/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { Image, Row, Col, Table } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import commonNames from "../../../config/commonNames";
import ManageFilesUpload from "../../../commonComponent/ManageFilesUpload";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import {
  categoryFilesRemove,
  categoryFilesUpdate,
  categoryGetAll,
} from "../../../slices/category.slice";
import { totalBalance } from "../../../config/financeCalculation";
import CategoryTableData from "../../../commonComponent/CategoryTableData";
import { tableHeader } from "../../../config/categoryTable";
import { setAddressString } from "../../../util/common";
import { totalBalanceColumn } from "../../../util/finance";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../slices/fileManagement.slice";

const AssetCategoryAllData = ({
  closeModal,
  toastMessage,
  url,
  title,
  category,
  displayArr,
  ...props
}) => {
  const { user } = useSelector((state) => state.auth);
  const { categoryTitle, assetsColumnsListArr, categoryType } = props;
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const categoryListDataTest = useSelector(
    (state) => state.category.categoryDashboardListing
  );
  const dispatch = useDispatch();

  const [fileModal, setFileModal] = useState(false);
  const [storeFiles, listFiles] = useState([]);
  const [storeAllFiles, setAllFiles] = useState("");
  const [is_deleted, setIs_deleted] = useState(false);
  const [totalCount, setTotalCount] = useState({
    totalBalance: 0,
    totalDue: 0,
    totalShareValue: 0,
    totalShareMarketValue: 0,
    totalMarketValue: 0,
    totalCashValue: 0,
    total: 0,
  });

  const getTableList = () => {
    dispatch(
      categoryGetAll({ id: id, category: category, main_category: "assets" })
    )
      .unwrap()
      .then((response) => {
        // setManualEntries(response.entries);
        // set// externalApiEntries,(response.external_api);
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  useEffect(() => {
    setTotalCount({
      balanceDue: totalBalance(displayArr, "balance_due"),
      balance: totalBalance(displayArr, "balance"),
      shareValue: totalBalance(displayArr, "share_value"),
      shareMarketValue: totalBalance(displayArr, "share_market_value"),
      marketValue: totalBalance(displayArr, "market_value"),
      cashValue: totalBalance(displayArr, "cash_value"),
      total: totalBalance(displayArr, "total"),
    });
  }, [displayArr, categoryListDataTest[category]]);

  const changeStatus = (showModal, r_id) => {
    // window.localStorage.setItem("row_id", r_id);
    setFileModal(showModal);
    if (attachments !== null) {
      // const allFiles = attachments;
      // setAllFiles(allFiles);
      // const collectFiles = allFiles.split(",");
      // listFiles(collectFiles);
    } else {
      setAllFiles("");
      listFiles([]);
    }
  };

  function fileAttachment(params) {
    const allFiles = params;
    setAllFiles(allFiles);
    const collectFiles = allFiles.split(",");
    listFiles(collectFiles);
  }

  const onDropAccepted = useCallback((acceptedFiles) => {
    setIs_deleted(true);
    const emptyfileArr = [];
    acceptedFiles.forEach((file) => {
      emptyfileArr.push(file);
    });
    const inProgressData = progressUploadDetails.length
      ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
      : 0;
    const used_space = storageData.usedSpace + inProgressData;
    const formdata = new FormData();
    for (let i = 0; i < emptyfileArr.length; i++) {
      const img_name = emptyfileArr[i];
      formdata.append("attachments", img_name);
    }
    let rowId = window.localStorage.getItem("row_id");
    formdata.append("row_id", rowId);
    formdata.append("used_space", used_space);
    let newData = {
      formdata: formdata,
      category: category,
      main_category: "assets",
    };

    dispatch(categoryFilesUpdate(newData))
      .unwrap()
      .then(async (response) => {
        await dispatch(get_user_storage({ id })).unwrap();
        fileAttachment(response.data);
        getTableList();
        setIs_deleted(false);
        acceptedFiles.length = 0;
        toastMessage("Your file has been uploaded successfully.", "success");
      })
      .catch((err) => {
        setIs_deleted(false);
        toastMessage(err.message, "error");
      });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    onDropAccepted,
  });

  const removeFile = (params, row_id) => {
    let rowId = window.localStorage.getItem("row_id");
    setIs_deleted(true);

    dispatch(
      categoryFilesRemove({
        catRowId: rowId,
        row_id: row_id,
        user_id: id,
        fileName: params,
        category: category,
        main_category: "assets",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setIs_deleted(false);
          fileAttachment(response.data);
          getTableList();
          toastMessage("Your file has been deleted successfully.", "success");
        }
      })
      .catch((err) => {
        setIs_deleted(false);
        toastMessage(err.message, "error");
      });
  };

  const columnCount = assetsColumnsListArr.length;
  const tableFooterRow = totalBalanceColumn(columnCount + 1);
  const isDisplayShareValue = [
    "savingaccount",
    "cashonhand",
    "nonretirementbrokerage",
  ].includes(categoryType);
  const isDsiplayMarketValue = [
    "personalproperty",
    "otherassets",
    "automobile",
  ].includes(categoryType);
  const isDisplayTotalBalance = ["retirementaccount"].includes(categoryType);
  const isDisplayBalanceDue = ["accountnotesreceviable"].includes(categoryType);
  const isDisplayShareMarketValue = ["realestate", "business"].includes(
    categoryType
  );
  const isDisplayCashValue = ["lifeinsurance"].includes(categoryType);

  return (
    <>
      <div className="pfs-table-container">
        <ManageFilesUpload
          changeStatus={changeStatus}
          fileModal={fileModal}
          storeFiles={storeFiles}
          storeAllFiles={storeAllFiles}
          getTableList={getTableList}
          toastMessage={toastMessage}
          fileAttachment={fileAttachment}
          user_id={id}
          acceptedFiles={acceptedFiles}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          removeFile={removeFile}
          is_deleted={is_deleted}
        />

        <>
          <Row>
            <Col lg={12}>
              <div className="pfs-common-title">
                <h4 className="default-regular-h4 cs-neutral-100">
                  {categoryTitle}
                  {/* <Link
                    // to={`/individual/assets/${categoryType}`}
                    className="custom-table-element"
                  // onClick={closeModal}
                  >
                    <span className="cs-asset icon">
                      <CapsyncIcon title="redirect-outlined" size="24px" />
                    </span>
                  </Link> */}
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="pfs-share-worksheet-listing">
                <Table
                  responsive
                  className={`cs-table ${categoryType}-table category-cs-table-container`}
                >
                  <thead id="table-head-details" className="cs-thead">
                    <tr>
                      {assetsColumnsListArr &&
                        assetsColumnsListArr.length > 0 &&
                        assetsColumnsListArr.map((assetTdHead) => {
                          return (
                            <>
                              <th
                                className={assetTdHead.pfsClass}
                                width={assetTdHead.pfsWidth}
                              >
                                {assetTdHead.title}
                              </th>
                            </>
                          );
                        })}
                      {/* <th className="default-medium-sub-heading-m" width="111">
                        {commonNames.Attachment}
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="cs-tbody">
                    {displayArr.map((assetsDisplay, indx) => {
                      return (
                        <tr key={indx}>
                          {assetsColumnsListArr &&
                            assetsColumnsListArr.map((assetRowField, index) => {
                              const { fieldType, fieldName } = assetRowField;
                              let displayValue =
                                fieldName === "date_updated"
                                  ? moment(assetsDisplay[fieldName]).format(
                                      "MM/DD/YYYY"
                                    )
                                  : assetsDisplay[fieldName];
                              if (fieldName === "ownership") {
                                displayValue = displayValue + "  %";
                              }
                              if (fieldName === "address") {
                                displayValue = setAddressString(displayValue);
                              }
                              return (
                                <td
                                  className={
                                    fieldName === "ownership" ? "text-end" : ""
                                  }
                                  width={assetRowField.pfsWidth}
                                >
                                  <CategoryTableData
                                    component={displayValue}
                                    type={fieldType}
                                    // classValue={`${fieldName === "ownership" ? 'dollar-value-gap' : ''}`}
                                    classValue={`${
                                      fieldName === "ownership"
                                        ? "text-end"
                                        : ""
                                    }`}
                                    item={assetsDisplay}
                                    fieldName={fieldName}
                                    // is_display_tooltip={assetsDisplay.is_display_tooltip}
                                    isSharePFS={true}
                                  />
                                </td>
                              );
                            })}
                          {/* <td>
                            <div className="other-action-td">
                              <CategoryTableData
                                type="attachments"
                                changeStatus={() => {}}
                                item={assetsDisplay}
                                isSharePFS={true}
                              />
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                    <tr className="cs-tfoot-assets default-medium-sub-heading-m">
                      {/* {tableFooterRow.map((column, index) => {
                        const totalColumn = tableFooterRow;
                        const lastColumnIndx = totalColumn.length - 3 === index;
                        const isDisplayShareValue = (index === 1 && ["savingaccount"].includes(categoryType));
                        const isDsiplayMarketValue = lastColumnIndx && ["personalproperty", "otherassets"].includes(categoryType);
                        const isDisplayTotalBalance = lastColumnIndx && !["personalproperty", "accountnotesreceviable", "otherassets", "realestate", "automobile", "lifeinsurance"].includes(categoryType);
                        const isDisplayBalanceDue = lastColumnIndx && ["accountnotesreceviable", "automobile"].includes(categoryType);
                        const isDisplayShareMarketValue = lastColumnIndx && ["realestate"].includes(categoryType);
                        const isDisplayCashValue = lastColumnIndx && ["lifeinsurance"].includes(categoryType);
                        return (
                          <td className={
                            (index === 1 && ["savingaccount"].includes(categoryType)) ||
                              (lastColumnIndx && (
                                ["personalproperty", "otherassets", "accountnotesreceviable", "automobile", "realestate", "lifeinsurance"].includes(categoryType) ||
                                !["personalproperty", "accountnotesreceviable", "otherassets", "realestate", "automobile", "lifeinsurance"].includes(categoryType)
                              ))
                              ? "cs-border-td" : ""
                          }>
                            {index === 0 && commonNames.Total}
                            {isDisplayShareValue && <CategoryTableData
                              type="number"
                              component={totalCount.balance}
                            />}
                            {isDisplayTotalBalance && <CategoryTableData
                              type="number"
                              component={totalCount.balance}
                            />}
                            {isDsiplayMarketValue && <CategoryTableData
                              type="number"
                              component={totalCount.marketValue}
                            />}
                            {isDisplayBalanceDue && <CategoryTableData
                              type="number"
                              component={totalCount.balanceDue}
                            />}
                            {isDisplayShareMarketValue && <CategoryTableData
                              type="number"
                              component={totalCount.shareMarketValue}
                            />}
                            {isDisplayCashValue && <CategoryTableData
                              type="number"
                              component={totalCount.cashValue}
                            />}
                          </td>
                        )
                      })} */}
                      <td colSpan={tableFooterRow.length - 3}>
                        {commonNames.Total}
                      </td>
                      <td colSpan={1}>
                        {isDisplayShareValue && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.shareValue}
                          />
                        )}
                        {isDisplayTotalBalance && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.total}
                          />
                        )}
                        {isDsiplayMarketValue && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.marketValue}
                          />
                        )}
                        {isDisplayBalanceDue && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.balanceDue}
                          />
                        )}
                        {isDisplayShareMarketValue && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.shareMarketValue}
                          />
                        )}
                        {isDisplayCashValue && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.cashValue}
                          />
                        )}
                      </td>
                      <td colSpan={2}></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </>
      </div>
    </>
  );
};

export default AssetCategoryAllData;
