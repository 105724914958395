import React, { useState } from "react";
import { Accordion, Row, Col, Table, Image } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { requestTableHeader } from "../../../config/request";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { getSignatureStatus } from "../../../util/request";
import { useDispatch } from "react-redux";
import { sendpfsemail, signature_reminder } from "../../../slices/user";

// import "./request.css";
// import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
// import {
//     fetchSignatureFrom,
//     getSignatureStatus,
//     getStatusInText,
// } from "../../../util/request";
import "./Requests.css";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { roleName } from "../../../config/commonRoleName";

const RequestDashboard = (props) => {
  const {
    data,
    status,
    onSignatureDetailsClick,
    handleDownload,
    radioValue,
    rowStartIdx,
    rowsPerPage,
  } = props;
  const imageTitles = [
    "signature-timline-outlined",
    "information-outlined",
    "information-outlined",
    "verification-outlined",
  ];

  const dispatch = useDispatch();

  const [disabledUsers, setDisabledUsers] = useState([]);

  const handleEmailSend = async (user) => {
    if (disabledUsers.includes(user.request_sent_to_email)) {
      return;
    }
    setDisabledUsers([...disabledUsers, user.request_sent_to_email]);
    const email = user.request_sent_to_email;
    const signatureHelloId = user.signature_hello_id;
    await handleSendEmail(email, signatureHelloId);
    setDisabledUsers(
      disabledUsers.filter((u) => u !== user.request_sent_to_email)
    );
  };

  async function handleSendEmail(email, signatureHelloId) {
    const response = await dispatch(
      signature_reminder({
        email: email,
        signature_request_id: signatureHelloId,
      })
    );
    if (response && response.payload.code === 500) {
      toast.error(response.payload.message);
    } else if (
      response &&
      response.payload.response &&
      response.payload.response.data.code === 500
    ) {
      toast.error(response.payload.response.data.message);
    } else {
      toast.success("Your reminder has been sent successfully.");
    }
  }

  return (
    <Row>
      <Col>
        <div className="amortization-main-listing signatur-from financial-request-table">
          {data.length === 0 ? (
            <span className="cs-common-no-record">
              There are no records to display
            </span>
          ) : (
            <Table className="cs-table">
              {/* <thead id="table-head-details-wm" className="cs-thead">
                                <tr>
                                    {requestTableHeader.requestToPending.map((header) => (
                                        <th
                                            key={header.title}
                                            className={header.class}
                                            width={header.width}
                                            scope="col"
                                        >
                                            {header.title}
                                        </th>
                                    ))}
                                   

                                </tr>
                            </thead> */}
              <thead id="table-head-details-wm" className="cs-thead">
                <tr>
                  {radioValue === "1" &&
                    requestTableHeader.requestToCompleted.map((header) => (
                      <th
                        key={header.title}
                        className={header.class}
                        width={header.FaWidth}
                        scope="col"
                      >
                        {header.title}
                      </th>
                    ))}
                  {radioValue === "2" &&
                    requestTableHeader.requestToCompleted.map((header) => (
                      <th
                        key={header.title}
                        className={header.class}
                        width={header.FaWidth}
                        scope="col"
                      >
                        {header.title}
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody className="cs-tbody request-dashboard">
                {data
                  .slice(rowStartIdx, rowStartIdx + rowsPerPage)
                  .map((request, index) => {
                    const {
                      wm_users,
                      request_type,
                      request_sent_by_role,
                      signature_date,
                      request_status,
                    } = request;
                    const {
                      request_sent_by_displayName,
                      request_sent_to_email,
                      request_sent_to_firstName,
                      request_sent_to_lastName,
                      request_sent_to_role,
                    } = wm_users[0];
                    const statusListArr = getSignatureStatus(
                      request,
                      "wealthManager"
                    );
                    let jointUserstatuslistArr = statusListArr.map(
                      (status) => status
                    );
                    jointUserstatuslistArr.shift();
                    const trID = request?.request_type
                      ? request?.request_type
                          .toLowerCase()
                          .replaceAll(" ", "_") +
                        "_" +
                        request?.request_id
                      : null;

                    return (
                      <>
                        {wm_users.length > 1 && (
                          <tr id={trID}>
                            <td
                              className="accordion-table-collapse accordion-after"
                              colSpan={6}
                            >
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item>
                                  <Accordion.Header>
                                    <Table className="cs-table">
                                      <tbody className="cs-tbody default-regular-sub-heading-m">
                                        <tr>
                                          <td width="248">{request_type}</td>
                                          <td width="270">
                                            {/* {request_sent_by_displayName} */}
                                            {/* {request_sent_to_firstName + " " + request_sent_to_lastName} */}
                                            {request_sent_to_firstName +
                                            " " +
                                            request_sent_to_lastName ? (
                                              (
                                                request_sent_to_firstName +
                                                " " +
                                                request_sent_to_lastName
                                              ).length > 20 ? (
                                                <CapsyncToolTip
                                                  Child={
                                                    (
                                                      request_sent_to_firstName +
                                                      " " +
                                                      request_sent_to_lastName
                                                    ).slice(0, 20) + "..."
                                                  }
                                                  placement={"top"}
                                                  message={
                                                    request_sent_to_firstName +
                                                    " " +
                                                    request_sent_to_lastName
                                                  }
                                                  type="text"
                                                />
                                              ) : (
                                                request_sent_to_firstName +
                                                " " +
                                                request_sent_to_lastName
                                              )
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                          <td width="220">
                                            {/* {request_sent_by_role.toLowerCase() === "wealth manager" ? "Financial Advisor" : request_sent_by_role} */}
                                            {/* {request_sent_to_role} */}
                                            {request_sent_to_role ===
                                            roleName.financial_advisor
                                              ? "Financial Advisor"
                                              : request_sent_to_role ===
                                                roleName.individual
                                              ? "Individual"
                                              : "Firm"}
                                          </td>
                                          <td width="200">
                                            {moment(
                                              wm_users[0].createdAt
                                            ).format("MM/DD/YYYY")}
                                          </td>
                                          <td width="320">
                                            <div className="cs-progress-indicator">
                                              {statusListArr.map(
                                                (status, index) => {
                                                  let statusclass = "";
                                                  let currentImageTitle =
                                                    imageTitles[index];
                                                  let tooltipMessage = "";

                                                  if (status === "Completed") {
                                                    statusclass = "cs-success";
                                                    tooltipMessage =
                                                      "Signature request has been sent";
                                                    if (index === 1) {
                                                      tooltipMessage =
                                                        "1st Signature added";
                                                    } else if (index === 2) {
                                                      tooltipMessage =
                                                        "2nd Signature added";
                                                    } else if (index === 3) {
                                                      tooltipMessage =
                                                        "Verification completed";
                                                    }
                                                  } else if (
                                                    index === 3 &&
                                                    status === "Rejected"
                                                  ) {
                                                    currentImageTitle =
                                                      "declined-outlined";
                                                    statusclass = "cs-danger";
                                                    tooltipMessage =
                                                      "Verification Rejected";
                                                  } else if (
                                                    status === "Rejected"
                                                  ) {
                                                    statusclass = "cs-danger";
                                                    if (index === 1) {
                                                      tooltipMessage =
                                                        "1st User rejected";
                                                    } else {
                                                      tooltipMessage =
                                                        "2nd User rejected";
                                                    }
                                                  } else {
                                                    if (
                                                      index === 1 &&
                                                      status === "Pending"
                                                    ) {
                                                      tooltipMessage =
                                                        "Waiting for 1st signature";
                                                    } else {
                                                      tooltipMessage =
                                                        "Waiting for 2nd signature";
                                                      if (
                                                        index === 3 &&
                                                        status === "Pending"
                                                      ) {
                                                        tooltipMessage =
                                                          "Waiting for verification";
                                                      }
                                                    }
                                                  }

                                                  return (
                                                    <div
                                                      className={`cs-indicator ${statusclass} ${
                                                        status === "Pending"
                                                          ? "cs-neutral-50"
                                                          : ""
                                                      }`}
                                                    >
                                                      <CapsyncToolTip
                                                        Child={
                                                          currentImageTitle
                                                        }
                                                        placement={"top"}
                                                        message={tooltipMessage}
                                                        type="icon"
                                                        size="22"
                                                      >
                                                        <span className="cs-indicator-icon cs-neutral-50">
                                                          <CapsyncIcon
                                                            title={
                                                              currentImageTitle
                                                            }
                                                            size="18"
                                                          />
                                                        </span>
                                                      </CapsyncToolTip>

                                                      {index <
                                                        statusListArr.length -
                                                          1 && (
                                                        <span className="cs-indicator-line"></span>
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </td>
                                          {/* {status === "Completed" && <td width="320"></td>} */}
                                          {status === "Completed" && (
                                            <td width="260">
                                              <div className="table-icons-container cs-file-icon finance-action-acc-column">
                                                <span
                                                  className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                    request.is_completed ===
                                                      true &&
                                                    status === "Completed"
                                                      ? "cs-disabled-icon"
                                                      : ""
                                                  } ${
                                                    status === "Completed"
                                                      ? "display-signature-icon"
                                                      : ""
                                                  }`}
                                                  // onClick={()=> toast.success("Your reminder has been sent successfully. ")}
                                                  onClick={() => {
                                                    // toast.success("Your reminder has been sent successfully. ");
                                                    if (
                                                      request.is_completed ===
                                                      false
                                                    ) {
                                                      handleEmailSend(
                                                        wm_users[0]
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <CapsyncToolTip
                                                    Child={"reminder-outlined"}
                                                    placement={"top"}
                                                    size="18"
                                                    message={"Reminder"}
                                                    type="icon"
                                                  />
                                                </span>

                                                <span
                                                  className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                    request.is_completed ===
                                                      false ||
                                                    wm_users[0]
                                                      .request_status === 4 ||
                                                    wm_users[1]
                                                      .request_status === 4
                                                      ? "cs-disabled-icon"
                                                      : ""
                                                  } ${
                                                    status === "Pending"
                                                      ? "display-signature-icon"
                                                      : ""
                                                  }`}
                                                  onClick={(e) => {
                                                    if (
                                                      request.is_completed ===
                                                        true &&
                                                      wm_users[0]
                                                        .request_status !== 4 &&
                                                      wm_users[1]
                                                        .request_status !== 4
                                                    ) {
                                                      props.sharePFSModal(
                                                        true,
                                                        false,
                                                        wm_users[0]
                                                          .sign_pfs_link,
                                                        wm_users[0].id
                                                      );
                                                    }
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <CapsyncToolTip
                                                    Child={"share-outlined"}
                                                    placement={"top"}
                                                    size="18"
                                                    message={"Share"}
                                                    type="icon"
                                                  />
                                                </span>

                                                <span
                                                  className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                    request.is_completed ===
                                                      false ||
                                                    wm_users[0]
                                                      .request_status === 4 ||
                                                    wm_users[1]
                                                      .request_status === 4
                                                      ? "cs-disabled-icon"
                                                      : ""
                                                  } ${
                                                    status === "Pending"
                                                      ? "display-signature-icon"
                                                      : ""
                                                  }`}
                                                  onClick={(e) => {
                                                    if (
                                                      request.is_completed ===
                                                        true &&
                                                      wm_users[0]
                                                        .request_status !== 4 &&
                                                      wm_users[1]
                                                        .request_status !== 4
                                                    ) {
                                                      handleDownload(
                                                        wm_users[0]
                                                          .sign_pfs_link
                                                      );
                                                      toast.success(
                                                        "Your Personal Financial Statement has been downloaded successfully."
                                                      );
                                                    }
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <CapsyncToolTip
                                                    Child={"download-outlined"}
                                                    placement={"top"}
                                                    size="18"
                                                    message={"Download"}
                                                    type="icon"
                                                  />
                                                </span>
                                              </div>
                                            </td>
                                          )}
                                          {status === "Pending" && (
                                            <td width="260"></td>
                                          )}
                                          {/* <td width="164"></td> */}
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Table className="cs-table accordion-table">
                                      <thead className="cs-thead">
                                        <tr>
                                          <th
                                            width="250"
                                            className="default-semi-bold-h6 cs-first-radius"
                                          >
                                            First Name
                                          </th>
                                          <th
                                            width="240"
                                            className="default-semi-bold-h6"
                                          >
                                            Last Name
                                          </th>
                                          <th
                                            width="260"
                                            className="default-semi-bold-h6"
                                          >
                                            Email
                                          </th>
                                          <th
                                            width="240"
                                            className="default-semi-bold-h6"
                                          >
                                            Status
                                          </th>
                                          <th
                                            width="240"
                                            className="default-semi-bold-h6"
                                          >
                                            Date
                                          </th>
                                          {/* <th width="186" className="default-semi-bold-h6">Action</th> */}
                                          {status === "Pending" ? (
                                            <th
                                              width="306"
                                              className="default-semi-bold-h6 cs-last-radius"
                                            >
                                              Action
                                            </th>
                                          ) : (
                                            <th
                                              width="306"
                                              className="default-semi-bold-h6 cs-last-radius"
                                            ></th>
                                          )}
                                          {/* <th width="120" className="default-semi-bold-h6 cs-last-radius"></th> */}
                                        </tr>
                                      </thead>
                                      <tbody className="cs-tbody default-regular-body-text-m">
                                        {wm_users.map((user) => {
                                          const {
                                            request_sent_to_firstName,
                                            request_sent_to_lastName,
                                            request_sent_to_email,
                                            request_status,
                                          } = user;
                                          const isDisabled =
                                            disabledUsers.includes(
                                              user.request_sent_to_email
                                            );
                                          return (
                                            <tr>
                                              <td
                                                width="250"
                                                className="default-regular-body-text-m cs-neutral-90"
                                              >
                                                {/* {request_sent_to_firstName} */}
                                                {request_sent_to_firstName ? (
                                                  request_sent_to_firstName.length >
                                                  20 ? (
                                                    <CapsyncToolTip
                                                      Child={
                                                        request_sent_to_firstName.slice(
                                                          0,
                                                          20
                                                        ) + "..."
                                                      }
                                                      placement={"top"}
                                                      message={
                                                        request_sent_to_firstName
                                                      }
                                                      type="text"
                                                    />
                                                  ) : (
                                                    request_sent_to_firstName
                                                  )
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              <td
                                                width="240"
                                                className="default-regular-body-text-m cs-neutral-90"
                                              >
                                                {/* {request_sent_to_lastName ? request_sent_to_lastName : "-"} */}
                                                {request_sent_to_lastName ? (
                                                  request_sent_to_lastName.length >
                                                  20 ? (
                                                    <CapsyncToolTip
                                                      Child={
                                                        request_sent_to_lastName.slice(
                                                          0,
                                                          20
                                                        ) + "..."
                                                      }
                                                      placement={"top"}
                                                      message={
                                                        request_sent_to_lastName
                                                      }
                                                      type="text"
                                                    />
                                                  ) : (
                                                    request_sent_to_lastName
                                                  )
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              <td
                                                width="260"
                                                className="default-regular-body-text-m cs-neutral-90"
                                              >
                                                {/* {request_sent_to_email} */}
                                                {request_sent_to_email ? (
                                                  request_sent_to_email.length >
                                                  20 ? (
                                                    <CapsyncToolTip
                                                      Child={
                                                        request_sent_to_email.slice(
                                                          0,
                                                          20
                                                        ) + "..."
                                                      }
                                                      placement={"top"}
                                                      message={
                                                        request_sent_to_email
                                                      }
                                                      type="text"
                                                    />
                                                  ) : (
                                                    request_sent_to_email
                                                  )
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              {/* <td width="216" className="default-regular-body-text-m cs-neutral-90">-</td> */}
                                              <td
                                                width="240"
                                                className="default-regular-body-text-m cs-neutral-90"
                                              >
                                                <span
                                                  className={`cs-badge default-regular-body-text-xs ${
                                                    request_status === 1 ||
                                                    request_status === 2
                                                      ? "cs-success"
                                                      : request_status === 3
                                                      ? "cs-warning"
                                                      : request_status === 4 &&
                                                        "cs-danger"
                                                  }`}
                                                >
                                                  {request_status === 1 ||
                                                  request_status === 2
                                                    ? "Completed"
                                                    : request_status === 3
                                                    ? "Pending"
                                                    : "Rejected"}
                                                </span>
                                              </td>
                                              <td
                                                width="240"
                                                className="default-regular-body-text-m cs-neutral-90"
                                              >
                                                {moment(user.createdAt).format(
                                                  "MM/DD/YYYY"
                                                )}
                                              </td>
                                              <td
                                                width="306"
                                                className="default-regular-body-text-m cs-neutral-90"
                                              >
                                                <div
                                                  className={
                                                    status === "Pending"
                                                      ? "finance-action-column"
                                                      : "justify-content-end"
                                                  }
                                                >
                                                  {status === "Pending" && (
                                                    <div className="table-icons-container cs-file-icon finance-action-acc-column">
                                                      <span
                                                        className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                          user.request_status ===
                                                            1 ||
                                                          user.request_status ===
                                                            2 ||
                                                          user.request_status ===
                                                            4
                                                            ? "cs-disabled-icon"
                                                            : ""
                                                        } ${
                                                          isDisabled
                                                            ? "cs-disabled-icon"
                                                            : ""
                                                        }`}
                                                        // onClick={()=> toast.success("Your reminder has been sent successfully. ")}
                                                        onClick={() => {
                                                          if (
                                                            !(
                                                              user.request_status ===
                                                                1 ||
                                                              user.request_status ===
                                                                2 ||
                                                              user.request_status ===
                                                                4
                                                            )
                                                          ) {
                                                            // toast.success("Your reminder has been sent successfully. ");
                                                            handleEmailSend(
                                                              user
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <CapsyncToolTip
                                                          Child={
                                                            "reminder-outlined"
                                                          }
                                                          placement={"top"}
                                                          size="18"
                                                          message={"Reminder"}
                                                          type="icon"
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                  <div
                                                    className="cs-detail-btn"
                                                    onClick={() =>
                                                      onSignatureDetailsClick(
                                                        user.request_sent_to_email,
                                                        user,
                                                        request.signature_hello_id,
                                                        request.request_id
                                                      )
                                                    }
                                                  >
                                                    <span className="default-light-sub-heading-s cs-neutral-90">
                                                      Details
                                                    </span>
                                                    <span className="icon cs-neutral-80">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="18"
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </td>
                          </tr>
                        )}
                        {wm_users.length === 1 && (
                          <>
                            <tr id={trID}>
                              <td width="248">{request_type}</td>
                              <td width="270">
                                {/* {request_sent_by_displayName} */}
                                {/* {request_sent_to_firstName + " " + request_sent_to_lastName} */}
                                {request_sent_to_firstName +
                                " " +
                                request_sent_to_lastName ? (
                                  (
                                    request_sent_to_firstName +
                                    " " +
                                    request_sent_to_lastName
                                  ).length > 20 ? (
                                    <CapsyncToolTip
                                      Child={
                                        (
                                          request_sent_to_firstName +
                                          " " +
                                          request_sent_to_lastName
                                        ).slice(0, 20) + "..."
                                      }
                                      placement={"top"}
                                      message={
                                        request_sent_to_firstName +
                                        " " +
                                        request_sent_to_lastName
                                      }
                                      type="text"
                                    />
                                  ) : (
                                    request_sent_to_firstName +
                                    " " +
                                    request_sent_to_lastName
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td width="220">
                                {" "}
                                {request_sent_to_role.toLowerCase() ===
                                roleName.financial_advisor
                                  ? "Financial Advisor"
                                  : request_sent_to_role === roleName.individual
                                  ? "Individual"
                                  : request_sent_to_role ===
                                    roleName.tax_professional
                                  ? "Firm"
                                  : request_sent_to_role}
                              </td>
                              <td width="200">
                                {moment(wm_users[0].createdAt).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>
                              <td width="320">
                                <div className="cs-progress-indicator">
                                  <div
                                    className={`cs-indicator cs-success ${
                                      status === "Completed" ? "cs-success" : ""
                                    }`}
                                  >
                                    <span>
                                      <CapsyncToolTip
                                        Child={"signature-timline-outlined"}
                                        placement={"top"}
                                        message={
                                          status === "Completed"
                                            ? "Signature request has been sent"
                                            : "Signature request has been sent"
                                        }
                                        type="icon"
                                        size="22"
                                      />
                                    </span>
                                    <span className="cs-indicator-line"></span>
                                  </div>
                                  <div
                                    className={`cs-indicator ${
                                      status === "Completed" &&
                                      wm_users[0].request_status !== 4
                                        ? "cs-success"
                                        : wm_users[0].request_status === 4
                                        ? "cs-danger"
                                        : "cs-neutral-50"
                                    }`}
                                  >
                                    <span>
                                      <CapsyncToolTip
                                        Child={"information-outlined"}
                                        placement={"top"}
                                        message={
                                          (wm_users[0].request_status === 4 &&
                                            "Signature Rejected") ||
                                          (wm_users[0].request_status === 1 &&
                                            "1st Signature added") ||
                                          (wm_users[0].request_status === 3 &&
                                            "Waiting for signature")
                                        }
                                        type="icon"
                                        size="22"
                                      />
                                    </span>
                                    <span className="cs-indicator-line"></span>
                                  </div>
                                  {wm_users[0].request_status !== 4 && (
                                    <div
                                      className={` cs-indicator-icon ${
                                        status === "Completed"
                                          ? "cs-success"
                                          : wm_users[0].request_status === 4
                                          ? "cs-danger"
                                          : "cs-neutral-50"
                                      }`}
                                    >
                                      <span>
                                        <CapsyncToolTip
                                          Child={"verification-outlined"}
                                          width="225px"
                                          placement={"top"}
                                          size="22"
                                          message={
                                            (wm_users[0].request_status === 4 &&
                                              "verification rejected") ||
                                            (wm_users[0].request_status === 1 &&
                                              "Completion of signature") ||
                                            (wm_users[0].request_status === 3 &&
                                              "Waiting for completion")
                                          }
                                          type="icon"
                                        />
                                      </span>
                                      <span className="cs-indicator-line"></span>
                                    </div>
                                  )}
                                  {wm_users[0].request_status === 4 && (
                                    <div
                                      className={` cs-indicator-icon ${
                                        wm_users[0].request_status === 4
                                          ? "cs-danger"
                                          : ""
                                      }`}
                                    >
                                      <span>
                                        <CapsyncToolTip
                                          Child={"declined-outlined"}
                                          placement={"top"}
                                          message={
                                            wm_users[0].request_status === 4 &&
                                            "Verification Rejected"
                                          }
                                          type="icon"
                                          size="22"
                                        />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td width="260">
                                <div className="table-icons-container cs-file-icon finance-action-column">
                                  <div className="finance-action-acc-column">
                                    <span
                                      className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                        wm_users[0].request_status === 1 ||
                                        wm_users[0].request_status === 2 ||
                                        wm_users[0].request_status === 4
                                          ? "cs-disabled-icon"
                                          : ""
                                      } ${
                                        status === "Completed"
                                          ? "display-signature-icon"
                                          : ""
                                      } ${
                                        disabledUsers.includes(
                                          wm_users[0].request_sent_to_email
                                        )
                                          ? "cs-disabled-icon"
                                          : ""
                                      }`}
                                      // onClick={()=> toast.success("Your reminder has been sent successfully. ")}
                                      onClick={() => {
                                        if (
                                          !(
                                            wm_users[0].request_status === 1 ||
                                            wm_users[0].request_status === 2 ||
                                            wm_users[0].request_status === 4
                                          )
                                        ) {
                                          // toast.success("Your reminder has been sent successfully. ");
                                          handleEmailSend(wm_users[0]);
                                        }
                                      }}
                                    >
                                      <CapsyncToolTip
                                        Child={"reminder-outlined"}
                                        placement={"top"}
                                        size="18"
                                        message={"Reminder"}
                                        type="icon"
                                      />
                                    </span>
                                    <span
                                      className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                        wm_users[0].request_status === 3 ||
                                        wm_users[0].request_status === 4 ||
                                        wm_users[0].is_deleted === true
                                          ? "cs-disabled-icon"
                                          : ""
                                      } ${
                                        status === "Pending"
                                          ? "display-signature-icon"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (
                                          (wm_users[0].request_status === 1 ||
                                            wm_users[0].request_status === 2) &&
                                          !(wm_users[0].request_status === 4) &&
                                          wm_users[0].is_deleted === false
                                        ) {
                                          props.sharePFSModal(
                                            true,
                                            false,
                                            wm_users[0].sign_pfs_link,
                                            wm_users[0].id
                                          );
                                        }
                                      }}
                                    >
                                      <CapsyncToolTip
                                        Child={"share-outlined"}
                                        placement={"top"}
                                        size="18"
                                        message={"Share"}
                                        type="icon"
                                      />
                                    </span>
                                    <span
                                      className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                        wm_users[0].request_status === 3 ||
                                        wm_users[0].request_status === 4 ||
                                        wm_users[0].is_deleted === true
                                          ? "cs-disabled-icon"
                                          : ""
                                      } ${
                                        status === "Pending"
                                          ? "display-signature-icon"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (
                                          (wm_users[0].request_status === 1 ||
                                            wm_users[0].request_status === 2 ||
                                            wm_users[0].request_status === 4) &&
                                          wm_users[0].is_deleted === false
                                        ) {
                                          handleDownload(
                                            wm_users[0].sign_pfs_link
                                          );
                                          toast.success(
                                            "Your Personal Financial Statement has been downloaded successfully."
                                          );
                                        }
                                      }}
                                    >
                                      <CapsyncToolTip
                                        Child={"download-outlined"}
                                        placement={"top"}
                                        size="18"
                                        message={"Download"}
                                        type="icon"
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className="cs-detail-btn"
                                    onClick={() =>
                                      onSignatureDetailsClick(
                                        request_sent_to_email,
                                        wm_users[0],
                                        request.signature_hello_id,
                                        request.request_id
                                      )
                                    }
                                  >
                                    <span className="default-light-sub-heading-s cs-neutral-90">
                                      Details
                                    </span>
                                    <span className="icon cs-neutral-80">
                                      <CapsyncIcon
                                        title="chevron-right-outlined"
                                        size="18"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default RequestDashboard;
