/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import IndRoutes from "./IndRoutes";
import { socket } from "../config/Socket";
import { localStorageClear } from "../util/common";
import { roleName } from "../config/commonRoleName";
import MessageModal from "../commonComponent/component/MessageModal";
import SessionLogoutModal from "../commonComponent/component/SessionLogoutModal";
import InactivityLogoutModal from "../commonComponent/component/InactivityLogoutModal";

import {
  clearAuthSlice,
  setRemoveAuthUser,
  signout,
  useUser,
} from "../slices/auth";
import { add_audit_log } from "../slices/auditLogs";

const Routing = () => {
  const dispatch = useDispatch();
  const user = useUser();

  const { authUser } = useSelector((state) => state.auth);
  const token = user && user.token;

  const INACTIVITY_TIMER = process.env.REACT_APP_INACTIVITY_TIMEOUT
    ? process.env.REACT_APP_INACTIVITY_TIMEOUT * 1000 * 60
    : 1000 * 60 * 15;
  const PROMPT_TIMER = process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT
    ? process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT * 60
    : 60 * 2;

  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [sessionLogoutModalOpen, setSessionLogoutModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(INACTIVITY_TIMER); // 2 mins
  const [loggedIn, setLoggedIn] = useState(true);

  const CapsyncMessage = (title, message, type) => {
    setMsgModalOpen(true);
    setModalTitle(title);
    setModalMessage(message);
    setModalType(type);
  };

  useEffect(() => {
    activate();
  }, [window.location.pathname]);

  useEffect(() => {
    const handleLoad = async () => {
      const userDetails = window.localStorage.getItem("user");
      if (!userDetails) {
        window.localStorage.setItem("lastTimeStamp", "");
      } else {
        const lastTimeStamp = window.localStorage.getItem("lastTimeStamp");

        const duration = moment.duration(moment().diff(lastTimeStamp));
        const durationInMinutes = duration.asMinutes();

        const tabCloseTimeoutLimit =
          process.env.REACT_APP_TAB_CLOSE_TIMEOUT_LIMIT || 5;

        if (
          durationInMinutes &&
          tabCloseTimeoutLimit < durationInMinutes &&
          !window.location.pathname.startsWith("/individual/quickbooks/")
        ) {
          dispatch(add_audit_log({ signout: true }));
          if (window.localStorage.pfs_access_obj) {
            socket.emit("remove_edit_permission", {
              user_id: JSON.parse(window.localStorage.getItem("pfs_access_id")),
              advisor_user_id: window.localStorage.getItem("id"),
              flag:
                JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role === 1
                  ? roleName.individual
                  : JSON.parse(window.localStorage.getItem("loginStatus"))
                      .user_role == 2
                  ? roleName.financial_advisor
                  : roleName.tax_professional,
            });
          }
          socket.emit("fetch_user", {
            user_id: window.localStorage.getItem("id"),
            user_type:
              JSON.parse(window.localStorage.getItem("loginStatus"))
                .user_role &&
              JSON.parse(window.localStorage.getItem("loginStatus")).user_role,
            user_role:
              JSON.parse(window.localStorage.getItem("loginStatus"))
                .user_role === 1
                ? roleName.individual
                : JSON.parse(window.localStorage.getItem("loginStatus"))
                    .user_role == 2
                ? roleName.financial_advisor
                : roleName.tax_professional,
            logged_out: true,
          });
          await dispatch(
            signout({
              user_id: authUser ? authUser?.user?.id : user?.user?.id,
              user_role: authUser
                ? authUser?.user_role?.role
                : user?.user_role?.role,
            })
          );
          localStorageClear();
          // navigate("/", { replace: true });
        } else {
          if (window.localStorage.getItem("pfs_access_obj")) {
            // get user back for multi user flow
            socket.emit("give_access_to_advisor", {
              user_id: JSON.parse(window.localStorage.getItem("pfs_access_id")),
              advisor_user_id: window.localStorage.getItem("id"),
              flag:
                JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role === 1
                  ? roleName.individual
                  : JSON.parse(window.localStorage.getItem("loginStatus"))
                      .user_role == 2
                  ? roleName.financial_advisor
                  : roleName.tax_professional,
            });
          }
        }
      }
    };

    const handleTabClose = (event) => {
      window.localStorage.setItem("lastTimeStamp", moment());
      if (window.localStorage.getItem("pfs_access_obj")) {
        // update user active flag
        socket.emit("remove_edit_permission", {
          user_id: JSON.parse(window.localStorage.getItem("pfs_access_id")),
          advisor_user_id: window.localStorage.getItem("id"),
          flag:
            JSON.parse(window.localStorage.getItem("loginStatus")).user_role ===
            1
              ? roleName.individual
              : JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role == 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        });
      }
    };

    window.addEventListener("load", handleLoad);
    window.addEventListener("unload", handleTabClose);

    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  const { getRemainingTime, activate, isLastActiveTab } = useIdleTimer({
    // onIdle,
    // onActive,
    // onAction,
    timeout: INACTIVITY_TIMER, // use for total in activity timeout
    syncTimers: 200,
    startOnMount: true,
    throttle: 10000,
    // onPrompt: () => handleInactivityTimeout(),
    promptBeforeIdle: 1000 * PROMPT_TIMER, // timeout - promptBeforeIdle -> call onPrompt
    crossTab: true,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "touchstart",
      "touchmove",
      // 'visibilitychange'
    ],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    socket.on("session_logout", () => {
      setSessionLogoutModalOpen(true);
      localStorageClear();
      dispatch(setRemoveAuthUser());
      dispatch(clearAuthSlice());
    });
  }, []);

  const handleStay = () => {
    activate();
    // setShowModal(false);
  };

  const handleLogoutFunc = (params) => {
    setLoggedIn(params);
  };

  const renderRoutes = () => {
    return (
      <IndRoutes
        CapsyncMessage={CapsyncMessage}
        handleLogoutFunc={handleLogoutFunc}
      />
    );
  };
  const rendertab = useMemo(() => renderRoutes(), []);

  return (
    <>
      {rendertab}
      {countdown <= PROMPT_TIMER &&
        isLastActiveTab() &&
        (window.location.pathname.includes("individual") ||
          window.location.pathname.includes("financial-advisor") ||
          window.location.pathname.includes("/professional")) &&
        token && (
          <InactivityLogoutModal
            remainingSeconds={countdown}
            onStay={handleStay}
          />
        )}
      <MessageModal
        isOpen={isMsgModalOpen}
        title={modalTitle}
        message={modalMessage}
        type={modalType}
      />
      <SessionLogoutModal
        sessionLogoutModalOpen={sessionLogoutModalOpen}
        closeModal={() => {
          window.location.href = "/";
        }}
      />
    </>
  );
};

export default Routing;
