import React from "react";
import NumberFormat from "react-currency-format";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";

function currencyFormat(num) {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const AccountDetails = ({ account, balance, type }) => {
  return (
    <tr>
      <td>
        <div className="plaid-text">
          {account}
          {type === "duplicate" && (
            <span className="plaid-icon cs-success">
              <CapsyncIcon title="check-outlined" size="22" />
            </span>
          )}
        </div>
      </td>
      <td>
        <div className="dollar-value-gap">
          <span> $ &nbsp;</span>
          <NumberFormat
            value={currencyFormat(balance)}
            displayType={"text"}
            thousandSeparator={true}
          />
        </div>
      </td>
    </tr>
  );
};

export default AccountDetails;
