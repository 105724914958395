/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  access_pfs_add_user,
  access_pfs_fetchAll,
  access_pfs_deleteUser,
  change_permission,
  pfs_re_invitation,
} from "../../../slices/pfs_access";
import { fetch_user_from_email } from "../../../slices/commonUsers";
import { backAppURl } from "../../../applicationMode";
import UseFetchPermission from "../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../PermissionPrevent";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import OffCanva from "./OffCanva";
import CapsyncSectionHeader from "../../../commonComponent/CapsyncSectionHeader";
import ManageUserTable from "./ManageUserTable";
import SkeletonManageUser from "./SkeletonManageUser";
import AddModalCollaboration from "./AddModalCollaboration";
import { socket } from "../../../config/Socket";
import { roleName } from "../../../config/commonRoleName";
import { checkDomainAccept } from "../../../util/common";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";
const manageUser = (props) => {
  const CapsyncDeleteMessage = props.CapsyncDeleteMessage;

  let dateType = [roleName.individual, roleName.financial_advisor];
  const [modalShow, setModalShow] = useState(false);
  const [fetchAllIndividuals, setFetchAllIndividuals] = useState([]);
  const [fetchAllWealthManagers, setFetchAllWealthManagers] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [OffcanvasState, setOffcanvasState] = useState({
    to_id: "",
    to_user_id: "",
    to_email: "",
    to_displayName: "",
    to_licenceId: "",
    to_user_role: "",
    to_status: "",
    to_phoneNo: "",
    to_photo: "",
    to_companyData: "",
    createdAt: "",
  });

  const [permissionRadio, setPermissionRadio] = useState(
    OffcanvasState.to_access_permission
  );
  const [show, setShow] = useState(false);
  const [makeDisable, setMakeDisable] = useState(false);
  const [mainTabName, setMainTabName] = useState("1");
  const [accessPermission, setAccessPermission] = useState(1);
  const [dropdownErr, setDropdownErr] = useState("");
  const radioName = [
    { name: "Individual", value: "1" },
    { name: "Firm", value: "2" },
    // { name: "Professional", value: "3" },
  ];
  const [managerUserData, setManagerUserData] = useState({});
  const [type, setType] = useState("Individual Or Financial Advisor");

  const dispatch = useDispatch();
  const { user, authUser } = useSelector((state) => state.auth);
  const storeReduxData = useSelector((state) => state.message);
  const { fetchUserPermission } = UseFetchPermission();
  const [loading, setLoading] = useState(false);
  const [isViewOnlyDisabled, setIsViewOnlyDisabled] = useState(false);
  const [isStandardDisabled, setIsStandardDisabled] = useState(false);
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const managerUser = useSelector((state) => state.manageUser.manageUser);
  const pfsAccessId = window.localStorage.getItem("pfs_access_id");

  useEffect(() => {
    const authEmail = authUser.email
      ? authUser.email
      : authUser.user.email
      ? authUser.user.email
      : "";
    if (managerUser && (managerUser.individual || managerUser.wealth)) {
      setManagerUserData({
        ...managerUser,
        individual: managerUser.individual.filter(
          (usr) => authEmail !== usr.to_email
        ),
        wealth: managerUser.wealth.filter((usr) => authEmail !== usr.to_email),
      });
    } else {
      setManagerUserData(managerUser);
    }
  }, [managerUser]);

  // let from_user_role = user && user.user_role.role;
  let from_user_role = localStorage.getItem("pfs_access_id")
    ? 1
    : user && user.user_role.role;
  let user_email = "";
  let dynamicURL = backAppURl;

  // It will be either 1/2,  1= view, 2 = edit
  const fetchPermission = (params) => {
    setAccessPermission(params);
  };

  const fetchAllUsers = () => {
    dispatch(
      access_pfs_fetchAll({
        id: id,
        user,
      })
    )
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        props.toastMessage("access_pfs_fetchAll :" + err.message, "error");
      });
  };

  useEffect(() => {
    fetchAllUsers();
  }, [user]);

  const fetch_userdata_from_email = (dataParam) => {
    setShow(true);
    if (dataParam.to_user_id) {
      dispatch(fetch_user_from_email({ email: dataParam.to_email }))
        .unwrap()
        .then((response) => {
          const businessName =
            response && response.res_data && response.res_data.businessName;
          let companyData = "";
          if (response && response.res_data.companyName) {
            companyData = response && response.res_data.companyName;
          }
          // if (response && response.res_data.companyLicenceId) {
          //   // if (response && response.res_data.companyName) {
          //   //   companyData += " | ";
          //   //   companyData += response && response.res_data.companyLicenceId;
          //   // } else {
          //     companyData = response && response.res_data.companyLicenceId;
          //   // }
          // }

          setOffcanvasState({
            to_id: dataParam.id,
            to_user_id: response.res_data.id,
            to_email: response.res_data.email,
            to_displayName: response.res_data.displayName,
            to_licenceId: response.res_data.licenceId,
            to_user_role: dataParam.to_user_role,
            to_status: dataParam.to_status,
            to_phoneNo: response.res_data.phoneNo,
            to_photo: response.res_data.profileImageUrl,
            to_companyData: businessName,
            createdAt: dataParam.createdAt
              ? moment(new Date(dataParam.createdAt)).format("MM/DD/YYYY")
              : "dataParam.createdAt",
            companyLicenceId: response.res_data.companyLicenceId,
          });
          // setShow(true);
          setPermissionRadio(dataParam.to_access_permission);
        })
        .catch((err) => {
          props.toastMessage("fetch_user_from_email :" + err.message, "error");
        });
    } else {
      setOffcanvasState({
        to_id: dataParam.id,
        to_user_id: "",
        to_email: dataParam.to_email,
        to_displayName: dataParam.to_firstName + " " + dataParam.to_lastName,
        to_licenceId: "-",
        to_user_role: dataParam.to_user_role,
        to_status: dataParam.to_status,
        to_phoneNo: dataParam.to_phoneNo,
        to_photo: dataParam.to_photo,
        to_companyData: "-",
        companyLicenceId: "-",
        createdAt: dataParam.createdAt,
      });
      // setShow(true);
      setPermissionRadio(dataParam.to_access_permission);
    }
  };

  const userCanvasEmail = OffcanvasState.to_email;
  if (userCanvasEmail && userCanvasEmail.length >= 33) {
    let arr = userCanvasEmail.split("");
    for (let z = 0; z < 33; z++) {
      const element = arr[z];
      user_email += element;
    }
    user_email += "...";
  } else {
    user_email = userCanvasEmail;
  }

  const checkType = (event) => {
    setType(event);
    setDropdownErr("");
  };

  const initialValues = {
    to_firstName: "",
    to_lastName: "",
    to_email: "",
  };

  const validationSchema = yup.object().shape({
    to_firstName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "Whitespace is not allowed")
      // .min(2, "Required at least 2 characters")
      .max(40, uniValidation.first_name.max)
      .required("Enter first name"),
    to_lastName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "Whitespace is not allowed")
      // .min(2, "Required at least 2 characters")
      .max(40, uniValidation.last_name.max)
      .required("Enter last name"),
    to_email: yup
      .string()
      // .matches(/^\S*$/, "Whitespace is not allowed")
      .required(uniValidation.email.required)
      .test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test(
        "no-more-than-30-before-at",
        "Cannot accept more than 30 characters before @",
        (value) => (value ? value.split("@")[0].length <= 30 : true)
      )
      .test(
        "at-least-1-character-before-at",
        "Enter at least 1 character before @",
        (value) => (value ? value.split("@")[0].length > 0 : true)
      )
      .test("custom-email-format", uniValidation.email.validEmail, (value) => {
        return value ? uniValidation.email.allowedFormat.test(value) : true;
      })
      .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
        return checkDomainAccept(value);
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    //---------This will be call on form submit---------//
    onSubmit: async (values) => {
      if (type === "Individual Or Financial Advisor") {
        setDropdownErr("Select user type");
      } else {
        setLoading(true);
        setMakeDisable(true);
        setDropdownErr("");
        // values.from_user_id = id;
        // values.request_send_by_displayName = user.user.displayName
        // values.to_access_permission = accessPermission;
        // values.from_user_role = from_user_role;
        // values.to_user_role = type;
        const updatedValues = {
          ...values,
          from_user_id: id,
          request_send_by_displayName: user.user.displayName,
          to_access_permission: accessPermission,
          from_user_role: from_user_role,
          to_user_role: type,
        };

        try {
          const response = await dispatch(access_pfs_add_user(updatedValues));
          setLoading(false);
          setModalShow(false);
          fetchAllUsers();

          formik.resetForm();
          setType("Individual Or Financial Advisor"); // Reset the type state here
          props.toastMessage(response.message, "success");

          setMakeDisable(false);
        } catch (err) {
          setLoading(false);
          setShowMessage(true);
          setMakeDisable(false);
        }
      }
    },
  });

  useEffect(() => {
    const updateListHandler = (msg) => {
      fetchAllUsers();
    };
    socket.on("get_updated_list", updateListHandler);
    return () => {
      socket.off("get_updated_list", updateListHandler);
    };
  }, []);

  const reSendInvitation = (pfs_row_id) => {
    dispatch(pfs_re_invitation({ id: pfs_row_id }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          props.toastMessage(
            "Your invite to collaborate was sent successfully.",
            "success"
          );

          setShow(false);
        }
      })
      .catch((err) => {
        props.toastMessage("pfs_re_invitation :" + err.message, "error");
      });
  };

  const deleteRecord = (id) => {
    CapsyncDeleteMessage(true, "Delete", "", () => deleteFunc(id));
  };
  const deleteFunc = async (r_id) => {
    try {
      await dispatch(access_pfs_deleteUser({ pfs_id: r_id, wm_id: id }));
      props.toastMessage("This user has been deleted successfully.", "success");
      setShow(false);
      fetchAllUsers();
    } catch (err) {
      props.toastMessage("access_pfs_deleteUser :" + err.message, "error");
    }
  };

  const checkPermissionValue = async (params) => {
    if (params === 1) {
      setIsStandardDisabled(false);
      setIsViewOnlyDisabled(true);
    } else if (params === 2) {
      setIsStandardDisabled(true);
      setIsViewOnlyDisabled(false);
    }
    try {
      setLoading(true);

      const response = await dispatch(
        change_permission({
          to_permission: params,
          email: OffcanvasState.to_email,
          to_displayName: OffcanvasState.to_displayName,
          to_status: OffcanvasState.to_status,
          from_user_id: id,
        })
      );

      // socket.emit("collaboration_listing", {
      //   id: response.payload.from_id,
      // });

      setShow(false);
      setPermissionRadio(params);
      fetchAllUsers();
      props.toastMessage(
        "The user permissions have been updated successfully.",
        "success"
      );
      setLoading(false);
      setIsStandardDisabled(false);
      setIsViewOnlyDisabled(false);
    } catch (err) {
      props.toastMessage("change_permission :" + err.message, "error");
      setLoading(false);
    }
  };

  const closeModalFunc = () => {
    setModalShow(false);
    setShowMessage(false);
    setType("Individual Or Financial Advisor");
    formik.resetForm();
  };

  return (
    <>
      {props.is_loader.assets === true ||
      props.is_loader.liabilities === true ? (
        <SkeletonManageUser />
      ) : (
        <section className="request-main-section ">
          <Container fluid>
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                subTitle="Collaboration"
                mainUrl="/individual"
                pagetitle="Collaboration"
              />
              <Row>
                <Col lg={12}>
                  <div className="cs-tabs-flex">
                    <div className="buttons">
                      <div className="cs-tabs cs-primary">
                        {radioName.map((radioName, idx) => (
                          <Button
                            className={`cs-stroke-default-tab cs-tabs-height default-regular-h5 ${
                              mainTabName === radioName.value
                                ? "cs-stroke-active"
                                : ""
                            }`}
                            key={idx}
                            type="radio"
                            variant={
                              idx % 2
                                ? "manage-user-individual"
                                : "manage-user-wealth-manager"
                            }
                            name="radioName"
                            value={radioName.value}
                            checked={mainTabName === radioName.value}
                            onClick={(e) =>
                              setMainTabName(e.currentTarget.value)
                            }
                          >
                            {radioName.name}
                          </Button>
                        ))}
                      </div>
                    </div>

                    <div className="sidebar-add-account ">
                      <Button
                        type="submit"
                        className="default-regular-h5 cs-btn-icon-primary"
                        onClick={() => {
                          setModalShow(true);
                        }}
                        disabled={show}
                      >
                        <span className="cs-neutral-10">
                          <CapsyncIcon title="add-filled" size="18" />
                        </span>
                        Add User
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {mainTabName === "1" ? (
              <Row>
                <Col>
                  <div className="collaboration-data-table">
                    <ManageUserTable
                      data={managerUserData.individual}
                      mainTabName={mainTabName}
                      onClick={fetch_userdata_from_email}
                      deleteRecord={deleteRecord}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              // m-0 g-0 dashboard-listing-table manageuser-listing-container apply-style-manage-user
              <Row>
                <Col>
                  <div className="collaboration-data-table">
                    <ManageUserTable
                      data={managerUserData.wealth}
                      mainTabName={mainTabName}
                      onClick={fetch_userdata_from_email}
                      deleteRecord={deleteRecord}
                    />
                  </div>
                </Col>
              </Row>
            )}

            {show && (
              <OffCanva
                show={show}
                setShow={setShow}
                mainTabName={mainTabName}
                OffcanvasState={OffcanvasState}
                dynamicURL={dynamicURL}
                user_email={user_email}
                permissionRadio={permissionRadio}
                checkPermissionValue={checkPermissionValue}
                reSendInvitation={reSendInvitation}
                deleteRecord={deleteRecord}
                isViewOnlyDisabled={isViewOnlyDisabled}
                isStandardDisabled={isStandardDisabled}
              />
            )}

            <AddModalCollaboration
              modalShow={modalShow}
              setModalShow={setModalShow}
              fetchAllUsers={fetchAllUsers}
              toastMessage={props.toastMessage}
            />
          </Container>
        </section>
      )}
    </>
  );
};

export default manageUser;
