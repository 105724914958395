import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import LeftPart from "./LeftPart";
import { clearMessage } from "../../slices/message";
import { mfaPhoneAuthFirstPin, mfaPhoneAuth } from "../../slices/auth";
import Cookies from "universal-cookie";
import { loginStatusStore, deviceVerifyCookieStore } from "../../util/common";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import LogoImage from "../logoImage";
import { socket } from "../../config/Socket";
import { roleName } from "../../config/commonRoleName";
import { syncPlaidAccounts } from "../../config/plaidFunctions";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";

const SignInMFAuthPhonePin = () => {
  const [loading, setLoading] = useState(false);
  const [deviceVerify, setDeviceVerify] = useState(false);
  const [resendMsg, setResendMsg] = useState();
  const [otp, setOtp] = useState();
  const [msg, setMsg] = useState("");

  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const handleChange = (otp) => {
    dispatch(clearMessage());
    setMsg("");
    setResendMsg(false);
    setOtp(otp);
  };

  const handleRemember = (e) => {
    if (e.target.checked) {
      setDeviceVerify(e.target.checked);
    } else {
      setDeviceVerify(false);
      cookies.remove(`deviceAuth24Hr-${id}`);
    }
  };

  let phoneNumber = user.user.phoneNo;
  if (JSON.parse(localStorage.getItem("phone"))) {
    phoneNumber = JSON.parse(localStorage.getItem("phone"));
  }

  useEffect(() => {
    dispatch(clearMessage());
    setResendMsg(false);
  }, [dispatch]);

  const id = user && user.user.id;
  const email = user && user.user.email;

  const handleSubmit = (e) => {
    e.preventDefault();
    setResendMsg(false);
    setLoading(true);
    const MFAPhoneOtp = otp;
    if (MFAPhoneOtp) {
      dispatch(mfaPhoneAuthFirstPin({ email, user_id: id, MFAPhoneOtp }))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res.code === 200) {
            deviceVerifyCookieStore(id, deviceVerify);
            loginStatusStore(res.role, res.res_data.user.id);
            if (res.role === 1) {
              syncPlaidAccounts(res.res_data.user.id);
              socket.emit("fetch_user", {
                user_id: res.res_data.user.id,
                user_type: res.role,
                user_role: roleName.individual,
                logged_out: false,
              });
              socket.emit("user_logging_out", true);
              navigate("/individual");
            } else {
              socket.emit("fetch_user", {
                user_id: res.res_data.user.id,
                user_type: res.role,
                user_role:
                  res.role === 2
                    ? roleName.financial_advisor
                    : roleName.tax_professional,
                logged_out: false,
              });
              socket.emit("user_logging_out", true);
              if (res.role === 2) {
                navigate("/financial-advisor");
              } else {
                navigate("/professional");
              }
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };
  const resendMFAPhoneOTP = () => {
    setOtp("");
    dispatch(clearMessage());
    dispatch(mfaPhoneAuth({ user_id: id, email }))
      .then((res) => {
        setResendMsg(res.payload.message);
      })
      .catch((err) => {});
  };
  function showStarNumber(params) {
    let newContent = "";
    newContent += params;
    return (
      newContent.substring(0, 3) +
      "*******" +
      newContent.substring(10, newContent.length)
    );
  }

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="mfa-phone-pin"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col>
                <div className="auth-right-section">
                  <LogoImage />
                  <div className="cs-auth-form">
                    <h1 className="default-semi-bold-h1">
                      Mobile verification
                    </h1>
                    <hr className="head-underline" />
                    <div className="default-regular-h5">
                      <span className="cs-neutral-80 ">
                        We have sent a code to
                        <span className="cs-primary">
                          {" " + showStarNumber(phoneNumber)}
                        </span>
                      </span>
                    </div>
                    <div className="auth-form">
                      <Form onSubmit={handleSubmit}>
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          renderInput={(props) => <input {...props} />}
                          inputStyle="otp-txt"
                          containerStyle="otp-layout"
                          // isInputNum={true}
                          inputType="number"
                          shouldAutoFocus={true}
                        />
                        {/* {msg ? (
                          <span className="cs-msg default-regular-body-text-s cs-danger">
                            {msg}
                          </span>
                        ) : null} */}
                        {msg ? (
                          <div className="cs-msg default-regular-body-text-s cs-danger">
                            <span className="icon">
                              <CapsyncIcon title="info-filled" />
                            </span>
                            <span className="txt">{msg}</span>
                          </div>
                        ) : null}
                        <span className="default-regular-h5 cs-neutral-90 auth-phone-pin">
                          Didn't receive the code?{" "}
                          <Link
                            onClick={resendMFAPhoneOTP}
                            className="cs-primary">
                            &nbsp;Resend
                          </Link>
                        </span>
                        <span>
                          {message && (
                            <div className="cs-msg default-regular-body-text-s cs-danger">
                              <span className="icon">
                                <CapsyncIcon title="info-filled" />
                              </span>
                              <span className="cs-msg default-regular-body-text-s cs-danger">
                                {" "}
                                {message.message || message}
                              </span>
                            </div>
                          )}
                        </span>
                        {resendMsg && (
                          <div className="cs-msg default-regular-body-text-s cs-success">
                            <span className="icon cs-success">
                              <CapsyncIcon
                                title="verification-outlined"
                                size="18"
                              />
                            </span>
                            <span className="cs-msg default-regular-body-text-s cs-success">
                              {" "}
                              {resendMsg}
                            </span>
                          </div>
                        )}
                        <div key={`default-checkbox`}>
                          <Form.Check
                            type={"checkbox"}
                            id={`default-checkbox`}
                            feedbackType="invalid"
                            className="cs-form-check-box"
                            aria-label="option 1"
                            name="terms"
                            checked={deviceVerify}
                            onChange={handleRemember}
                            label={
                              <span className="default-light-body-text-m cs-neutral-90 cursor-pointer d-flex">
                                Trust this device for 180 days
                              </span>
                            }
                          />
                        </div>
                        <Button
                          type="submit"
                          className="cs-btn-primary lg-btn default-regular-h5 cs-ver-btn"
                          // disabled={loading}
                          // onClick={handleSubmit}
                        >
                          Verify account
                        </Button>
                      </Form>
                    </div>
                    {/* <Form>
                      <div key={`default-checkbox`}>
                        <Form.Check
                          type={"checkbox"}
                          id={`default-checkbox`}
                          feedbackType="invalid"
                          className="cs-form-check-box"
                          aria-label="option 1"
                          name="terms"
                          checked={deviceVerify}
                          onChange={handleRemember}
                          label={
                            <span className="default-light-body-text-m cs-neutral-90 cursor-pointer">
                              Remember this device for 24 Hours
                            </span>
                          }
                        />
                      </div>
                    </Form> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignInMFAuthPhonePin;
