import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { headerAddToken } from "../util/common";
import API from "../util/api";
import { localDataRemove } from "../config/finance";

export const user = createAsyncThunk(
  "/user/findall",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/user/findall", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const userUpdateImage = createAsyncThunk(
  "/user/update_image",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/user/update_image", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const userDeleteRecord = createAsyncThunk(
  "/user/delete/",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(`/user/delete/${id}`, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const userFindRecord = createAsyncThunk(
  "/user/find/",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(`/user/find/${id}`, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);


export const mfaphoneotpupdatewithotp = createAsyncThunk(
  "/user/Mfaphoneotpupdatewithpin",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/user/Mfaphoneotpupdatewithpin`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return message;
      }
      // return thunkAPI.rejectWithValue();
    }
  }
);

export const userInitialUpdateRecord = createAsyncThunk(
  "/user/initial_update",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/initial_update`, values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const userInitialModalSetup = createAsyncThunk(
  "/user/initial_modal_setup",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/user/initial_modal_setup`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const userMainFilter = createAsyncThunk(
  "/user/main_filter",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/main_filter`, values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaEmailUpdateRecord = createAsyncThunk(
  "/user/mfaemailupdate",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/mfaemailupdate`, values, header);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      // if (error.response.data.code === 401) {
      //   window.location.href = '/unauthorize'
      // } else {
      const message = error.response && error.response.data.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
      // }
    }
  }
);
//wealthmanager

export const mfaPhoneUpdateRecord = createAsyncThunk(
  "/user/mfaphoneupdate",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/mfaphoneupdate`, values, header);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const mfaPhoneUpdateRecordwithOtp = createAsyncThunk(
  "/user/mfaphoneupdatewithotpsend",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/user/mfaphoneupdatewithotpsend`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return message;
      }
      // return thunkAPI.rejectWithValue();
    }
  }
);
export const fileEncryptionUpdateRecord = createAsyncThunk(
  "/user/fileencryption",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/fileencryption`, values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;

        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaGoogleDeleteRecord = createAsyncThunk(
  "/user/mfagoogledeleterecord",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/user/mfagoogledeleterecord`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaPhoneDeleteRecord = createAsyncThunk(
  "/user/mfaphonedeleterecord",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `/user/mfaphonedeleterecord/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaSetupPhoneAuthPin = createAsyncThunk(
  "user/mfaSetupPhoneAuthPin",
  async ({ user_id, email, MFAPhoneOtp }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        "/user/mfaSetupPhoneAuthPin",
        { user_id, email, MFAPhoneOtp },
        header
      );
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        //const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(error.response.data));
        //return error.response.data;
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
//wealth-manager

export const createPfsUrl = createAsyncThunk(
  "user/generatecopyurl",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/generatecopyurl`, values, header);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const sendpfsemail = createAsyncThunk(
  "/user/sharepfs/senduseremail",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/user/sharepfs/senduseremail`,
        values,
        header
      );
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const signature_reminder = createAsyncThunk(
  "/hellosign/signature_reminder",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/hellosign/signature_reminder`,
        values,
        header
      );
      return response.data;
    } catch (error) {
      const message = error && error;
      thunkAPI.dispatch(setMessage(message));
      return message
    }
  }
);


export const sendCancelemail = createAsyncThunk(
  "/user/shareEmail",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/shareEmail`, values, header);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const getPendingMailList = createAsyncThunk(
  "/get_pending_mail_list",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/get_pending_mail_list`, values, header);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const getPfsIndividualUsers = createAsyncThunk(
  "/user/get_pfs_individual_users",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/user/get_pfs_individual_users`, values, header);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const fileEncryptionUpdate = createAsyncThunk(
  "/wealthmanager/wm_fileencryption",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(`/wealthmanager/wm_fileencryption`, values, header);
      // thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;

        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);


const initialState = {};

const userSlice = createSlice({
  name: "User",
  initialState,
  extraReducers: (builder) => {

  }
});

const { reducer } = userSlice;
export default reducer;
