import React from "react";
import { Button, Image, Modal } from "react-bootstrap";

// COMPONENT IMPORTS
import ErrorValidation from "../../../assets/images/other/error-validation.svg";

const SessionLogoutModal = ({ sessionLogoutModalOpen, closeModal }) => {
  return (
    <Modal
      show={sessionLogoutModalOpen}
      onHide={closeModal}
      centered
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="cs-md-modal payment-success"
      className="cs-session-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          Attention needed
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="success-head">
          <span>
            <Image
              src={ErrorValidation}
              alt="Error Validation"
              width={100}
              height={100}
            />
          </span>
          <p className="default-light-body-text-m cs-neutral-90">
            Your account has been signed out because it was accessed on another
            browser or device. You can only remain signed in on one active
            session at a time.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cs-btn-primary lg-btn default-regular-h5"
          onClick={closeModal}
        >
          Back to Sign In
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionLogoutModal;
