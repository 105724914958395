import React from "react";
import { Image, Button, Modal, ModalFooter } from "react-bootstrap";
import ErrorValidation from "../../../../assets/images/other/error-validation.svg";

const UnsupportedFile = (props) => {
  const { type, unsupportedModal, onClose, onDownload, setDownloadMedia } =
    props;
  return (
    <Modal
      id="confirm-edit-profile"
      show={unsupportedModal}
      onHide={() => onClose()}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal cs-delete-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
          Unsupported files
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <Image
            src={ErrorValidation}
            alt="Error Validation"
            width={100}
            height={100}
          />
          <p className="default-light-body-text-m cs-neutral-90">
            {type == "file-management"
              ? "No Preview Available"
              : type == "protected-pdf-file"
              ? "No Preview Available for Password Protected file"
              : "No preview available. To preview this file, please restore it to the file management system. Once restored,then you can Download the file."}
          </p>
        </div>
      </Modal.Body>
      <ModalFooter className="cs-modal-btn cs-center-btn">
        <Button
          className="cs-btn-secondary md-btn default-regular-h5 btn btn-primary"
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        {(type == "file-management" || type == "protected-pdf-file") && (
          <Button
            type="submit"
            className="cs-btn-primary md-btn default-regular-h5 btn btn-primary"
            onClick={() => {
              onDownload && onDownload();
              onClose();
              setDownloadMedia && setDownloadMedia([]);
            }}
          >
            Download
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default UnsupportedFile;
