import * as yup from "yup";
import { checkDomainAccept } from "../../../../util/common";
// import { setAddressString } from "../../../../util/common";

export const uniValidation = {
  email: {
    validEmail: "Enter a valid email address",
    required: "Enter email address",
    sapces: "Email cannot contain spaces",
    allowedFormat: /^[a-z0-9]+([_+.][a-z0-9]+)*@[a-z0-9.-]+\.[a-z]{2,}$/i,
  },
  password: {
    validPass: "Incorrect password",
    require: "Enter password",
    strongWeak: "Weak password",
    number: "Password must contain a number",
    lowercase: "Password must contain a lowercase letter",
    uppercase: "Password must contain an uppercase letter",
    symbol: "Password must contain a symbol",
    max: "Password cannot exceed 20 characters",
    min: "Password must contain at least 8 characters",
    sapces: "Password cannot contain spaces",
    comman: "Enter a valid password",
  },
  confirm_password: {
    validPass: "Incorrect password",
    require: "Confirm password",
    match: "Passwords do not match",
    number: "Password must contain a number",
    lowercase: "Password must contain a lowercase letter",
    uppercase: "Password must contain an uppercase letter",
    symbol: "Use a symbol",
    max: "Password cannot exceed 20 characters",
    min: "Password must contain at least 8 characters",
    sapces: "Password cannot contain spaces",
    comman: "Enter a valid password",
  },
  first_name: {
    max: "Maximum 40 characters allowed",
    require: "Enter first name",
  },
  last_name: {
    max: "Maximum 40 characters allowed",
    require: "Enter last name",
  },
  phone_number: {
    valid: "Enter a valid phone number",
    requrie: "Enter phone number",
  },
  otp: {
    require: "Enter code",
    valid: "Enter a valid code",
  },
  street_name: {
    require: "Enter street address",
    valid: "Enter a valid address",
  },
  city: {
    valid: "Enter a valid city",
    require: "Enter city",
    notMatch: "City entered does not match the Zip code entered",
  },
  state: {
    notMatch: "State entered does not match the Zip code entered",
  },
  zip_code: {
    valid: "Enter a valid ZIP code",
    require: "Enter ZIP code",
  },
  notes: {
    valid: "Notes cannot have more than 200 characters.",
  },
  assets_and_liabilities: {
    institution: {
      require: "Enter institution",
      min: "Institution name must be at least 2 characters",
      max: "Maximum 40 characters allowed",
    },
    balance: {
      require: "Enter balance",
      nonzero: "Balance must be greater than zero",
      twodigits: "Maximum two digits are allowed after the decimal point",
    },
    ownership: {
      require: "Enter ownership (%)",
    },
    property_name: {
      require: "Enter name of debtor",
      whitespace: "Avoid leading or trailing whitespace",
      min: "Debtor name must be at least 2 characters",
      max: "Maximum 40 characters allowed",
    },
    loan_amount: {
      require: "Enter loan amount",
      greate: "Loan amount must be greater than zero",
    },
    loan_period: {
      require: "Enter loan term",
      require: "Enter loan term",
      years: "Loan Term must be less then 40 years",
      months: "Loan term must be less than 480 months",
      nonzero: "Loan term must be greater than zero",
    },
    annual_rate: {
      require: "Enter Interest rate",
      number: "Enter numeric values only",
      valid: "Enter a valid number",
      nonzero: "Interest rate must be greater than zero",
    },
    balance_due: {
      require: "Enter balance due",
      number: "Enter numeric values only",
      positive: "Balance due must be greater than zero",
      nonzero: "Balance due must be greater than zero",
      max: "Maximum two digits are allowed after the decimal point",
    },
    loan_type: {
      max: "Maximum 40 characters allowed",
    },
    company: {
      require: "Enter institution ",
      min: "Company name must be at least 2 characters",
      max: "Maximum 40 characters allowed",
    },
    beneficiary: {
      require: "Enter beneficiary",
      max: "Maximum 40 characters allowed",
    },
    cash_value: {
      require: "Enter cash value",
      nonzero: "Cash value must be greater than zero",
      max: "Maximum two digits are allowed after the decimal point",
    },
    description: {
      require: "Enter description",
      max: "Maximum 100 characters allowed",
      min: "Description must be at least 2 characters",
    },
    creditor_name: {
      min: "Creditor name must be at least 2 characters",
      max: "Maximum 40 characters allowed",
      require: "Enter name of creditor",
    },
    market_value: {
      require: "Enter market value",
      nonzero: "Market value must be greater than zero",
      max: "Maximum two digits are allowed after the decimal point",
    },
    vin: {
      valid: "Enter a valid VIN number",
      require: "Enter VIN number",
    },
    entity_name: {
      require: "Enter enitity name",
      min: "Entity name must be at least 2 characters",
      max: "Maximum 40 characters allowed",
    },
    industry: {
      min: "Industry name must be at least 2 characters",
      max: "Maximum 40 characters allowed",
      requrie: "Enter industry",
    },
    collateral: {
      max: "Maximum 40 characters allowed",
      min: "Collateral name must be at least 2 characters",
      require: "Enter collateral name",
    },
  },
};

const liability_accountPayable_annualRateSchema = yup
  .number()
  .typeError("Interest rate should be a number")
  .when("loan_period", {
    is: (loan_period) => !!loan_period,
    then: yup
      .number()
      .required("Interest rate is required")
      .moreThan(0, "Interest rate should be greater than zero")
      // .lessThan(101, "Interest rate should not be more than 100")
      .test(
        "valid-percentage",
        "Interest rate should not be more than 100",
        (value) => {
          if (value && (value < 0 || value > 100)) {
            return false;
          }
          return true;
        }
      ),
    otherwise: yup.number(),
  });

const liability_accountPayable_validationSchema = yup.object().shape({
  creditor_name: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, uniValidation.assets_and_liabilities.creditor_name.min)
    .max(40, uniValidation.assets_and_liabilities.creditor_name.max)
    .required(uniValidation.assets_and_liabilities.creditor_name.require)
    .test(
      "is-not-only-spaces",
      uniValidation.assets_and_liabilities.creditor_name.require,
      (value) => {
        return value.trim() !== "";
      }
    ),

  creditor_email: yup
    .string()
    .transform((curr, orig) => (orig === " " || orig === "" ? null : curr))
    // .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, uniValidation.email.validEmail)
    .test(
      "no-more-than-30-before-at",
      uniValidation.email.validEmail,
      (value) => (value ? value.split("@")[0].length <= 30 : true)
    )
    .test(
      "at-least-1-character-before-at",
      "Enter at least 1 character before @",
      (value) => (value ? value.split("@")[0].length > 0 : true)
    )
    .test("custom-email-format", uniValidation.email.validEmail, (value) => {
      return value ? uniValidation.email.allowedFormat.test(value) : true;
    })
    .email(uniValidation.email.validEmail)
    .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
      return checkDomainAccept(value);
    })
    .nullable(),
  apr_percentage: yup
    .number()
    .typeError("Enter a valid number")
    // .max(100, "APR should not be more than 100")
    .test(
      "is-valid-decimal",
      "Maximum two digits are allowed after the decimal point",
      (value) => {
        if (value === undefined || value === null) {
          return true;
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),

  minimum_payment: yup
    .string()

    .test(
      "is-valid-decimal",
      "Maximum two digits are allowed after the decimal point",
      (value) => {
        if (value === undefined || value === null) {
          return true;
        }
        return /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value);
      }
    ),
  // .test(
  //     'Is positive?',
  //     'Balance must be a positive number',
  //     (value) => !!value && !value.includes('-')
  // ),

  balance_due: yup
    .string()
    .required(uniValidation.assets_and_liabilities.balance_due.require)
    .test(
      "non-zero",
      uniValidation.assets_and_liabilities.balance_due.nonzero,
      (value) => Number(value.replace(/,/g, "")) !== 0
    )
    .test(
      "Is positive?",
      uniValidation.assets_and_liabilities.balance_due.positive,
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    .test(
      "checkDecimal",
      uniValidation.assets_and_liabilities.balance_due.max,
      (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    ),
  // minimum_payment: yup
  //   .string()
  //   .required("Please enter payment"),
  // .test(
  //   "non-zero",
  //   "payment must be greater than zero",
  //   (value) => parseFloat(value) !== 0
  // ),
  /** Type should be optional */
  // type: yup.string()
  //   .required("Please enter Type")
  //   .max(40, "Type must be 40 characters maximum")
  //   .test(
  //     "whitesspace",
  //     "Whitespace is not allowed",
  //     (value) => !(/^\s+|\s+$/g).test(value)
  //   ),
  // annual_rate: yup.lazy(() => liability_accountPayable_annualRateSchema),
  // annual_rate: yup.string()
  //   .required("Please rate of interest"),

  date_updated: yup.date().required("Please select updated date"),
  // .max(addOneDay(), "Future date can not be accepted"),
});

const liability_unpaidTexes_validationSchema = yup.object().shape({
  description: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, uniValidation.assets_and_liabilities.description.min)
    .max(100, uniValidation.assets_and_liabilities.description.max)
    .required(uniValidation.assets_and_liabilities.description.require)
    .test(
      "is-not-only-spaces",
      uniValidation.assets_and_liabilities.description.require,
      (value) => {
        return value.trim() !== "";
      }
    ),
  balance_due: yup
    .string()
    .test(
      "Is positive?",
      "Balance due must be greater than zero",
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    // )
    .test(
      "non-zero",
      "Balance due must be greater than zero",
      (value) => Number(value.replace(/,/g, "")) !== 0
    )
    .required("Enter balance due"),
  date_updated: yup.date().required("Please select updated date"),
  //.min("2005-4-1", "Date is too late") // Due to error in Iphone, we not checking this case.
  // .max(new Date(), "Future date can not be accepted"),
});

const liability_otherLiabilities_validationSchema = yup.object().shape({
  description: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, uniValidation.assets_and_liabilities.description.min)
    .max(100, uniValidation.assets_and_liabilities.description.max)
    .required(uniValidation.assets_and_liabilities.description.require)
    .test(
      "is-not-only-spaces",
      uniValidation.assets_and_liabilities.description.require,
      (value) => {
        return value.trim() !== "";
      }
    ),
  balance_due: yup
    .string()
    .required(uniValidation.assets_and_liabilities.balance_due.require)
    .test(
      "non-zero",
      uniValidation.assets_and_liabilities.balance_due.nonzero,
      (value) => Number(value.replace(/,/g, "")) !== 0
    )
    .test(
      "Is positive?",
      uniValidation.assets_and_liabilities.balance_due.positive,
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    .test(
      "non-zero",
      "Balance due must be greater than zero",
      (value) => Number(value.replace(/,/g, "")) !== 0
    ),

  date_updated: yup.date().required("Please select updated date"),
  //.min("2005-4-1", "Date is too late") // Due to error in Iphone, we not checking this case.
  // .max(new Date(), "Future date can not be accepted"),
});

const liability_realEstateMortgage_validationSchema = yup.object().shape({
  creditor_name: yup.string().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) =>
      schema
        .required("Enter name of creditor")
        .test("is-not-only-spaces", "Enter name of creditor", (value) => {
          return value.trim() !== "";
        })
        // .min(2, "Name of Creditor must be at least 2 characters")
        .max(40, "Maximum 40 characters allowed"),
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    otherwise: (schema) => schema,
  }),
  street_name: yup
    .string()
    .required(uniValidation.street_name.require)
    .test("is-not-only-spaces", uniValidation.street_name.require, (value) => {
      return value.trim() !== "";
    })
    .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
    .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid),
  city: yup
    .string()
    .required(uniValidation.city.require)
    .test("is-not-only-spaces", uniValidation.city.require, (value) => {
      return value.trim() !== "";
    })
    .min(2, uniValidation.city.valid),
  state: yup
    .string()
    .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
    .min(2, "Required at least 2 characters"),
  zip_code: yup
    .string()
    .required(uniValidation.zip_code.require)
    .test("is-not-only-spaces", uniValidation.zip_code.require, (value) => {
      return value.trim() !== "";
    })
    .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
    .min(5, uniValidation.zip_code.valid)
    .max(5, uniValidation.zip_code.valid),
  creditor_email: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, uniValidation.email.validEmail)
    .email(uniValidation.email.validEmail)
    .test(
      "no-more-than-30-before-at",
      uniValidation.email.validEmail,
      (value) => (value ? value.split("@")[0].length <= 30 : true)
    )
    .test(
      "at-least-1-character-before-at",
      "Enter at least 1 character before @",
      (value) => (value ? value.split("@")[0].length > 0 : true)
    )
    .test("custom-email-format", uniValidation.email.validEmail, (value) => {
      return value ? uniValidation.email.allowedFormat.test(value) : true;
    })
    .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
      return checkDomainAccept(value);
    }),
  loan_amount: yup
    .string()
    .required(uniValidation.assets_and_liabilities.loan_amount.require)
    .test(
      "non-zero",
      uniValidation.assets_and_liabilities.loan_amount.greate,
      (value) => {
        const numericValue = Number(value.replace(/,/g, ""));
        return numericValue !== 0;
      }
    ),
  date_Loan: yup.date().required("Please select Loan date"),
  loan_period: yup
    .number()
    .moreThan(0, uniValidation.assets_and_liabilities.loan_period.nonzero)
    .test(
      "LoanPeriodInYear",
      uniValidation.assets_and_liabilities.loan_period.years,
      (value, context) =>
        context.parent.frequency === "Yearly" && value
          ? Number(value) <= 40
          : true
    )
    .test(
      "LoanPeriodInMonth",
      uniValidation.assets_and_liabilities.loan_period.months,
      (value, context) =>
        context.parent.frequency === "Monthly" && value
          ? Number(value) <= 480
          : true
    )
    .required(uniValidation.assets_and_liabilities.loan_period.require),
  annual_rate: yup
    .number()
    .typeError(uniValidation.assets_and_liabilities.annual_rate.number)
    .required(uniValidation.assets_and_liabilities.annual_rate.require),
  // .moreThan(0, uniValidation.assets_and_liabilities.annual_rate.nonzero)
  // .lessThan(101, "Interest rate should not be more than 100")
  // .test(
  //   "valid-percentage",
  //   "Interest rate should not be more than 100",
  //   (value) => {
  //     if (value && (value < 0 || value > 100)) {
  //       return false;
  //     }
  //     return true;
  //   }
  // )
  // maturity_date: yup.date(),

  ownership: yup
    .number()
    // .positive("Ownership can't start with a minus")
    // .moreThan(0, "Ownership should not be zero")
    // .lessThan(101, "Ownership should not be more than 100")
    .required(uniValidation.assets_and_liabilities.ownership.require)
    .test(
      "is-not-only-spaces",
      uniValidation.assets_and_liabilities.ownership.require,
      (value) => {
        return value && value.toString().trim() !== "";
      }
    ),

  payment: yup
    .string()
    .test(
      "non-zero",
      "payment must be greater than zero",
      (value) => parseFloat(value) !== 0
    ),

  mortgage_balance: yup.string().when("is_manual", {
    is: (is_manual) => !!is_manual || !is_manual,
    then: (schema) =>
      schema
        .required("Enter mortgage balance")
        .test(
          "Is positive?",
          "Mortgage Balance must be a positive number",
          (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        )
        // .test(
        //   'checkDecimal',
        //   'Maximum two digits are allowed after the decimal point',
        //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

        // )
        .test(
          "non-zero",
          "Mortgage balance must be greater than zero",
          (value) => Number(value.replace(/,/g, "")) !== 0
        )
        .test(
          "balanceDue",
          "Mortgage balance must be lower than the loan amount",
          (value, context) =>
            context.parent.loan_amount &&
            value &&
            Number(context.parent.loan_amount.replace(/,/g, "")) >=
              Number(value.replace(/,/g, ""))
        ),
    otherwise: (schema) => schema,
  }),

  date_updated: yup.date(),
  // .max(new Date(), "Future date can not be accepted"),

  loan_type: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    .max(40, "Maximum 40 characters allowed"),
  // .test(
  //   "whitesspace",
  //   "Whitespace is not allowed",
  //   (value) => !(/^\s+|\s+$/g).test(value)
  // )
  collateral: yup.string().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) =>
      schema
        .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, "Collateral name must be at least 2 characters")
        .max(40, "Maximum 40 characters allowed"),
    otherwise: (schema) => schema,
  }),

  //.min("2005-4-1", "Date is too late") // Due to error in Iphone, we not checking this case.
  // .max(new Date(), "Future date can not be accepted"),
});

const liability_installment_auto_validationSchema = yup.object().shape({
  creditor_email: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, uniValidation.email.validEmail)
    .test(
      "no-more-than-30-before-at",
      uniValidation.email.validEmail,
      (value) => (value ? value.split("@")[0].length <= 30 : true)
    )
    .test(
      "at-least-1-character-before-at",
      "Enter at least 1 character before @",
      (value) => (value ? value.split("@")[0].length > 0 : true)
    )
    .test("custom-email-format", uniValidation.email.validEmail, (value) => {
      return value ? uniValidation.email.allowedFormat.test(value) : true;
    })
    .email(uniValidation.email.validEmail)
    .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
      return checkDomainAccept(value);
    }),
  description: yup
    .string()
    .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, "Description must be at least 2 characters")
    .max(40, "Description must be 40 characters maximum")
    .required("Enter description"),
  creditor_name: yup.string().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) =>
      schema
        .required("Enter name of creditor")
        // .min(2, "Name of Creditor must be at least 2 characters")
        .max(40, "Maximum 40 characters allowed"),
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    otherwise: (schema) => schema,
  }),
  loan_amount: yup.string().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) => schema.required("Enter Loan amount"),
    otherwise: (schema) => schema,
  }),
  date_Loan: yup.date().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) => schema.required("Please select Loan date"),
    otherwise: (schema) => schema,
  }),
  balance_due: yup
    .string()
    .when("is_manual", {
      is: (is_manual) => !!is_manual,
      then: (schema) => schema.required("Enter balance due "),
      otherwise: (schema) => schema,
    })
    .test(
      "Is positive?",
      "Balance due must be greater than zero",
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    .test(
      "non-zero",
      "Balance due must be greater than zero",
      (value) => Number(value.replace(/,/g, "")) !== 0
    ),
  //   .test(
  //     'balanceDue',
  //     'Balance due must be lower than the loan amount',
  //     (value, context) => context.parent.loan_amount && value && (Number(context.parent.loan_amount.replace(/,/g, "")) >= Number(value.replace(/,/g, "")))
  // )
  loan_period: yup
    .number()
    .moreThan(0, uniValidation.assets_and_liabilities.loan_period.nonzero)
    .test(
      "LoanPeriodInYear",
      uniValidation.assets_and_liabilities.loan_period.years,
      (value, context) =>
        context.parent.frequency === "Yearly" && value
          ? Number(value) <= 40
          : true
    )
    .test(
      "LoanPeriodInMonth",
      uniValidation.assets_and_liabilities.loan_period.months,
      (value, context) =>
        context.parent.frequency === "Monthly" && value
          ? Number(value) <= 480
          : true
    )
    .required(uniValidation.assets_and_liabilities.loan_period.require),
  annual_rate: yup
    .number()
    .typeError(uniValidation.assets_and_liabilities.annual_rate.number)
    .required(uniValidation.assets_and_liabilities.annual_rate.require),
  // .moreThan(0, uniValidation.assets_and_liabilities.annual_rate.nonzero)
  // .lessThan(101, "Interest rate should not be more than 100")
  // .test(
  //   "valid-percentage",
  //   "Interest rate should not be more than 100",
  //   (value) => {
  //     if (value && (value < 0 || value > 100)) {
  //       return false;
  //     }
  //     return true;
  //   }
  // )
  // maturity_date: yup.date(),

  payment: yup
    .string()
    .test(
      "non-zero",
      "payment must be greater than zero",
      (value) => parseFloat(value) !== 0
    ),

  date_updated: yup.date(),
  // .max(new Date(), "Future date can not be accepted"),

  loan_type: yup
    .string()
    .required("Please enter type")
    .max(40, "Maximum 40 characters allowed")
    .test(
      "whitesspace",
      "Whitespace is not allowed",
      (value) => !/^\s+|\s+$/g.test(value)
    ),

  collateral: yup
    .string()
    .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, "Collateral name must be at least 2 characters")
    .max(40, "Maximum 40 characters allowed"),
});

const liability_notesPayable_annualRateSchema = () =>
  yup
    .number()
    .typeError("Interest rate should be a number")
    .when("loan_period", {
      is: (loan_period) => !!loan_period,
      then: (schema) =>
        schema
          .required("Interest rate is required")
          .moreThan(0, "Interest rate should be greater than zero")
          .lessThan(101, "Interest rate should not be more than 100")
          .test(
            "valid-percentage",
            "Interest rate should not be more than 100",
            (value) => {
              if (value && (value < 0 || value > 100)) {
                return false;
              }
              return true;
            }
          ),
      otherwise: (schema) => schema,
    });
//-------------Add account form validation-------------//
const liability_notesPayable_validationSchema = yup.object().shape({
  creditor_email: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, uniValidation.email.validEmail)
    .test(
      "no-more-than-30-before-at",
      uniValidation.email.validEmail,
      (value) => (value ? value.split("@")[0].length <= 30 : true)
    )
    .test(
      "at-least-1-character-before-at",
      "Enter at least 1 character before @",
      (value) => (value ? value.split("@")[0].length > 0 : true)
    )
    .test("custom-email-format", uniValidation.email.validEmail, (value) => {
      return value ? uniValidation.email.allowedFormat.test(value) : true;
    })
    .email(uniValidation.email.validEmail)
    .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
      return checkDomainAccept(value);
    }),
  creditor_name: yup.string().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) =>
      schema
        .required("Enter name of creditor")
        // .min(2, "Name of Creditor must be at least 2 characters")
        .max(40, "Maximum 40 characters allowed")
        .test("is-not-only-spaces", "Enter name of creditor", (value) => {
          return value.trim() !== "";
        }),
    otherwise: (schema) => schema,
  }),
  loan_amount: yup
    .string()
    .when("is_manual", {
      is: (is_manual) => !!is_manual,
      then: (schema) => schema.required("Enter loan amount"),
      // otherwise: (schema) => schema,
    })
    .test(
      "Is positive?",
      "Loan amount must be greater than zero",
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    .test(
      "non-zero",
      "Loan amount must be greater than zero",
      (value) => Number(value.replace(/,/g, "")) !== 0
    ),
  date_Loan: yup.date().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) => schema.required("Please select Loan date"),
    otherwise: (schema) => schema,
  }),
  balance_due: yup
    .string()
    .when("is_manual", {
      is: (is_manual) => !!is_manual,
      then: (schema) => schema.required("Enter balance due"),
      otherwise: (schema) => schema,
    })
    .test(
      "Is positive?",
      "Balance due must be greater than zero",
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    .test(
      "non-zero",
      "Balance due must be greater than zero",
      (value) => Number(value.replace(/,/g, "")) !== 0
    ),
  //   .test(
  //     'balanceDue',
  //     'Balance due must be lower than the loan amount',
  //     (value, context) => context.parent.loan_amount && value && (Number(context.parent.loan_amount.replace(/,/g, "")) >= Number(value.replace(/,/g, "")))
  // )
  loan_period: yup
    .number()
    .moreThan(0, uniValidation.assets_and_liabilities.loan_period.nonzero)
    .test(
      "LoanPeriodInYear",
      uniValidation.assets_and_liabilities.loan_period.years,
      (value, context) =>
        context.parent.frequency === "Yearly" && value
          ? Number(value) <= 40
          : true
    )
    .test(
      "LoanPeriodInMonth",
      uniValidation.assets_and_liabilities.loan_period.months,
      (value, context) =>
        context.parent.frequency === "Monthly" && value
          ? Number(value) <= 480
          : true
    )
    .required(uniValidation.assets_and_liabilities.loan_period.require),
  annual_rate: yup
    .number()
    .typeError(uniValidation.assets_and_liabilities.annual_rate.number)
    .required(uniValidation.assets_and_liabilities.annual_rate.require),
  // .moreThan(0, uniValidation.assets_and_liabilities.annual_rate.nonzero)
  // maturity_date: yup.date(),

  payment: yup
    .string()
    .test(
      "non-zero",
      "payment must be greater than zero",
      (value) => parseFloat(value) !== 0
    ),

  date_updated: yup.date(),
  // .max(new Date(), "Future date can not be accepted"),

  /** should be optional  */
  // loan_type: yup.string()
  //   .required("Please enter type")
  //   .max(40, "Type must be 40 characters maximum")
  //   .test(
  //     "whitesspace",
  //     "Whitespace is not allowed",
  //     (value) => !(/^\s+|\s+$/g).test(value)
  //   ),

  collateral: yup
    .string()
    .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, "Collateral name must be at least 2 characters")
    .max(40, "Maximum 40 characters allowed"),
});

const liability_install_auto_validationSchema = yup.object().shape({
  creditor_email: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, uniValidation.email.validEmail)
    .test(
      "no-more-than-30-before-at",
      uniValidation.email.validEmail,
      (value) => (value ? value.split("@")[0].length <= 30 : true)
    )
    .test(
      "at-least-1-character-before-at",
      "Enter at least 1 character before @",
      (value) => (value ? value.split("@")[0].length > 0 : true)
    )
    .test("custom-email-format", uniValidation.email.validEmail, (value) => {
      return value ? uniValidation.email.allowedFormat.test(value) : true;
    })
    .email(uniValidation.email.validEmail)
    .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
      return checkDomainAccept(value);
    }),
  creditor_name: yup.string().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) =>
      schema
        .required("Enter name of creditor")
        .test("is-not-only-spaces", "Enter name of creditor", (value) => {
          return value.trim() !== "";
        })
        // .min(2, "Name of Creditor must be at least 2 characters")
        .max(40, "Maximum 40 characters allowed"),
    // matches(/^\S.*$/, "Whitespace is not allowed")
    otherwise: (schema) => schema,
  }),
  loan_amount: yup
    .string()
    .when("is_manual", {
      is: (is_manual) => !!is_manual,
      then: (schema) => schema.required("Enter Loan amount"),
      // otherwise: (schema) => schema,
    })
    .test(
      "Is positive?",
      "Loan amount must be greater than zero",
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    // )
    .test(
      "non-zero",
      "Loan amount due must be greater than zero",
      (value) => Number(value.replace(/,/g, "")) !== 0
    ),
  date_Loan: yup.date().when("is_manual", {
    is: (is_manual) => !!is_manual,
    then: (schema) => schema.required("Please select Loan date"),
    otherwise: (schema) => schema,
  }),
  balance_due: yup
    .string()
    .when("is_manual", {
      is: (is_manual) => !!is_manual,
      then: (schema) => schema.required("Enter balance due"),
      otherwise: (schema) => schema,
    })
    .test(
      "Is positive?",
      "Balance due must be greater than zero",
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    // .test(
    //   'checkDecimal',
    //   'Maximum two digits are allowed after the decimal point',
    //   (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    .test(
      "non-zero",
      "Balance Due must be greater than zero",
      (value) => Number(value.replace(/,/g, "")) !== 0
    ),
  // .test(
  //   'balanceDue',
  //   'Balance due must be lower than the loan amount',
  //   (value, context) => context.parent.loan_amount && value && (Number(context.parent.loan_amount.replace(/,/g, "")) >= Number(value.replace(/,/g, "")))
  // )
  loan_period: yup
    .number()
    .moreThan(0, uniValidation.assets_and_liabilities.loan_period.nonzero)
    .test(
      "LoanPeriodInYear",
      uniValidation.assets_and_liabilities.loan_period.years,
      (value, context) =>
        context.parent.frequency === "Yearly" && value
          ? Number(value) <= 40
          : true
    )
    .test(
      "LoanPeriodInMonth",
      uniValidation.assets_and_liabilities.loan_period.months,
      (value, context) =>
        context.parent.frequency === "Monthly" && value
          ? Number(value) <= 480
          : true
    )
    .required(uniValidation.assets_and_liabilities.loan_period.require),
  annual_rate: yup
    .number()
    .typeError(uniValidation.assets_and_liabilities.annual_rate.number)
    .required(uniValidation.assets_and_liabilities.annual_rate.require),
  // .moreThan(0, uniValidation.assets_and_liabilities.annual_rate.nonzero)
  // .lessThan(101, "Interest rate should not be more than 100")
  // .test(
  //   "valid-percentage",
  //   "Interest rate should not be more than 100",
  //   (value) => {
  //     if (value && (value < 0 || value > 100)) {
  //       return false;
  //     }
  //     return true;
  //   }
  // )
  // maturity_date: yup.date(),

  payment: yup
    .string()
    .test(
      "non-zero",
      "payment must be greater than zero",
      (value) => parseFloat(value) !== 0
    ),

  date_updated: yup.date(),
  // .max(new Date(), "Future date can not be accepted"),

  loan_type: yup.string().max(40, "Maximum 40 characters allowed"),
  // .test(
  //   "whitesspace",
  //   "Whitespace is not allowed",
  //   (value) => !(/^\s+|\s+$/g).test(value)
  // )
  // .max(40, "Type must be 40 characters maximum")
  // .test(
  //   "whitesspace",
  //   "Whitespace is not allowed",
  //   (value) => !(/^\s+|\s+$/g).test(value)
  // )

  collateral: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, uniValidation.assets_and_liabilities.collateral.min)
    .max(40, uniValidation.assets_and_liabilities.collateral.max),
});

const liability_lifeInsurance_validationSchema = yup.object().shape({
  company: yup
    .string()
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, uniValidation.assets_and_liabilities.company.min)
    .max(40, uniValidation.assets_and_liabilities.company.max)
    .required(uniValidation.assets_and_liabilities.company.require)
    .test(
      "is-not-only-spaces",
      uniValidation.assets_and_liabilities.company.require,
      (value) => {
        return value.trim() !== "";
      }
    ),
  insure_person_name: yup
    .string()
    .matches(/^\S.*$/, "Whitespace is not allowed")
    .min(2, "Name must be at least 2 characters")
    .max(100, "Name must be 20 characters maximum")
    .required("Please enter insured"),
  beneficiary: yup
    .string()
    .required(uniValidation.assets_and_liabilities.beneficiary.require)
    .test(
      "is-not-only-spaces",
      uniValidation.assets_and_liabilities.beneficiary.require,
      (value) => {
        return value.trim() !== "";
      }
    )
    // .matches(/^\S.*$/, "Whitespace is not allowed")
    // .min(2, "Beneficiary name must be at least 2 characters")
    .max(40, "Maximum 40 characters allowed"),
  // .test(
  //     "whitesspace",
  //     "Whitespace is not allowed",
  //     (value) => !(/^\s+|\s+$/g).test(value)
  // ),
  balance_due: yup.string().required("Enter balance due "),
  cash_value: yup
    .string()
    .required("Enter cash value")
    .test(
      "non-zero",
      uniValidation.assets_and_liabilities.cash_value.nonzero,
      (value) => Number(value.replace(/,/g, "")) !== 0
    )
    .test(
      "Is positive?",
      uniValidation.assets_and_liabilities.cash_value.nonzero,
      (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    )
    .test(
      "checkDecimal",
      uniValidation.assets_and_liabilities.cash_value.max,
      (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    ),
  date_updated: yup.date().required("Please select updated date"),
  // .max(new Date(), "Future date can not be accepted"),
});

const liability_installmentsAcc_annualRateSchema = yup
  .number()
  .typeError("Interest rate should be a number")
  .when("loan_period", {
    is: (loan_period) => !!loan_period,
    then: yup
      .number()
      .required("Interest rate is required")
      .moreThan(0, "Interest rate should be greater than zero")
      .lessThan(101, "Interest rate should not be more than 100")
      .test(
        "valid-percentage",
        "Interest rate should not be more than 100",
        (value) => {
          if (value && (value < 0 || value > 100)) {
            return false;
          }
          return true;
        }
      ),
    otherwise: yup.number(),
  });

const validationObj = {
  liability_accountPayable_validationSchema:
    liability_accountPayable_validationSchema,
  liability_installment_auto_validationSchema:
    liability_install_auto_validationSchema,
  liability_installment_other_validationSchema:
    liability_notesPayable_validationSchema,
  liability_unpaidTexes_validationSchema:
    liability_unpaidTexes_validationSchema,
  liability_otherLiabilities_validationSchema:
    liability_otherLiabilities_validationSchema,
  liability_realEstateMortgage_validationSchema:
    liability_realEstateMortgage_validationSchema,
  liability_notesPayable_validationSchema:
    liability_notesPayable_validationSchema,
  liability_lifeInsurance_validationSchema:
    liability_lifeInsurance_validationSchema,
};

export default validationObj;
