import React from "react";
import CapsyncIcon from "./CapsyncIcon";
import {
  checkConsecutiveLetters,
  checkDictionary,
  dictionaryWords,
} from "../util/common";

const PasswordRequirement = ({ password }) => {
  const requirements = [
    { text: "No spaces allowed", check: /^\S*$/ },
    { text: "Min 8 characters", check: /^.{8,}$/ },
    { text: "Max 20 characters", check: /^.{1,20}$/ },
    { text: "Use lower case letters", check: /[a-z]/ },
    { text: "Use upper case letters", check: /[A-Z]/ },
    { text: "Use a number", check: /\d/ },
    { text: "Use a symbol", check: /^(?=.*(_|[^\w])).+$/ },
    {
      text: "Strong password",
      check: dictionaryWords,
    },
  ];

  const isValid = (requirement) => {
    if (Array.isArray(requirement.check)) {
      if (password.length < 8) {
        return false;
      }

      if (checkConsecutiveLetters(password.toLowerCase())) {
        return false;
      }

      if (checkDictionary(password)) {
        return false;
      }
      return true;
    } else {
      if (requirement.check.test(password)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div className="password-requirements">
      {requirements.map((requirement, index) => (
        <span
          key={index}
          className={`default-regular-sub-heading-xxs ${
            isValid(requirement) ? "cs-success" : "cs-danger"
          }`}
        >
          <span className={isValid(requirement) ? "cs-success" : "cs-danger"}>
            <CapsyncIcon
              title={isValid(requirement) ? "check-outlined" : "close-outlined"}
              size="14"
            />
          </span>
          {requirement.text}
        </span>
      ))}
    </div>
  );
};

export default PasswordRequirement;
