/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { NavDropdown, Table } from "react-bootstrap";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { fileSizeCalc, getFileIcon } from "../../../util/common";
import { isMobile } from "react-device-detect";
import commonNames from "../../../config/commonNames";

const TableView = ({
  type,
  data,
  isSelected,
  selectedRows,
  sortingHeader,
  category = [],
  setSortingHeader,
  setSortingHeaderName,
  sortingHeaderName,
  storage,
  setFileStorageModal,
  viewOnlyPermission,
  CapsyncDeleteMessage,
  handleFileDownload,
  handleProtectedFileDownload,
  setRenameFolderDetails,
  setRenameFolder,
  handleArchive,
  setCreateFolderModal,
  setRenameFile,
  handleClick,
  handleMove,
  handleFileDelete,
  handleRowDoubleClick,
  setSelectedID,
  selectedId,
  fmListRef,
  parentId,
  folderDropHoverKey,
  isFolderDropHover = false,
}) => {
  const [clickTimeout, setClickTimeout] = useState(null);

  const checkSorting = () => {
    if (category.length < 2) {
      return false;
    } else {
      return (
        ((category[category.length - 1].category_id !== null &&
          category[category.length - 1].row_id !== null) ||
          category[1].title === commonNames.Other) &&
        parentId !== null
      );
    }
  };

  const handleClickEvents = (document, event, index) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      handleSingleClick(document, event, index);
      setClickTimeout(null);
    } else {
      setClickTimeout(
        setTimeout(() => {
          handleRowDoubleClick && handleRowDoubleClick(document);
          setClickTimeout(null);
        }, 300)
      );
    }
  };

  const handleSingleClick = (document, event, index) => {
    if (!event.detail || event.detail === 1) {
      handleClick(document, event, index);
    }
  };

  const ActionButton = ({
    document,
    type,
    isMobile,
    viewOnlyPermission,
    index,
    storage,
    handleFileDownload,
    handleProtectedFileDownload,
    setCreateFolderModal,
    setRenameFile,
    setRenameFolder,
    setRenameFolderDetails,
    handleMove,
    handleArchive,
    CapsyncDeleteMessage,
    handleFileDelete,
  }) => {
    const handleToggle = (documentId) => {
      if (selectedId === documentId) {
        setSelectedID && setSelectedID(null);
      } else {
        setSelectedID && setSelectedID(documentId);
      }
    };
    return (
      <>
        {!selectedRows.length && (
          <>
            {type === "document" && (
              <div
                className={`cs-table-icon ${isMobile ? "" : "fm-table-icon"}`}
              >
                {document.is_downloadable && (
                  <span
                    className={`${
                      isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                    }`}
                    title="Download"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (storage.usedSpace > storage.maxSpace) {
                        setFileStorageModal(true);
                      } else {
                        handleFileDownload([document]);
                      }
                    }}
                    onMouseEnter={(e) => {
                      setSelectedID && setSelectedID(null);
                    }}
                  >
                    <CapsyncIcon title="download-outlined" size="18" />
                  </span>
                )}
                {/* {((document.category_id !== null && document.row_id === null) ||
                  (document.category_id !== null &&
                    document.row_id !== null &&
                    document.attachment === null)) &&
                  document.is_downloadable && (
                    <span
                      className={`${
                        isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                      }`}
                      title="Protect Download"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleProtectedFileDownload([document]);
                      }}
                    >
                      <CapsyncIcon
                        title="protect-download-outlined"
                        size="18"
                      />
                    </span>
                  )} */}
                {!viewOnlyPermission && document.is_movable && (
                  <span
                    className={`${
                      isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                    }`}
                    title="Rename"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCreateFolderModal(true);
                      if (document.attachment) {
                        setRenameFile(true);
                      } else {
                        setRenameFolder(true);
                      }
                      setRenameFolderDetails(document);
                    }}
                    onMouseEnter={(e) => {
                      setSelectedID && setSelectedID(null);
                    }}
                  >
                    <CapsyncIcon title="edit-image-outlined" size="18" />
                  </span>
                )}
                {!viewOnlyPermission && document.is_movable && (
                  <span
                    className={`${
                      isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                    }`}
                    title="Move"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMove(document);
                    }}
                    onMouseEnter={(e) => {
                      setSelectedID && setSelectedID(null);
                    }}
                  >
                    <CapsyncIcon title="move-outlined" size="18" />
                  </span>
                )}
                {!viewOnlyPermission && document.is_delete_allowed && (
                  <span
                    className={`${
                      isMobile ? "cs-neutral-80-mobile cs-danger" : "cs-danger"
                    }`}
                    title="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      CapsyncDeleteMessage(
                        true,
                        "",
                        `Are you sure you want to delete this ${
                          document.attachment ? "File" : "Folder"
                        }? You can restore it later from the trash. Please confirm if you wish to proceed.`,
                        () => handleFileDelete(document)
                      );
                    }}
                    onMouseEnter={(e) => {
                      setSelectedID && setSelectedID(null);
                    }}
                  >
                    <CapsyncIcon title="delete-outlined" size="18" />
                  </span>
                )}
                {!viewOnlyPermission &&
                  (document.is_archive_allowed ||
                    document.is_delete_allowed) && (
                    <div
                      className={`${
                        isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                      }`}
                    >
                      <>
                        <NavDropdown
                          show={document.id === selectedId}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggle(document.id);
                          }}
                          title={
                            <span
                              className="cs-neutral-80 cursor-pointer"
                              title="More Action"
                            >
                              <CapsyncIcon
                                title="option-horizontal-filled"
                                size="18"
                              />
                            </span>
                          }
                          className="cs-settings-dropdown"
                        >
                          {document.is_archive_allowed && (
                            <NavDropdown.Item
                              title="Archive"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleArchive(document);
                              }}
                            >
                              <CapsyncIcon title="archive-outlined" size="22" />
                              <span className="default-light-body-text-m drop-icon">
                                Archive
                              </span>
                            </NavDropdown.Item>
                          )}
                          {/* {document.is_downloadable && (
                            <NavDropdown.Item
                              title="Protect Download"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleProtectedFileDownload([document]);
                                setSelectedID && setSelectedID(null);
                              }}>
                              <CapsyncIcon
                                title="protect-download-outlined"
                                size="22"
                              />
                              <span className="default-light-body-text-m drop-icon">
                                Encrypted Download
                              </span>
                            </NavDropdown.Item>
                          )} */}
                        </NavDropdown>
                      </>
                    </div>
                  )}
              </div>
            )}
            {!viewOnlyPermission &&
              (type === "trash" || type === "archive") && (
                <div
                  className={`cs-table-icon ${isMobile ? "" : "fm-table-icon"}`}
                >
                  <span
                    className={`${
                      isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                    }`}
                    title="Download"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (storage.usedSpace > storage.maxSpace) {
                        setFileStorageModal(true);
                      } else {
                        handleFileDownload([document]);
                      }
                    }}
                  >
                    <CapsyncIcon title="download-outlined" size="18" />
                  </span>
                  {/* <span
                    className={`${
                      isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                    }`}
                    title="Protect Download"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (storage.usedSpace > storage.maxSpace) {
                        setFileStorageModal(true);
                      } else {
                        handleProtectedFileDownload([document]);
                      }
                    }}
                  >
                    <CapsyncIcon title="protect-download-outlined" size="18" />
                  </span> */}
                  <span
                    className={`${
                      isMobile ? "cs-neutral-80-mobile" : "cs-neutral-80"
                    }`}
                    title="Move"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMove(document);
                    }}
                  >
                    <CapsyncIcon title="move-outlined" size="18" />
                  </span>
                  <span
                    className="cs-danger"
                    title="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      CapsyncDeleteMessage(
                        true,
                        "",
                        `${
                          type === "trash"
                            ? ` Are you sure you want to permanently delete this ${
                                document.attachment ? "File" : "Folder"
                              }? This action cannot be undone and the item cannot be restored. Please confirm if you wish to proceed.`
                            : `Are you sure you want to delete this ${
                                document.attachment ? "File" : "Folder"
                              }? You can restore it later from the trash. Please confirm if you wish to proceed.`
                        }`,
                        () => handleFileDelete(document)
                      );
                    }}
                  >
                    <CapsyncIcon title="delete-outlined" size="18" />
                  </span>
                </div>
              )}
          </>
        )}
      </>
    );
  };

  const TableRow = ({ document, isSelected, index }) => {
    const extension =
      document.name &&
      document.name.split(/\.(?=[^\.]+$)/)[1] &&
      document.name.split(/\.(?=[^\.]+$)/)[1].toLowerCase();
    const icon = getFileIcon(extension);
    return (
      <tr
        onClick={(event) => {
          event.stopPropagation();
          isMobile
            ? handleClickEvents(document, event, index)
            : (!event.detail || event.detail === 1) &&
              handleClick(document, event, index);
        }}
        onDoubleClick={() =>
          !isMobile && handleRowDoubleClick && handleRowDoubleClick(document)
        }
        className={`${isSelected(document.id) ? "selected" : ""} ${
          !document.attachment
            ? folderDropHoverKey === "grid-folder-" + document.id &&
              isFolderDropHover
              ? "cs-folder-drop-selected"
              : ""
            : ""
        }`}
        id={`grid-${
          !document.attachment ? "folder-" + document.id : document.id
        }`}
      >
        <React.Fragment>
          <td className="default-regular-sub-heading-m">
            <div className="fm-name">
              {document.attachment ? (
                <span>{icon}</span>
              ) : (
                <span className="cs-neutral-60">
                  <CapsyncIcon title="folder-icon-filled" size="22" />
                </span>
              )}
              <span title={document.name}>{document.name}</span>
            </div>
          </td>
          {isMobile && (
            <td className="default-light-body-text-l">
              <ActionButton
                document={document}
                type={type}
                isMobile={isMobile}
                viewOnlyPermission={viewOnlyPermission}
                storage={storage}
                handleFileDownload={handleFileDownload}
                handleProtectedFileDownload={handleProtectedFileDownload}
                setCreateFolderModal={setCreateFolderModal}
                setRenameFile={setRenameFile}
                setRenameFolder={setRenameFolder}
                setRenameFolderDetails={setRenameFolderDetails}
                handleMove={handleMove}
                handleArchive={handleArchive}
                CapsyncDeleteMessage={CapsyncDeleteMessage}
                handleFileDelete={handleFileDelete}
              />
            </td>
          )}

          {type == "document" && (
            <td className="default-light-body-text-l">
              {document.updated_by || "-"}
            </td>
          )}
          {type == "document" && (
            <td className="default-light-body-text-l">
              {document.updatedAt
                ? new Date(document.updatedAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "-"}
            </td>
          )}
          {type == "trash" && (
            <td className="default-light-body-text-l">
              {document.deleted_at
                ? new Date(document.deleted_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "-"}
            </td>
          )}
          {type == "archive" && (
            <td className="default-light-body-text-l">
              {document.archived_date
                ? new Date(document.archived_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "-"}
            </td>
          )}

          <td className="default-light-body-text-l">
            {fileSizeCalc(document.file_size)}
          </td>
          {!isMobile && (
            <td
              className="default-light-body-text-l"
              onMouseLeave={(e) => {
                setSelectedID && setSelectedID(null);
              }}
            >
              <ActionButton
                document={document}
                type={type}
                isMobile={isMobile}
                viewOnlyPermission={viewOnlyPermission}
                storage={storage}
                index={index}
                handleFileDownload={handleFileDownload}
                handleProtectedFileDownload={handleProtectedFileDownload}
                setCreateFolderModal={setCreateFolderModal}
                setRenameFile={setRenameFile}
                setRenameFolder={setRenameFolder}
                setRenameFolderDetails={setRenameFolderDetails}
                handleMove={handleMove}
                handleArchive={handleArchive}
                CapsyncDeleteMessage={CapsyncDeleteMessage}
                handleFileDelete={handleFileDelete}
              />
            </td>
          )}
        </React.Fragment>
      </tr>
    );
  };

  return data && data.length > 0 ? (
    <div className="cs-list-view">
      <div className="fm-list" ref={fmListRef}>
        <Table>
          <thead>
            <tr>
              <th
                width="700"
                className="default-regular-sub-heading-m cursor-pointer"
                onClick={() => {
                  if (type !== "document" ? true : checkSorting()) {
                    setSortingHeader({
                      name: !sortingHeader.name,
                      modifiedBy: false,
                      dateUpdated: false,
                      fileSize: false,
                    });
                    setSortingHeaderName("name");
                  }
                }}
              >
                Name
                {(type !== "document" ? true : checkSorting()) &&
                  sortingHeaderName === "name" &&
                  (sortingHeader.name ? (
                    <span className="cs-neutral-100">
                      <CapsyncIcon title="arrow-up-outlined" size="14" />
                    </span>
                  ) : (
                    <span className="cs-neutral-100">
                      <CapsyncIcon title="arrow-down-outlined" size="14" />
                    </span>
                  ))}
              </th>
              {isMobile && <th className=""></th>}
              {type == "document" && (
                <th
                  className="default-regular-sub-heading-m cursor-pointer"
                  onClick={() => {
                    if (checkSorting()) {
                      setSortingHeader({
                        name: false,
                        modifiedBy: !sortingHeader.modifiedBy,
                        dateUpdated: false,
                        fileSize: false,
                      });
                      setSortingHeaderName("modifiedBy");
                    }
                  }}
                >
                  Modified By
                  {checkSorting() &&
                    sortingHeaderName === "modifiedBy" &&
                    (sortingHeader.modifiedBy ? (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-up-outlined" size="14" />
                      </span>
                    ) : (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-down-outlined" size="14" />
                      </span>
                    ))}
                </th>
              )}
              {type == "document" && (
                <th
                  className="default-regular-sub-heading-m cursor-pointer"
                  onClick={() => {
                    if (checkSorting()) {
                      setSortingHeader({
                        name: false,
                        modifiedBy: false,
                        dateUpdated: !sortingHeader.dateUpdated,
                        fileSize: false,
                      });
                      setSortingHeaderName("dateUpdated");
                    }
                  }}
                >
                  Date Updated
                  {checkSorting() &&
                    sortingHeaderName === "dateUpdated" &&
                    (sortingHeader.dateUpdated ? (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-up-outlined" size="14" />
                      </span>
                    ) : (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-down-outlined" size="14" />
                      </span>
                    ))}
                </th>
              )}
              {type == "trash" && (
                <th
                  className="default-regular-sub-heading-m cursor-pointer"
                  onClick={() => {
                    setSortingHeader({
                      name: false,
                      modifiedBy: false,
                      dateDeleted: !sortingHeader.dateDeleted,
                      fileSize: false,
                    });
                    setSortingHeaderName("dateDeleted");
                  }}
                >
                  Trashed Date
                  {sortingHeaderName == "dateDeleted" &&
                    (sortingHeader.dateDeleted ? (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-up-outlined" size="14" />
                      </span>
                    ) : (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-down-outlined" size="14" />
                      </span>
                    ))}
                </th>
              )}
              {type == "archive" && (
                <th
                  className="default-regular-sub-heading-m cursor-pointer"
                  onClick={() => {
                    setSortingHeader({
                      name: false,
                      modifiedBy: false,
                      dateArchived: !sortingHeader.dateArchived,
                      fileSize: false,
                    });
                    setSortingHeaderName("dateArchived");
                  }}
                >
                  Archived Date
                  {sortingHeaderName == "dateArchived" &&
                    (sortingHeader.dateArchived ? (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-up-outlined" size="14" />
                      </span>
                    ) : (
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="arrow-down-outlined" size="14" />
                      </span>
                    ))}
                </th>
              )}
              <th className="default-regular-sub-heading-m">File Size </th>
              {!isMobile && <th className=""></th>}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((document, index) => {
                return (
                  <React.Fragment key={index}>
                    {(type == "document" ? !document.is_archive : document) && (
                      <TableRow
                        key={index}
                        document={document}
                        index={index}
                        isSelected={isSelected}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  ) : (
    <div className="manage-file-container no-records">
      <label className="cs-upload-files default-regular-h4">
        We don't have any active records to display
      </label>
    </div>
  );
};

export default TableView;
