/* eslint-disable */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Cashonhand from "../../assets/categoryForms/Cashonhand.jsx";
import SavingsAccount from "../../assets/categoryForms/SavingsAccount.jsx";
import OtherRetirement from "../../assets/categoryForms/OtherRetirement.jsx";
import NotesReceivable from "../../assets/categoryForms/NotesReceivable.jsx";
import LifeInsurance from "../../assets/categoryForms/LifeInsurance.jsx";
import NonRetirementBrokerage from "../../assets/categoryForms/NonRetirementBrokerage.jsx";
import RealEstate from "../../assets/categoryForms/RealEstate.jsx";
import Automobile from "../../assets/categoryForms/Automobile.jsx";
import Businesses from "../../assets/categoryForms/Businesses.jsx";
import PersonalProperty from "../../assets/categoryForms/PersonalProperty.jsx";
import OtherAssets from "../../assets/categoryForms/OtherAssets.jsx";

import AccountPayable from "../../liabilities/categoryForms/AccountPayable.jsx";
import UnpaidTaxes from "../../liabilities/categoryForms/UnpaidTaxes.jsx";
import OtherLiabilities from "../../liabilities/categoryForms/OtherLiabilities.jsx";
import NotesPayable from "../../liabilities/categoryForms/NotesPayable.jsx"

import { categoryGetAll, categoryTotalNew } from "../../../../slices/category.slice.js";
import MortgagesOnRealEstate from "../../liabilities/categoryForms/MortgagesOnRealEstate.jsx";
import InstallmentAmountAuto from "../../liabilities/categoryForms/InstallmentAmountAuto.jsx";
import InstallmentAmountOther from "../../liabilities/categoryForms/InstallmentAmountOther.jsx";

const CategoryForm = (props) => {
  const categoryData = useSelector((state) => state.category);
  const { formId, formType } = props;
  const { categoryListing } = categoryData;
  const type = formType === "Assets" ? "assets" : "liabilities";
  const selectedCategory = categoryListing[type].filter(
    (category, index) => category.id === formId
  )[0];
  return (
    <FormComponents
      {...props}
      checkType={selectedCategory.category}
      dashboardAPi={props.dashboardAPi}
    />
  );
};

const FormComponents = (props) => {
  const { checkType } = props;
  let component = null;
  switch (checkType) {
    case 'cashonhand':
      component = <Cashonhand {...props} />
      break
    case 'savingsAccount':
      component = <SavingsAccount {...props} />
      break
    case 'otherRetirement':
      component = <OtherRetirement {...props} />
      break
    case 'notesReceivable':
      component = <NotesReceivable {...props} />
      break

    case 'lifeInsurance':
      component = <LifeInsurance  {...props} category="assets" />
      break
    case 'life_insurance':
      component = <LifeInsurance {...props} category="liabilities" />
      break
    case 'nonRetirementBrokerage':
      component = <NonRetirementBrokerage {...props} />
      break
    case 'realEstate':
      component = <RealEstate {...props} />
      break
    case 'automobile':
      component = <Automobile {...props} />
      break
    case 'businesses':
      component = <Businesses {...props} />
      break
    case "personalProperty":
      component = <PersonalProperty {...props} />;
      break
    case 'otherAssets':
      component = <OtherAssets {...props} />;
      break

    //  liabilities start
    case 'accountPayable':
      component = <AccountPayable {...props} />
      break
    case 'notesPayable':
      component = <NotesPayable {...props} />
      break
    case 'installmentAuto':
      component = <InstallmentAmountAuto {...props} />
      break
    case 'installmentAcc':
      component = <InstallmentAmountOther {...props} />
      break
    case 'unpaidTaxes':
      component = <UnpaidTaxes {...props} />
      break
    case 'otherLiabilities':
      component = <OtherLiabilities {...props} />
      break
    case 'mortgageOnRealestate':
      component = <MortgagesOnRealEstate {...props} />
      break
    default:
  }
  return component;
};

const InitialSetup3 = (props) => {
  const { fetchTypeCategory, changeCounter, previous, record, setShowEditModal, showModal, onHnadleClose, setCategoryAccountAdded,} = props; //need to remove "changeCategoryData, toastMessage" after new forms implementation
  const { user } = useSelector((state) => state.auth);
  const { category } = useParams();
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const dispatch = useDispatch();

  const dashboardAPi = () => {
    dispatch(categoryTotalNew({ id: id }))
      .unwrap()
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCategoryFormClose = () => {
    if (onHnadleClose) {
      return onHnadleClose(false)
    }
    if (record) {
      setShowEditModal(false)
    } else {
      previous()
    }
  };

  const handleCategoryFormSubmit = (submitCategory) => {
    getTableList(submitCategory);
    if (onHnadleClose) {
      return onHnadleClose(true);
    }
    if (record) {
      setShowEditModal(false);
    } else {
      changeCounter(4);
      setCategoryAccountAdded && setCategoryAccountAdded(submitCategory);
    }
  };

  const getTableList = (submitCategory) => {
    const main_category =
      fetchTypeCategory.category === "Assets" ? "assets" : "liabilities";

    if (window.location.pathname.includes(main_category)) {
      dispatch(
        categoryGetAll({
          id: user.user.id,
          category: submitCategory,
          main_category,
        })
      )
        .unwrap()
        .then((response) => {})
        .catch((err) => {
          toast.error(err.message);
        });
    }

    if (
      window.location.pathname.includes("liabilities") &&
      main_category === "assets" &&
      submitCategory === "lifeinsurance"
    ) {
      dispatch(
        categoryGetAll({
          id: user.user.id,
          category: "lifeinsurance",
          main_category: "liabilities",
        })
      )
        .unwrap()
        .then((response) => {})
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  return (
    <CategoryForm
      formId={fetchTypeCategory.type}
      formType={fetchTypeCategory.category}
      onFormClose={handleCategoryFormClose}
      onFormSubmit={handleCategoryFormSubmit}
      record={record}
      showModal={showModal}
      linkAddressObj={props.linkAddressObj || null}
      dashboardAPi={dashboardAPi}
    />
  );
};

export default InitialSetup3;
