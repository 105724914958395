/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Dropdown, Form } from "react-bootstrap";
import Slider from "react-slick";

import FinanceSummary from "../FinanceSummary";
import { backAppURl } from "../../applicationMode";
import CapsyncBreadcrumb from "../CapsyncBreadcrumb";
import FinanceAllocation from "../FinanceAllocation";
import SecuritiesHoldings from "./SecuritiesHoldings";
import { DecryptReact } from "../../customHooks/EncryptDecryptReact";
import {
  assetsCategory,
  headingList,
  portFolioType,
} from "../../config/finance";
import CapsyncIcon from "../CapsyncIcon";
import {
  columnFindRecordPortfolio,
  selectColumn,
} from "../../slices/columnFilter";
import SkeletonStocksPortfolio from "./SkeletonStocksPortfolio";
import { transaction_breadcrumb } from "../../slices/transactions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function fetchInstitutionId() {
  const decryptedData = DecryptReact(
    window.localStorage.getItem("trans_ins_id")
  );
  return decryptedData;
}

const Portfolio = ({ is_loader, editAccessUser }) => {
  const dispatch = useDispatch();
  const { user, lastSyncDate } = useSelector((state) => state.auth);
  let id;
  let decryptRowId = +DecryptReact(
    window.localStorage.getItem("portfolio_row_id")
  );
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const { mask_id, category } = useParams();

  const [isLoader, setIsLoader] = useState(true);
  const [securitiesHoldings, setSecuritiesHoldings] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [lineData, setLineData] = useState([]);

  const [headingState, setHeadingState] = useState(
    headingList.map((x) => x.name)
  );
  const [storedColumns, setStoredColumns] = useState([]);
  const [categoryColumnsFilters, setCategoryColumnsFilters] = useState([
    ...headingList,
  ]);

  const [breadcrumb, setBreadcrumb] = useState({
    category: "",
    sub_category: "",
    main_category: "",
    institute: "Portfolio",
    category_url: "",
  });
  ////////////////////////////////////////////////////////////
  const [sequence, setSequence] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let selectedColumnsArray = sequence
      .filter((item) => item.isChecked)
      .map((item) => item.name);
    setSelectedColumns([...selectedColumnsArray]);
    const sortedCategoryColumnFilters = sortDataBySequence(
      categoryColumnsFilters,
      sequence
    );
    setCategoryColumnsFilters(sortedCategoryColumnFilters);
  }, [sequence]);

  function sortDataBySequence(data, sequence) {
    const sequenceMap = {};
    sequence.forEach((item, index) => {
      sequenceMap[item.name] = index;
    });

    data.sort((a, b) => {
      const indexA = sequenceMap[a.name];
      const indexB = sequenceMap[b.name];
      return indexA - indexB;
    });

    return data;
  }
  ////////////////////////////////////////////////////////////
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchColumns();
    holdingSecurities();
    fetchTransactionBreadCrumb();
  }, []);

  function fetchInsId() {
    const decryptedData = DecryptReact(
      window.localStorage.getItem("trans_ins_id")
    );
    return decryptedData;
  }

  const fetchTransactionBreadCrumb = () => {
    dispatch(
      transaction_breadcrumb({
        mask_id: mask_id,
        user_id: id,
        institution_id: fetchInsId(),
      })
    )
      .unwrap()
      .then((response) => {
        setBreadcrumb({
          category: response.category,
          sub_category: response.sub_category,
          institute: response.institute,
          main_category: response.main_category,
          category_url: response.category_url,
        });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  //////////////////////////////////////////////////////////////////////////
  function checkFieldNameExist(category, sequence) {
    const categoryFieldName = category.map((item) => item.name);
    const sequenceFieldName = sequence.map((item) => item.name);
    return categoryFieldName.every((title) =>
      sequenceFieldName.includes(title)
    );
  }
  //////////////////////////////////////////////////////////////////////////

  const fetchColumns = () => {
    dispatch(
      columnFindRecordPortfolio({
        user_id: id,
        category_name: category,
        row_id: decryptRowId,
      })
    )
      .unwrap()
      .then((response) => {
        if (
          response &&
          response.columns_name &&
          checkFieldNameExist(categoryColumnsFilters, response.columns_name)
        ) {
          setSequence(response.columns_name);
        } else {
          changeManageColumnsFilter({
            firstTimeUserSequence: [...headingList],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeManageColumnsFilter = ({
    items,
    columnName,
    firstTimeUserSequence,
  }) => {
    const sequenceMap = {};
    sequence.forEach((item, index) => {
      sequenceMap[item.name] = item.isChecked;
    });
    let newSequence = [];
    if (items) {
      items.map((categoryColumn, index) => {
        newSequence.push({
          name: categoryColumn.name,
          isChecked: sequenceMap[categoryColumn.name],
        });
      });
    } else if (firstTimeUserSequence) {
      firstTimeUserSequence.map((categoryColumn, index) => {
        newSequence.push({ name: categoryColumn.name, isChecked: true });
      });
    } else {
      newSequence = [...sequence];
    }
    if (columnName) {
      newSequence.map((categoryColumn, index) => {
        if (categoryColumn.name === columnName) {
          newSequence[index].isChecked = !newSequence[index].isChecked;
        }
      });
    }

    setSequence(newSequence);
    dispatch(
      selectColumn({
        user_id: id,
        category_name: category,
        columns_name: newSequence, //modifyColumns.length === 0 ? '' : modifyColumns.join(','),
        category_type: "assets",
        row_id: decryptRowId,
        is_portfolio: true,
      })
    )
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  let fetchCatData = assetsCategory.find((x) => x.url === category);

  const mainLevelArr = [
    {
      title: "Assets",
      url: "/individual/assets",
    },
    {
      title: fetchCatData.title,
      url: `/individual/assets/${fetchCatData.url}`,
    },
  ];

  const holdingSecurities = () => {
    axios
      .post(`${backAppURl}/api/plaid/holdings/securities`, {
        user_id: id,
        mask_id: mask_id,
        institution_id: fetchInstitutionId(),
      })
      .then((response) => {
        if (response.data) {
          setSecuritiesHoldings(response.data.holdings_securities);
          setPieData(
            response.data.pieChartData.map((data) => {
              return {
                ...data,
                fill:
                  portFolioType[data.name] !== undefined
                    ? portFolioType[data.name]
                    : "#CA4949",
              };
            })
          );
          setLineData(response.data.lineChartData);
        }
        setIsLoader(false);
      })
      .catch((err) => {
        console.log("err:- ", err);
        setIsLoader(false);
      });
  };

  const chart_carousel = {
    dots: true,
    infinite: false,
    speed: 500,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (is_loader.assets === true || is_loader.liabilities === true || isLoader) {
    return (
      <div className="skeleton-portfolio">
        <SkeletonStocksPortfolio />
      </div>
    );
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (
      [0, categoryColumnsFilters.length - 1].includes(result.destination.index)
    ) {
      return;
    }

    const items = Array.from(categoryColumnsFilters);
    if (
      result.destination.index === items.length ||
      !items[result.destination.index].draggable
    )
      return;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    changeManageColumnsFilter({ items: items });
    setCategoryColumnsFilters(items);
    return;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <section className="assets-main-section">
      <Container fluid>
        <div className="cs-section-topbar">
          <CapsyncBreadcrumb
            mainTitle="Assets"
            subTitle={breadcrumb.institute}
            multiLevelFlag="true"
            mainLevelArr={mainLevelArr}
          />
          <div className="cs-title">
            <h1 className="default-semi-bold-h1 cs-neutral-100">Portfolio</h1>
            {lastSyncDate && (
              <span className="default-regular-body-text-m cs-neutral-80">
                Last sync: {lastSyncDate}
              </span>
            )}
          </div>
        </div>

        <div className="for-des">
          <div className="charts-container">
            <Row>
              <Col lg={4}>
                <div className="new-pie-chart">
                  <FinanceAllocation
                    pieData={pieData}
                    title="Total Portfolio"
                    heading="Portfolio Allocation"
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="line-chart-container">
                  <FinanceSummary lineData={lineData} title="Assets" />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="for-mob cs-common-carousel">
          <Slider {...chart_carousel}>
            <Col lg={6}>
              <div className="new-pie-chart">
                <FinanceAllocation
                  pieData={pieData}
                  title="Total Portfolio"
                  heading="Portfolio Allocation"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="line-chart-container">
                <FinanceSummary lineData={lineData} title="Assets" />
              </div>
            </Col>
          </Slider>
        </div>

        {editAccessUser && (
          <Row>
            <Col>
              <div className="category-manage-row">
                <div className="cs-common-add-dropdown">
                  <div className="custom-dropdown" ref={dropdownRef}>
                    <button
                      className="default-regular-h5 xl-btn cs-btn-icon-primary"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <span className="cs-neutral-10">
                        <CapsyncIcon title="filter-filled" size="18" />
                      </span>
                      Manage Columns
                    </button>
                    {isOpen && (
                      <div className="custom-dropdown-menu">
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          <Droppable droppableId="characters">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="manage-columns-items"
                              >
                                {categoryColumnsFilters.map(
                                  (filterColumn, index) => (
                                    <Draggable
                                      key={filterColumn.name}
                                      draggableId={filterColumn.name}
                                      index={index}
                                      isDragDisabled={!filterColumn.draggable}
                                    >
                                      {(provided) => (
                                        <li
                                          {...provided.draggableProps}
                                          ref={provided.innerRef}
                                          key={index}
                                          className="manage-column-item"
                                        >
                                          <div className="cs-form-check-box">
                                            <span
                                              className={`${
                                                !filterColumn.draggable
                                                  ? "cs-disabled"
                                                  : "cs-neutral-100"
                                              }`}
                                              {...provided.dragHandleProps}
                                            >
                                              <CapsyncIcon
                                                title="order-selector-filled"
                                                size="18"
                                              />
                                            </span>
                                            <Form.Check
                                              inline
                                              className={`default-light-body-text-m cs-neutral-100 ${
                                                !filterColumn.filter &&
                                                "cs-disabled"
                                              }`}
                                              label={filterColumn.name}
                                              type="checkbox"
                                              id={`inline-checkbox-${index}`}
                                              disabled={!filterColumn.filter}
                                              onChange={() =>
                                                changeManageColumnsFilter({
                                                  columnName: filterColumn.name,
                                                })
                                              }
                                              checked={
                                                selectedColumns.includes(
                                                  filterColumn.name
                                                ) ||
                                                filterColumn.name === "action"
                                              }
                                            />
                                          </div>
                                        </li>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <div className={`${!isLoader ? "portfolio-cs-table-container" : ""}`}>
          <SecuritiesHoldings
            securitiesHoldings={securitiesHoldings}
            storedColumns={storedColumns}
            headingState={sequence
              .filter((x) => x.isChecked)
              .map((x) => x.name)}
          />
        </div>
      </Container>
    </section>
  );
};

export default Portfolio;
