import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { clearMessage } from "../../../slices/message";
import { mfaPhoneAuth } from "../../../slices/auth";
import { MfaPhoneContext } from "./Account_details/AccountDetails";
import { mfaPhoneUpdateRecordwithOtp } from "../../../slices/user";
import UseFetchDetails from "../../../customHooks/UseFetchDetails";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const IndiPhonePinVerification = (props) => {
  const fetchData = useContext(MfaPhoneContext);
  const [loading, setLoading] = useState(false);
  const [resendMsg, setResendMsg] = useState();

  const [otp, setOtp] = useState();
  const [msg, setMsg] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  let id;
  let email;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
    const { fetchUserData } = UseFetchDetails();
    email = fetchUserData.email;
  } else {
    id = user && user.user.id;
    email = user && user.user.email;
  }

  const handleChange = (otp) => {
    setMsg("");
    setResendMsg(false);
    setOtp(otp);
  };

  useEffect(() => {
    dispatch(clearMessage());
    setResendMsg(false);
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setResendMsg(false);
    setLoading(true);
    const MFAPhoneOtp = otp;
    const phoneValue = props.phoneValue;
    if (MFAPhoneOtp) {
      dispatch(mfaPhoneUpdateRecordwithOtp({ id, MFAPhoneOtp, phoneValue }))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res.code === 200) {
            fetchData.closeMfaModal();
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          } else {
            setMsg("please enter valid otp");
          }
        })
        .catch((err) => {
          setLoading(false);
          setMsg(uniValidation.otp.require);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };

  const resendMFAPhoneOTP = () => {
    setOtp("");
    dispatch(clearMessage());
    dispatch(mfaPhoneAuth({ user_id:id, email }))
      .then((res) => {
        setResendMsg(res.payload.message);
      })
      .catch((err) => {});
  };

  function showStarNumber(params) {
    let storeNewContent =
      params.substring(0, 3) + "*******" + params.substring(10, params.length);
    return storeNewContent;
  }

  return (
    <>
      <Row className="mfVerificationPin">
        <Col lg={12}>
          <div className="auth-right mfa-setup-auth-pin">
            <div className="formArea">
              <h2>Mobile verification</h2>
              <hr className="underline" />
              <div>
                <span className="QRauth-verify">
                  We have sent a code to{" "}
                  <span className="phoneShow">
                    {showStarNumber(props.phoneValue)}
                  </span>
                </span>
              </div>
              <div className="auth-form">
                <Form onSubmit={handleSubmit}>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="otp-txt"
                    containerStyle="otp-layout"
                    // isInputNum={true}
                    inputType="number"
                    shouldAutoFocus={true}
                  />

                  {msg ? (
                    <p className="formik-form-error text-center">{msg}</p>
                  ) : null}
                  <div>
                    <span className="default-regular-h5 mfaWithEmail-resed-Info">
                      Did not receive the code?{" "}
                      <Link onClick={resendMFAPhoneOTP} className="cs-priamry">Resend</Link>
                    </span>
                  </div>

                  {resendMsg && (
                    <div className="cs-msg default-regular-body-text-s cs-success">
                      <span className="icon cs-success">
                        <CapsyncIcon title="verification-outlined" size="18" />
                      </span>
                      <span className="formik-form-error"> {resendMsg}</span>
                    </div>
                  )}

                  {message && message.message && (
                    <div className="cs-msg default-regular-body-text-s cs-danger">
                      <span className="icon">
                        <CapsyncIcon title="info-filled" />
                      </span>
                      <span className="formik-form-error"> {message.message}</span>
                    </div>
                  )}

                  <Button
                    type="submit"
                    className="cs-pri-sm-btn mb-3"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    <span>Verify account</span>
                    {loading && (
                      <>
                        &nbsp;
                        <span className="spinner-border spinner-border-sm"></span>
                      </>
                    )}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default IndiPhonePinVerification;
