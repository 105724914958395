/* eslint-disable */
import React, { useEffect, useState, createContext } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  Modal,
  Dropdown,
  Container,
  FormControl,
  Card,
  FormLabel,
  ModalFooter,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  formatPhoneNumber,
  localStorageClear,
  setAddressString,
} from "../../../../util/common";
import {
  checkPhoneNumber,
  getWmDataByLicenseNumber,
  mfaEmailAuth,
  mfaPhoneAuth,
  setRemoveAuthUser,
  userUpdateRecord,
} from "../../../../slices/auth";
import ProfileImageComponent from "../../../profileImage/ProfileCard";
import { wealth_managers_findone } from "../../../../slices/auth";
import { forgotpassword } from "../../../../slices/auth";
import CommonSettingHeader from "../CommonHeader/CommonSettingHeader";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import "../../../individual/settings/Account_details/account_details.css";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import SuccessValidation from "../../../../../assets/images/other/success-validation.svg";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import ConfirmEditProfie from "./ConfirmEditProfie";
import EditProfile from "./EditProfie";
import AuthMFASetUpModal from "../../../../commonComponent/modalPopup/AuthMFASetUpModal";
import AuthMFAOptionModal from "../../../../commonComponent/modalPopup/AuthMFAOptionModal";
import { isValidPhoneNumber } from "react-phone-number-input";
import Skeleton from "react-loading-skeleton";
import SkeletonAccount from "../../../Common/Loaders/SkeletonAccount";
import { usAreaCodes } from "../../../../config/countryAreaCodes";
import { formatFullName } from "../../../../config/finance";
import { uniValidation } from "../../../individual/liabilities/categoryForms/validationSchema";
import { socket } from "../../../../config/Socket";

export const MfaPhoneContext = createContext(null);

const AccountDetailsWel = (props) => {
  const CapsyncMessage = props.CapsyncMessage;
  const { message } = useSelector((state) => state.message);
  const { user } = useSelector((state) => state.auth);
  const role = user.user_role.role;
  const user_id = user && user.user.id;
  const email = user && user.user.email;
  const phone = user && user.user.phoneNo;
  const existing_licenceId = user && user.user.licenceId;
  const existingCompanyLicenceId = user && user.user.companyLicenceId;
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [rejMsg, setRejMsg] = useState("");
  const [saveDisable, setSaveDisable] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [mfaType, setMfatype] = useState(0);
  const [msg, setMsg] = useState("");
  const [addressMsg, setAddressMsg] = useState("");
  const [confirmPhoneValidation, setConfirmPhoneValidation] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [mfaModal, setMfaModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState("");
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showConfirmEmailUpdate, setShowConfirmEmailUpdate] = useState(false);
  const [mfaOptionModal, setMfaOptionModal] = useState(false);
  const licenseId = user.user.licenceId;
  const [isAddressChange, setIsAddressChange] = useState(false);
  const [autocompletePredictions, setAutocompletePredictions] = useState([]);
  const [address, setAddress] = useState(
    user.user.address ? setAddressString(user.user.address) : ""
  );
  const [addressObj, setAddressObj] = useState(
    user.user.address || {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
    }
  );

  const [isManualAddress, setIsManualAddress] = useState(false); //Is Manual address

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const dispatch = useDispatch();

  const fetchRecord = () => {
    if (user_id) {
      dispatch(wealth_managers_findone({ wm_id: user_id }))
        .unwrap()
        .then((response) => {
          const { city, state, country, postal_code, line1 } = response.address;
          setRecord(response);
          setSelectedAddress(response && response.address);
          setAddress(
            response &&
              response.address &&
              setAddressString(response.address).trim()
          );
        })
        .catch((err) => {
          CapsyncMessage("Error", "fetchRecord :" + err.message, "error");
        });
    }
  };
  useEffect(() => {
    socket.on("get_all_details_emit", () => fetchRecord());
    return () => {
      socket.off("get_all_details_emit", () => fetchRecord());
    };
  }, []);

  useEffect(() => {
    fetchRecord();
  }, [user_id]);

  const closeModal = () => {
    setMfaModal(false);
  };
  const resetPassLink = () => {
    setModalShow(true);
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .strict()
      .max(40, uniValidation.first_name.max)
      .required(uniValidation.first_name.require)
      .test("is-not-only-spaces", uniValidation.first_name.require, (value) => {
        return value.trim() !== "";
      }),
    lastName: yup
      .string()
      .strict()
      .max(40, uniValidation.last_name.max)
      .required(uniValidation.last_name.require)
      .test("is-not-only-spaces", uniValidation.last_name.require, (value) => {
        return value.trim() !== "";
      }),
    ...(role == 3
      ? {}
      : {
          companyName: yup.string().required("Please enter company name"),
          companyLicenceId: yup.number().required("Enter company CRD number"),
        }),
    phoneNo: yup.string(),
    businessName: yup
      .string()
      .required("Enter Company name")
      .test("is-not-only-spaces", "Enter Company name", (value) => {
        return value.trim() !== "";
      }),
    street_name: yup
      .string()
      .required(uniValidation.street_name.require)
      .test(
        "is-not-only-spaces",
        uniValidation.street_name.require,
        (value) => {
          return value.trim() !== "";
        }
      )
      .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
      .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid),
    city: yup
      .string()
      .required(uniValidation.city.require)
      .test("is-not-only-spaces", uniValidation.city.require, (value) => {
        return value.trim() !== "";
      })
      .min(2, uniValidation.city.valid),
    state: yup
      .string()
      .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
      .min(2, "Required at least 2 characters"),
    zip_code: yup
      .string()
      .required(uniValidation.zip_code.require)
      .test("is-not-only-spaces", uniValidation.zip_code.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
      .min(5, uniValidation.zip_code.valid)
      .max(5, uniValidation.zip_code.valid),
  });

  const phoneAlreadyExists = async (phoneNo) => {
    try {
      const response = await dispatch(
        checkPhoneNumber({ phone_number: phoneNo })
      ).unwrap();
      return response.code === 200;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const {
    values,
    errors,
    touched,
    status,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setErrors,
    resetForm,
    handleReset,
    setStatus,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: record,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (
        !!isAddressChange &&
        !!status &&
        (!!status.street_name ||
          !!status.city ||
          !!status.state ||
          !!status.zip_code)
      ) {
        return;
      }
      if (email !== values.email) {
        setShowConfirmEmailUpdate(true);
        setShowEditProfile(false);
      } else if (phone !== values.phoneNo && msg === "") {
        try {
          const phoneExists = await phoneAlreadyExists(values.phoneNo);
          if (phoneExists) {
            setShowConfirmEmailUpdate(true);
            setShowEditProfile(false);
          } else {
            setFieldError("phoneNo", "Phone Number Already In Use");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        setLoading(true);
        setSaveDisable(true);
        saveData();
      }
    },
  });

  function detailsAreNotChanged(user_details) {
    const { address } = user && user.user;
    const isCompanyLicenceNumberChanged =
      existingCompanyLicenceId !== values.companyLicenceId;
    const isPhoneNumberChanged = phone !== values.phoneNo;
    // const isAddressChanged = setAddressString(existingAddress) !== setAddressString(isAddressChange ? addressObj : user.user.address)
    const isAddressChanged =
      isAddressChange &&
      (user_details.address.city !== address.city ||
        user_details.address.state !== address.state ||
        user_details.address.line1 !== address.line1 ||
        user_details.address.postal_code !== address.postal_code);
    const isEmailUpdated = user_details.is_email_updated;
    if (role == 2) {
      return !(
        isCompanyLicenceNumberChanged ||
        isPhoneNumberChanged ||
        isAddressChanged ||
        isEmailUpdated
      );
    } else {
      const isFnameChange = user.user.firstName !== values.firstName;
      const isLnameChange = user.user.lastName !== values.lastName;
      const isCompanyNameChange =
        user.user.businessName !== values.businessName;
      return !(
        isCompanyLicenceNumberChanged ||
        isPhoneNumberChanged ||
        isAddressChanged ||
        isEmailUpdated ||
        isLnameChange ||
        isFnameChange ||
        isCompanyNameChange
      );
    }
  }
  useEffect(() => {
    if (isAddressChange === true) {
      setSaveDisable(false);
    }
  }, [isAddressChange, values]);

  function saveData() {
    let user_details = {};
    user_details.user_id = values.id;
    user_details.firstName = values.firstName;
    user_details.lastName = values.lastName;
    user_details.displayName = values.displayName;
    user_details.address = isAddressChange ? addressObj : user.user.address;
    // user_details.email = values.email;
    user_details.isProfessional = true;
    user_details.businessName = values.businessName.trim();
    user_details.companyLicenceId = values.companyLicenceId;
    user_details.licenceId = values.licenceId;
    user_details.phoneNo = values.phoneNo;
    user_details.is_manual_address = isManualAddress;

    if (email !== values.email) {
      user_details.is_email_updated = true;
    }

    if (detailsAreNotChanged(user_details)) {
      setShowEditProfile(false);
      return;
    }

    if (msg === "") {
      dispatch(userUpdateRecord(user_details))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            if (
              user_details.is_email_updated &&
              user_details.is_email_updated === true
            ) {
              try {
                const storedUser = localStorage.getItem("user");
                const parsedUser = JSON.parse(storedUser);
                const newToken = response.data;
                const newUser = { ...parsedUser };
                newUser.user.email = values.email;
                newUser.user.phoneNo = values.phoneNo;
                newUser.user.firstName = values.firstName;
                newUser.user.lastName = values.lastName;
                newUser.user.displayName = values.displayName;

                localStorage.removeItem("user");
                localStorage.setItem("user", JSON.stringify(newUser));
              } catch (error) {
                // props.toastMessage("failed to update user email", 'error')
                toast.error(error.message);
              }
            }
            setShowEditProfile(false);
            setLoading(false);
            setSaveDisable(false);
            fetchRecord();
            toast.success(response.message);
          } else {
            toast.error(response.message);
            setSaveDisable(false);
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err:- ", err);
          props.toastMessage(err.message, "error");
        });
    }
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      dispatch(getWmDataByLicenseNumber({ licenseId: licenseId }, { signal }))
        .unwrap()
        .then((res) => {
          setAddresses(res.address);
        });
    } catch (err) {
      console.log(err);
    }
    return () => {
      controller.abort();
    };
  }, []);

  const handleLicenceChange = ({ target }) => {
    if (target.name === "licenceId") {
      setSaveDisable(true);
    } else {
      setSaveDisable(false);
    }
    setFieldValue(target.name, target.value);
    if (target.name === "street_name") {
      let addressObjNew = addressObj;
      //Updating Street name as line1 for address object
      addressObjNew = { ...addressObjNew, line1: target.value, line2: "" };
      setIsAddressChange(true);
      // setAddressObj(addressObjNew);//Set address object for update details
    }
    if (target.name === "zip_code") {
      setIsAddressChange(true);
    }
    setUpdateDetails("");
  };

  const backToSignIn = async () => {
    try {
      await dispatch(forgotpassword({ email })).unwrap();
      setModalShow(false);
      localStorageClear();
      dispatch(setRemoveAuthUser());
      window.location.href = "/";
    } catch (error) {
      console.log("error", error);
    }
  };

  const getLicenceData = () => {
    if (values.licenceId !== existing_licenceId) {
      dispatch(getWmDataByLicenseNumber(values))
        .unwrap()
        .then((response) => {
          if (response.licenseissue) {
            return false;
          } else {
            setSaveDisable(false);
            return true;
          }
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    } else {
      return false;
    }
  };

  const handler = () => {
    getLicenceData();
  };

  const handleConfirmEmail = () => {
    setShowEditProfile(false);
    // setShowConfirmEmailUpdate(true);
  };

  const handleContinueEmailUpdate = () => {
    setMfaModal(true);
    setShowConfirmEmailUpdate(false);
  };

  const handleEditProfile = (s) => {
    setMfatype("");
    handleReset();
    setShowEditProfile(s);
    fetchRecord();
    setSaveDisable(true);
    setUpdateDetails("");
  };

  const handleMFAOptionChange = (type) => {
    setMfatype(type);
  };

  const submitMfaOption = () => {
    if (mfaType === "1") {
      dispatch(mfaEmailAuth({ email }))
        .then((data) => {})
        .catch((err) => {});
    }
    if (mfaType === "2") {
      dispatch(mfaPhoneAuth({ user_id, email }))
        .then((res) => {})
        .catch((err) => {});
    }
    setMfaModal(false);
    setMfaOptionModal(true);
  };

  const phoneHandleChange = (val, fieldName) => {
    if (val) {
      if (
        isValidPhoneNumber(val) === true &&
        usAreaCodes.includes(Number(val.split("+1")[1].substr(0, 3))) &&
        val.split("+1")[1].length == 10
      ) {
        setFieldValue(fieldName, val);
        if (fieldName === "confirmPhoneNo") {
          setConfirmPhoneValidation("");
        } else {
          setMsg("");
        }
        setSaveDisable(false);
      } else {
        setFieldValue(fieldName, val);
        if (fieldName === "confirmPhoneNo") {
          setConfirmPhoneValidation(uniValidation.phone_number.valid);
        } else {
          setMsg(uniValidation.phone_number.valid);
        }
      }
    } else {
      setFieldValue(fieldName, "");
      if (fieldName === "confirmPhoneNo") {
        setConfirmPhoneValidation(uniValidation.phone_number.valid);
      } else {
        setMsg(uniValidation.phone_number.requrie);
      }
    }
    setUpdateDetails("");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const parsedUser = JSON.parse(storedUser);

    // localStorage.setItem('user', "this is manual name")
    // console.log(localStorage.getItem("user"))
  }, []);

  return (
    <>
      <section className="settings-main-section">
        <Container fluid>
          {/* <div className="cs-section-topbar">
            <CapsyncSectionHeader
              mainTitle="Dashboard"
              mainUrl="/financial-advisor"
              pagetitle={"Settings"}
              subTitle="Settings"
            />
          </div> */}
          {props.isLoader === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl={role === 2 ? "/financial-advisor" : "/professional"}
                pagetitle={"Settings"}
                subTitle="Settings"
              />
            </div>
          )}

          {props.isLoader === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown />
          )}
          <Card>
            <div className="settings-body">
              <>
                {/* <CommonSettingHeader /> */}
                {props.isLoader === true ? (
                  <div className="for-des">
                    {" "}
                    <div className="skeleton-account-tabs">
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={80} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={150} />
                      </p>
                    </div>{" "}
                  </div>
                ) : (
                  <CommonSettingHeader />
                )}

                {props.isLoader ? (
                  <SkeletonAccount />
                ) : (
                  <>
                    <div className="account-details-page">
                      <Row>
                        <Col lg={9}>
                          <div className="account-detail-box">
                            <div className="profile-personal">
                              <div className="profile">
                                <ProfileImageComponent
                                  role={user && user.user_role.role}
                                  type="Individual"
                                  id={user && user.user.id}
                                  profileImageUrl={record.profileImageUrl}
                                  customImgSrc={props.customImgSrc}
                                  profileState={props.profileState}
                                  changeProfileState={props.changeProfileState}
                                  changeSrcStatus={props.changeSrcStatus}
                                  toastMessage={props.toastMessage}
                                  customLoginImgSrc={props.customLoginImgSrc}
                                  setCustomLoginImgSrc={
                                    props.setCustomLoginImgSrc
                                  }
                                  user={user}
                                  record={record}
                                  fetchRecord={fetchRecord}
                                />
                                <div className="display-names">
                                  <h2 className="default-semi-bold-h2 cs-neutral-100">
                                    {formatFullName(
                                      record.firstName,
                                      record.lastName
                                    )}
                                  </h2>
                                  <p className="default-regular-sub-heading-m cs-neutral-80">
                                    {record.email}
                                  </p>
                                </div>
                              </div>
                              <div className="personal-information">
                                <h3 className="default-regular-h3 cs-neutral-100">
                                  Personal information
                                </h3>
                                <Button
                                  variant="primary"
                                  className="cs-btn-primary sm-btn default-regular-h5"
                                  onClick={() => handleEditProfile(true)}
                                >
                                  Edit
                                </Button>
                              </div>
                            </div>
                            <Form className="personal-inform-form">
                              <Row>
                                <Col className="col" lg={6} md={6} sm={6}>
                                  <Form.Group className="cs-form-group cs-disabled">
                                    <FormLabel>First name</FormLabel>
                                    <Form.Control
                                      type="text"
                                      autoComplete="off"
                                      name="firstName"
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === " " &&
                                          e.target.selectionStart === 0
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder="First Name"
                                      defaultValue={record.firstName}
                                      value={record.firstName}
                                      disabled
                                      maxLength={40}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col className="col" lg={6} md={6} sm={6}>
                                  <Form.Group className="cs-form-group cs-disabled">
                                    <FormLabel>Last name</FormLabel>

                                    <Form.Control
                                      type="text"
                                      autoComplete="off"
                                      name="lastName"
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === " " &&
                                          e.target.selectionStart === 0
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder="Last Name"
                                      defaultValue={record.lastName}
                                      value={record.lastName}
                                      disabled
                                      maxLength={40}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} md={6} sm={6}>
                                  <Form.Group className="cs-form-group cs-disabled">
                                    <FormLabel>Email</FormLabel>

                                    <Form.Control
                                      type="email"
                                      autoComplete="off"
                                      name="email"
                                      onKeyDown={(e) =>
                                        e.code === "Space" && e.preventDefault()
                                      }
                                      placeholder="Email Address"
                                      defaultValue={record.email}
                                      value={record.email}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6} md={6} sm={6}>
                                  <Form.Group className="cs-form-group cs-disabled">
                                    <FormLabel>Phone number</FormLabel>

                                    <Form.Control
                                      className="cursor-pointer not-allowed"
                                      type="text"
                                      autoComplete="off"
                                      name="phoneNo"
                                      placeholder="Phone Number"
                                      defaultValue={formatPhoneNumber(
                                        record.phoneNo
                                      )}
                                      value={formatPhoneNumber(record.phoneNo)}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                {role == 3 && (
                                  <Col className="col" lg={6} md={6} sm={6}>
                                    <Form.Group className="cs-form-group cs-disabled">
                                      <FormLabel>Name of Company</FormLabel>
                                      <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        name=""
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === " " &&
                                            e.target.selectionStart === 0
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        placeholder="Business Name"
                                        defaultValue={record.businessName}
                                        value={record.businessName}
                                        disabled
                                        maxLength={40}
                                      />
                                    </Form.Group>
                                  </Col>
                                )}
                                <Col lg={6} md={6} sm={6}>
                                  <Form.Group className="cs-form-group cs-disabled">
                                    <FormLabel>Address</FormLabel>
                                    <Dropdown className="cs-form-dropdown">
                                      <Dropdown.Toggle>
                                        <FormControl
                                          type="text"
                                          autoComplete="off"
                                          placeholder="Address"
                                          name="address"
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === " " &&
                                              e.target.selectionStart === 0
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                          value={setAddressString(
                                            record.address
                                          )}
                                          id="address-input"
                                          className="autocomplete-input"
                                          disabled
                                        />
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </Form.Group>
                                </Col>
                                {role == 2 && (
                                  <>
                                    <Col lg={6} md={6} sm={6}>
                                      <Form.Group className="cs-form-group cs-disabled">
                                        <FormLabel>License number</FormLabel>
                                        <Form.Control
                                          type="text"
                                          placeholder="License number"
                                          autoComplete="off"
                                          name="licenceId"
                                          defaultValue={record.licenceId}
                                          value={record.licenceId}
                                          maxLength={7}
                                          disabled
                                        />
                                      </Form.Group>
                                    </Col>
                                  </>
                                )}
                              </Row>
                              {role == 2 && (
                                <>
                                  <Row>
                                    <Col lg={6} md={6} sm={6}>
                                      <Form.Group className="cs-form-group cs-disabled">
                                        <FormLabel>Company name</FormLabel>

                                        <Form.Control
                                          type="text"
                                          placeholder="Company name"
                                          autoComplete="off"
                                          name="companyName"
                                          defaultValue={record.companyName}
                                          value={record.companyName}
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                      <Form.Group className="cs-form-group cs-disabled">
                                        <FormLabel>
                                          Company license number
                                        </FormLabel>

                                        <Form.Control
                                          type="text"
                                          placeholder="Company license number"
                                          autoComplete="off"
                                          name="companyLicenceId"
                                          defaultValue={record.companyLicenceId}
                                          value={record.companyLicenceId}
                                          disabled
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </>
                              )}

                              <div className="account-change-password">
                                <span
                                  className="default-regular-h5 cs-primary cursor-pointer"
                                  onClick={resetPassLink}
                                >
                                  Change password
                                </span>
                                <Button
                                  variant="primary"
                                  className="cs-btn-primary sm-btn default-regular-h5"
                                  onClick={() => handleEditProfile(true)}
                                >
                                  Edit
                                </Button>
                              </div>
                              {rejMsg && (
                                <div className="cs-msg default-regular-body-text-s cs-danger">
                                  <span className="icon">
                                    <CapsyncIcon title="info-filled" />
                                  </span>
                                  <span className="txt"> {message}</span>
                                </div>
                              )}
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </>
            </div>
          </Card>
        </Container>
      </section>

      <EditProfile
        values={values}
        addresses={addresses}
        errors={errors}
        record={record}
        touched={touched}
        loading={loading}
        handleBlur={handleBlur}
        fetchRecord={fetchRecord}
        saveDisable={saveDisable}
        handleChange={handleLicenceChange}
        handleSubmit={handleSubmit}
        showEditProfile={showEditProfile}
        selectedAddress={selectedAddress}
        toastMessage={props.toastMessage}
        setShowEditProfile={setShowEditProfile}
        autocompletePredictions={autocompletePredictions}
        address={address}
        resetForm={resetForm}
        phoneHandleChange={phoneHandleChange}
        msg={msg}
        setMsg={setMsg}
        addressObj={addressObj}
        handleReset={handleReset}
        setErrors={setErrors}
        confirmPhoneValidation={confirmPhoneValidation}
        addressMsg={addressMsg}
        updateDetails={updateDetails}
        setUpdateDetails={setUpdateDetails}
        setIsAddressChange={setIsAddressChange}
        setFieldValue={setFieldValue}
        isManualAddress={isManualAddress}
        setAddressObj={setAddressObj}
        setStatus={setStatus}
        status={status}
        setFieldTouched={setFieldTouched}
      />

      <ConfirmEditProfie
        showConfirmEmailUpdate={showConfirmEmailUpdate}
        setShowConfirmEmailUpdate={setShowConfirmEmailUpdate}
        onContinueEmailUpdate={handleContinueEmailUpdate}
      />

      <AuthMFASetUpModal
        closeModal={closeModal}
        CapsyncMessage={CapsyncMessage}
        mfaType={mfaType}
        mfaModal={mfaModal}
        onChangeMFAOption={handleMFAOptionChange}
        mfaOptionModal={mfaOptionModal}
        submitMfaOption={submitMfaOption}
        record={record}
        modalTitle={"Profile Update"}
      />

      <AuthMFAOptionModal
        record={record}
        modalShow={mfaOptionModal}
        setModalShow={setMfaOptionModal}
        mfaType={mfaType}
        saveData={saveData}
      />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="success-change-password default-light-body-text-m cs-neutral-90">
            <Image
              src={SuccessValidation}
              alt="Success Validation"
              width={84}
              height={84}
            />

            <p>
              Reset password link has been sent to your registration email
              address.
            </p>
          </div>
        </Modal.Body>
        <ModalFooter>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={backToSignIn}
            >
              Continue
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* <Modal
        show={mfaModal.show}
        onHide={mfaModal.hide}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            Phone number update
          </Modal.Title>
          <CloseButton onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <MfaPhoneContext.Provider
            value={{ closeMfaModal: () => closeModal() }}
          >
            {mfaType === 1 ? (
              <PhoneVerification CapsyncMessage={CapsyncMessage} />
            ) : (
              ""
            )}
          </MfaPhoneContext.Provider>
        </Modal.Body>
        <ModalFooter>
          <div></div>
        </ModalFooter>
      </Modal> */}
    </>
  );
};
export default AccountDetailsWel;
