import React, { useEffect } from "react";

const ConnectPlaid = () => {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 1000);
  }, []);

  return (
    <>
      <section>Please wait while we fetch your data from PLAID.</section>
    </>
  );
};

export default ConnectPlaid;
