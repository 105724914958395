import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";

import { MfaContext } from "./MFASetup";
import { wealth_managers_mfaPhoneAuthPin } from "../../../../slices/wealthManager.slice";
import { mfaPhoneAuth } from "../../../../slices/auth";
import UseUserRecordWm from "../../../../customHooks/UseUserRecordWm";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import "../../../individual/settings/Mfa_setup/mfa_setup.css";

import { authUserUpdated } from "../../../../slices/auth";
import { uniValidation } from "../../../individual/liabilities/categoryForms/validationSchema";

const WmMfaPhonePinVerification = (props) => {
  const CapsyncMessage = props.CapsyncMessage;
  const fetchData = useContext(MfaContext);
  const [loading, setLoading] = useState(false);
  const [resendMsg, setResendMsg] = useState();
  const [otp, setOtp] = useState();
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();
  const wmUserData = useSelector((state) => state.auth.user.user)

  const handleChange = (otp) => {
    setResendMsg(false);
    setOtp(otp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const MFAPhoneOtp = otp;
    if (MFAPhoneOtp) {
      setMsg("");
      dispatch(
        wealth_managers_mfaPhoneAuthPin({
          user_id: wmUserData.id,
          MFAPhoneOtp: MFAPhoneOtp,
          email: wmUserData.email,
        })
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response.code === 200) {
            fetchData.closeMfaModal();
            dispatch(authUserUpdated({ MFAPhone: true }));
            toast.success(response.message);
          } else {
            setMsg(response.message);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };

  const resendMFAPhoneOTP = () => {
    setOtp("");
    dispatch(mfaPhoneAuth({ user_id: wmUserData.id, email: wmUserData.email }))
      .then((res) => {
        setResendMsg(res && res.payload && res.payload.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const modifyPhoneNo = (str) => {
    let phoneStr = "" + str
    let storeNewContent = `${phoneStr.substring(0, 3)}*******${phoneStr.substring(10, phoneStr.length)}`;
    return storeNewContent;
  }

  return (
    <div className="email-otp-verfication">
      <div className="text-boxes-content">
        <div className="otp-content">
          <h5 className="default-regular-h5 cs-neutral-80">
            We have sent a code to
            <span className="default-regular-h5 cs-primary">
              {" " + modifyPhoneNo(wmUserData.phoneNo)}
            </span>
          </h5>
          <Form onSubmit={handleSubmit} className="otp-form-boxes">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle="otp-txt"
              containerStyle="otp-layout"
              // isInputNum={true}
              inputType="number"
              shouldAutoFocus={true}
            />
            {msg ? (
              <span className="default-light-body-text-s cs-danger">{msg}</span>
            ) : null}
          </Form>
        </div>
        <p className="default-regular-h5 cs-neutral-90">
          Did not receive the code?
          <span
            onClick={resendMFAPhoneOTP}
            className="cs-primary cursor-pointer"
          >
            &nbsp;Resend
          </span>
        </p>
      </div>

      {resendMsg && (
        <div className="cs-msg default-regular-body-text-s cs-success cs-flex">
          <span className="icon cs-success">
            <CapsyncIcon title="verification-outlined" size="18" />
          </span>
          {resendMsg}
        </div>
      )}

      {/* {msg && (
        <div className="cs-msg default-regular-body-text-s cs-danger">
          <span className="icon">
            <CapsyncIcon title="info-filled" />
          </span>
          <span className="txt"> {msg}</span>
        </div>
      )} */}

      <div className="email-verification-buttons">
        <Button
          type="submit"
          onClick={() => props.closeModal()}
          className="cs-btn-secondary md-btn default-regular-h5"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="cs-btn-primary md-btn default-regular-h5"
          disabled={loading}
          onClick={handleSubmit}
        >
          Verify account
        </Button>
      </div>


    </div>
  );
};

export default WmMfaPhonePinVerification;
