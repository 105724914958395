/* eslint-disable */
import React, { useState, useEffect, createContext } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  Modal,
  CloseButton,
  Container,
  FormControl,
  Card,
  FormLabel,
  ModalFooter,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileImageComponent from "../../../profileImage/ProfileCard";
import { useFormik } from "formik";
import * as yup from "yup";

import { userFindRecord } from "../../../../slices/user";
import {
  checkPhoneNumber,
  forgotpassword,
  loginUserFindRecord,
  mfaEmailAuth,
  mfaPhoneAuth,
  setRemoveAuthUser,
  userUpdateRecord,
} from "../../../../slices/auth";
import PhoneVerificationIndi from "../PhoneVerificationIndi";
import CommonSettingHeading from "../CommonHeader/CommonSettingHeading";
import "../../settings/Account_details/account_details.css";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import EditProfile from "./EditProfile";

export const MfaPhoneContext = createContext(null);
import SuccessValidation from "../../../../../assets/images/other/success-validation.svg";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import ConfirmEditProfie from "./ConfirmEmailProfile";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";

import AuthMFASetUpModal from "../../../../commonComponent/modalPopup/AuthMFASetUpModal";
import AuthMFAOptionModal from "../../../../commonComponent/modalPopup/AuthMFAOptionModal";

import {
  formatPhoneNumber,
  localStorageClear,
  setAddressString,
} from "../../../../util/common";
import { isValidPhoneNumber } from "react-phone-number-input";
import { usAreaCodes } from "../../../../config/countryAreaCodes";
import Skeleton from "react-loading-skeleton";
import SkeletonAccount from "../../../Common/Loaders/SkeletonAccount";
import { toast } from "react-toastify";
import { formatFullName } from "../../../../config/finance";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";
import { socket } from "../../../../config/Socket";

const AccountSettings = (props) => {
  const { user, authUser } = useSelector((state) => state.auth);
  const [capLoader, setCapLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [record, setRecord] = useState({});
  // const [newRecord, setNewRecord] = useState({});
  const [autocompletePredictions, setAutocompletePredictions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [address, setAddress] = useState("");
  const [rejMsg, setRejMsg] = useState("");
  const [saveDisable, setSaveDisable] = useState(true);
  const [mfaType, setMfaType] = useState(0);
  const [mfaModal, setMfaModal] = useState(false);
  const [mfaOptionModal, setMfaOptionModal] = useState(false);
  // const [modalType, setModalType] = useState(0);
  const existingAddress = user && user.user.address;
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [userData, setUserData] = useState({});
  // const [usAreaCodes, setUserData] = useState({});
  const [showConfirmEmailUpdate, setShowConfirmEmailUpdate] = useState(false);
  const [addressObj, setAddressObj] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });
  const [updateDetails, setUpdateDetails] = useState("");
  const [isAddressChange, setIsAddressChange] = useState(false);
  const [msg, setMsg] = useState({
    phoneNo: "",
    address: "",
    confirmPhoneNo: "",
  });
  const [isManualAddress, setIsManualAddress] = useState(false); //Is Manual address

  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";

  const { message } = useSelector((state) => state.message);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CapsyncMessage = props.CapsyncMessage;
  let newRecord = {};
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const email = isMySettings ? authUser.user.email : user.user.email;
  const phone = isMySettings ? authUser.user.phoneNo : user.user.phoneNo;
  const pfsAccessId = window.localStorage.getItem("pfs_access_id");
  // const resetMfaType = (a) => {
  //   setRejMsg();
  //   setMfatype(a);
  //   setMfaModal(true);
  // };

  const closeModal = () => {
    setMfaModal(false);
  };
  useEffect(() => {
    socket.on("get_all_details_emit", () => fetchRecord());
    return () => {
      socket.off("get_all_details_emit", () => fetchRecord());
    };
  }, []);

  useEffect(() => {
    fetchRecord();
  }, [id, isMySettings]);

  const fetchRecord = () => {
    if (id && !isMySettings) {
      setCapLoader(true);
      dispatch(userFindRecord({ id }))
        .unwrap()
        .then((response) => {
          newRecord = response;
          const { city, state, country, postal_code, line1 } = response.address;
          setUserData(response);
          setRecord(response);
          setAddress(
            response && response.address && setAddressString(response.address)
          );
          setSelectedAddress(response && response.address);
          setCapLoader(false);
        })
        .catch(() => {});
    } else {
      setCapLoader(true);
      dispatch(loginUserFindRecord({ id: authUser?.user?.id }))
        .unwrap()
        .then((response) => {
          newRecord = response;
          setUserData(response);
          setRecord(response);
          setAddress(
            response && response.address && setAddressString(response.address)
          );
          setSelectedAddress(response && response.address);
          setCapLoader(false);
        })
        .catch(() => {});
    }
  };

  const resetPassLink = () => {
    // dispatch(forgotpassword({ email }))
    //   .unwrap()
    //   .then(() => {
    //     localStorage.removeItem("loginStatus");
    setModalShow(true);
    // })
    // .catch(() => { });
  };

  const resetFormData = () => {
    resetForm();
  };

  const handleChange = ({ target }) => {
    setUpdateDetails("");
    if (msg.phoneNo != "" || msg.address != "") {
      setSaveDisable(true);
    } else {
      setSaveDisable(false);
    }
    setFieldValue(target.name, target.value);

    if (target.name === "zip_code") {
      setIsAddressChange(true);
    }
  };

  const backToSignIn = async () => {
    try {
      await dispatch(forgotpassword({ email })).unwrap();
      setModalShow(false);
      localStorageClear();
      dispatch(setRemoveAuthUser());
      window.location.href = "/";
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleEditProfile = (s) => {
    setMfaType("");
    handleReset();
    fetchRecord();
    setShowEditProfile(s);
    setSaveDisable(true);
    setUpdateDetails("");
  };

  const handleConfirmEmail = () => {
    setShowEditProfile(false);
    setShowConfirmEmailUpdate(true);
  };

  const handleContinueEmailUpdate = () => {
    setShowConfirmEmailUpdate(false);
    setMfaModal(true);
  };

  const handleMFAOptionChange = (type) => {
    setMfaType(type);
    // setMfaModal(false);
    // setMfaOptionModal(true);
  };
  const submitMfaOption = () => {
    if (mfaType === "1") {
      dispatch(mfaEmailAuth({ email }))
        .then((data) => {})
        .catch((err) => {});
    }
    if (mfaType === "2") {
      dispatch(mfaPhoneAuth({ user_id: id, email }))
        .then((res) => {})
        .catch((err) => {});
    }
    setMfaModal(false);
    setMfaOptionModal(true);
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .max(40, uniValidation.first_name.max)
      .required(uniValidation.first_name.require)
      .test("is-not-only-spaces", uniValidation.first_name.require, (value) => {
        return value.trim() !== "";
      }),
    lastName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .max(40, uniValidation.last_name.max)
      .required(uniValidation.last_name.require)
      .test("is-not-only-spaces", uniValidation.last_name.require, (value) => {
        return value.trim() !== "";
      }),
    // address: yup
    //   .string()
    //   .transform((value, input, ctx) => {
    //     if (ctx.isType(value)) return value;
    //     return setAddressString(value).trim()
    //   })
    //   .required("Please enter address").test("is-not-only-spaces", "Please enter address", (value) => {
    //   return value.trim() !== "";
    // })
    //   .matches(/^\S.*$/, "Whitespace is not allowed"),
    // email: yup
    //   .string()
    //   .matches(/^\S*$/, "Whitespace is not allowed")
    //   .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
    //   return value.trim() !== "";
    // })
    //   .email(uniValidation.email.validEmail)
    //   .test('no-more-than-30-before-at', 'Cannot accept more than 30 characters before @', value =>
    //     value ? value.split('@')[0].length <= 30 : true
    //   )
    //   .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
    //     value ? value.split('@')[0].length > 0 : true
    //   )
    //   .test('custom-email-format', uniValidation.email.validEmail, value => {
    //     return value ? uniValidation.email.allowedFormat.test(value) : true
    //   })
    //   .test('no-capital-letters', 'Email address should not contain capital letters', value => {
    //     return !/[A-Z]/.test(value);
    //   }),
    // confirmEmail: yup
    //   .string()
    //   .matches(/^\S*$/, "Whitespace is not allowed")
    //   .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
    //   return value.trim() !== "";
    // })
    //   .email(uniValidation.email.validEmail)
    //   .test('no-more-than-30-before-at', 'Cannot accept more than 30 characters before @', value =>
    //     value ? value.split('@')[0].length <= 30 : true
    //   )
    //   .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
    //     value ? value.split('@')[0].length > 0 : true
    //   )
    //   .test('custom-email-format', uniValidation.email.validEmail, value => {
    //     return value ? uniValidation.email.allowedFormat.test(value) : true
    //   })
    //   .test('no-capital-letters', 'Email address should not contain capital letters', value => {
    //     return !/[A-Z]/.test(value);
    //   })
    //   .oneOf([yup.ref('email'), null], 'Emails must be same'),
    phoneNo: yup.string(),
    // confirmPhoneNo: yup
    //   .string()
    //   .oneOf([yup.ref('phoneNo'), null], 'Phone number must be same'),
    street_name: yup
      .string()
      .required(uniValidation.street_name.require)
      .test(
        "is-not-only-spaces",
        uniValidation.street_name.require,
        (value) => {
          return value.trim() !== "";
        }
      )
      .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
      .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid),
    city: yup
      .string()
      .required(uniValidation.city.require)
      .test("is-not-only-spaces", uniValidation.city.require, (value) => {
        return value.trim() !== "";
      })
      .min(2, uniValidation.city.valid),
    state: yup
      .string()
      .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
      .min(2, "Required at least 2 characters"),
    zip_code: yup
      .string()
      .required(uniValidation.zip_code.require)
      .test("is-not-only-spaces", uniValidation.zip_code.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
      .min(5, uniValidation.zip_code.valid)
      .max(5, uniValidation.zip_code.valid),
  });

  const phoneAlreadyExists = async (phoneNo) => {
    try {
      const response = await dispatch(
        checkPhoneNumber({ phone_number: phoneNo })
      ).unwrap();
      return response.code === 200;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const {
    values,
    errors,
    touched,
    status,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setStatus,
    resetForm,
    handleReset,
    setErrors,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: record,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (
        (!!isAddressChange &&
          !!status &&
          (!!status.street_name ||
            !!status.city ||
            !!status.state ||
            !!status.zip_code)) ||
        msg.field
      ) {
        return;
      }
      if (email !== values.email) {
        setShowConfirmEmailUpdate(true);
        setShowEditProfile(false);
      } else if (phone !== values.phoneNo && msg.phoneNo === "") {
        try {
          const phoneExists = await phoneAlreadyExists(values.phoneNo);
          if (phoneExists) {
            setShowConfirmEmailUpdate(true);
            setShowEditProfile(false);
          } else {
            setFieldError("phoneNo", "Phone Number Already In Use");
          }
        } catch (error) {
          console.error("Error:", error);
          // Handle error if needed
        }
      } else {
        setSaveDisable(true);
        save();
      }
    },
  });

  function detailsAreNotChanged(user_details) {
    const userData = isMySettings ? authUser.user : user.user;
    const { firstName, lastName, address } = userData;
    const isFirstNameOrLastNameChanged =
      firstName !== user_details.firstName ||
      lastName !== user_details.lastName;
    const isAddressChanged =
      isAddressChange &&
      (user_details.address.city !== address.city ||
        user_details.address.state !== address.state ||
        user_details.address.line1 !== address.line1 ||
        user_details.address.postal_code !== address.postal_code);
    const isEmailUpdated = email !== values.email;
    const isPhoneNumberChanged = phone !== values.phoneNo;
    return !(
      isFirstNameOrLastNameChanged ||
      isAddressChanged ||
      isEmailUpdated ||
      isPhoneNumberChanged
    );
  }

  useEffect(() => {
    if (isAddressChange === true) {
      setSaveDisable(false);
    }
  }, [isAddressChange, values]);

  const save = () => {
    const userData = isMySettings ? authUser.user : user.user;
    let user_details = {};
    user_details.user_id = values.id;
    user_details.firstName = values.firstName;
    user_details.lastName = values.lastName;
    // user_details.displayName = values.displayName;
    user_details.address = isAddressChange ? addressObj : userData.address;
    // user_details.email = values.email;
    user_details.isProfessional = false;
    user_details.phoneNo = values.phoneNo;
    user_details.is_manual_address = isManualAddress;
    if (email !== values.email) {
      user_details.is_email_updated = true;
    }

    const { firstName, lastName } = userData;
    const isPhoneNumberChanged = phone !== values.phoneNo;
    const isFirstOrLastNameChanged =
      firstName !== values.firstName || lastName !== values.lastName;
    const isAddressChanged = isAddressChange;

    if (detailsAreNotChanged(user_details)) {
      setShowEditProfile(false);
      return;
    }

    setIsAddressChange(false);
    if (msg.phoneNo === "" && msg.address === "") {
      dispatch(userUpdateRecord(user_details))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            if (
              user_details.is_email_updated &&
              user_details.is_email_updated === true
            ) {
              try {
                if (
                  !window.localStorage.getItem("pfs_access_id") &&
                  !isMySettings
                ) {
                  const storedUser = localStorage.getItem("user");
                  const parsedUser = JSON.parse(storedUser);
                  // const newToken = response.data;
                  // const newUser = { ...parsedUser, token: newToken };
                  const newUser = { ...parsedUser };
                  newUser.user.email = values.email;
                  newUser.user.phoneNo = values.phoneNo;
                  newUser.user.firstName = values.firstName;
                  newUser.user.lastName = values.lastName;
                  newUser.user.displayName = values.displayName;
                  localStorage.removeItem("user");
                  localStorage.setItem("user", JSON.stringify(newUser));
                }
                toast.success(response.message);
              } catch (error) {
                toast.error("failed to update user email");
              }
            } else {
              if (
                isPhoneNumberChanged ||
                isFirstOrLastNameChanged ||
                isAddressChanged
              )
                toast.success(response.message);
            }
            fetchRecord();
            setLoading(false);
            setShowEditProfile(false);
            // props.changeProfileState(!props.profileState);
            // window.location.reload(true);
          } else {
            toast.error(response.message);
            // setLoading(false);
            setSaveDisable(false);
          }
        })
        .catch((err) => {
          setRejMsg(err.message);
          setLoading(false);
          setSaveDisable(false);
        });
    }
  };
  const phoneHandleChange = (val, field) => {
    if (val) {
      if (val.startsWith("+1")) {
        if (
          usAreaCodes.includes(Number(val.split("+1")[1].substr(0, 3))) &&
          val.split("+1")[1].length == 10
        ) {
          setSaveDisable(false);
          setFieldValue(field, val);
          setMsg({ ...msg, field: "" });
        } else {
          setSaveDisable(true);
          setFieldValue(field, val);
          setMsg({ ...msg, field: uniValidation.phone_number.valid });
        }
      } else {
        setSaveDisable(true);
        setFieldValue(field, val);
        setMsg({ ...msg, field: uniValidation.phone_number.valid });
      }
    } else {
      setSaveDisable(true);
      setFieldValue(field, "");
      setMsg({ ...msg, field: uniValidation.phone_number.requrie });
    }
    setUpdateDetails("");
  };

  const confirmPhoneHandleChange = (val, field) => {
    if (val) {
      if (isValidPhoneNumber(val) === true) {
        if (val.startsWith("+1")) {
          if (usAreaCodes.includes(Number(val.split("+1")[1].substr(0, 3)))) {
            // setSaveDisable(false)
            setFieldValue(field, val);
            // setMsg({ ...msg, field: "" });
          } else {
            // setSaveDisable(true)
            setFieldValue(field, val);
            // setMsg({ ...msg, field: uniValidation.phone_number.valid });
          }
        } else {
          // setSaveDisable(false)
          setFieldValue(field, val);
          // setMsg({ ...msg, field: "" });
        }
      } else {
        // setSaveDisable(true)
        setFieldValue(field, val);
        // setMsg({ ...msg, field: uniValidation.phone_number.valid });
      }
    } else {
      // setSaveDisable(true)
      setFieldValue(field, "");
      // setMsg({ ...msg, field: uniValidation.phone_number.valid });
    }
    setUpdateDetails("");
  };
  return (
    <>
      <section className="settings-main-section">
        <Container fluid>
          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl="/individual"
                pagetitle={isMySettings ? "My Settings" : "Settings"}
                subTitle={isMySettings ? "My Settings" : "Settings"}
              />
            </div>
          )}

          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown editAccessUser={props.editAccessUser} />
          )}
          <Card>
            <div className="settings-body">
              <>
                {props.is_loader.assets === true ||
                props.is_loader.liabilities === true ? (
                  <div className="for-des">
                    <div className="skeleton-account-tabs">
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={80} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={150} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                ) : (
                  <CommonSettingHeading editAccessUser={props.editAccessUser} />
                )}
                {props.is_loader.assets === true ||
                props.is_loader.liabilities === true ? (
                  <SkeletonAccount />
                ) : (
                  <>
                    <div className="account-details-page">
                      <Row>
                        <Col lg={9}>
                          <div className="account-detail-box">
                            <div className="profile-personal">
                              <div className="profile">
                                <ProfileImageComponent
                                  role={user && user.user_role.role}
                                  type="Individual"
                                  id={id}
                                  profileImageUrl={record.profileImageUrl}
                                  changeProfileState={props.changeProfileState}
                                  profileState={props.profileState}
                                  changeSrcStatus={props.changeSrcStatus}
                                  customImgSrc={props.customImgSrc}
                                  customLoginImgSrc={props.customLoginImgSrc}
                                  setCustomLoginImgSrc={
                                    props.setCustomLoginImgSrc
                                  }
                                  toastMessage={props.toastMessage}
                                  user={user}
                                  record={record}
                                  fetchRecord={fetchRecord}
                                  editAccessUser={props.editAccessUser}
                                  isMySettings={isMySettings}
                                />
                                <div className="display-names">
                                  <h2 className="default-semi-bold-h2 cs-neutral-100">
                                    {formatFullName(
                                      record.firstName,
                                      record.lastName
                                    )}
                                  </h2>
                                  <p className="default-regular-sub-heading-m cs-neutral-80">
                                    {record.email}
                                  </p>
                                </div>
                              </div>
                              <div className="personal-information">
                                <h3 className="default-regular-h3 cs-neutral-100">
                                  Personal information
                                </h3>
                                {(pfsAccessId === null || isMySettings) && (
                                  <Button
                                    variant="primary"
                                    className="cs-btn-primary sm-btn default-regular-h5"
                                    // onClick={() => setShowEditProfile(true)}
                                    onClick={() => handleEditProfile(true)}
                                    id="profile-edit"
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                            </div>
                            <>
                              <Form className="personal-inform-form">
                                <Row>
                                  <Col className="col" lg={6} md={6} sm={6}>
                                    <Form.Group className="cs-form-group cs-disabled">
                                      <FormLabel>First name</FormLabel>
                                      <Form.Control
                                        type="text"
                                        // name="firstName"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === " " &&
                                            e.target.selectionStart === 0
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        placeholder="First Name"
                                        defaultValue={record.firstName}
                                        value={record.firstName}
                                        disabled
                                        maxLength={40}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col className="col" lg={6} md={6} sm={6}>
                                    <Form.Group className="cs-form-group cs-disabled">
                                      <FormLabel>Last name</FormLabel>
                                      <Form.Control
                                        type="text"
                                        // name="lastName"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === " " &&
                                            e.target.selectionStart === 0
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        placeholder="Last Name"
                                        defaultValue={record.lastName}
                                        value={record.lastName}
                                        disabled
                                        maxLength={40}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6} md={6} sm={6}>
                                    <Form.Group className="cs-form-group cs-disabled">
                                      <FormLabel>Email</FormLabel>
                                      <Form.Control
                                        disabled
                                        type="email"
                                        autoComplete="off"
                                        // name="email"
                                        onKeyDown={(e) =>
                                          e.code === "Space" &&
                                          e.preventDefault()
                                        }
                                        placeholder="Email Address"
                                        defaultValue={record.email}
                                        value={record.email}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} md={6} sm={6}>
                                    <Form.Group className="cs-form-group cs-disabled">
                                      <FormLabel>Phone number</FormLabel>
                                      <Form.Control
                                        type="text"
                                        disabled
                                        autoComplete="off"
                                        // name="phoneNo"
                                        placeholder="Phone Number"
                                        defaultValue={formatPhoneNumber(
                                          record.phoneNo
                                        )}
                                        value={formatPhoneNumber(
                                          record.phoneNo
                                        )}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12}>
                                    <Form.Group className="cs-form-group cs-disabled">
                                      <FormLabel>Address</FormLabel>
                                      <FormControl
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Address"
                                        // name="address"
                                        value={setAddressString(record.address)}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === " " &&
                                            e.target.selectionStart === 0
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        id="address-input"
                                        className="autocomplete-input"
                                        disabled
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                {/* <div> */}
                                <div className="account-change-password">
                                  {(!window.localStorage.getItem(
                                    "pfs_access_id"
                                  ) ||
                                    isMySettings) && (
                                    <span
                                      className="default-regular-h5 cs-primary cursor-pointer"
                                      onClick={resetPassLink}
                                      id="reset-password"
                                    >
                                      Change password
                                    </span>
                                  )}
                                  {(pfsAccessId === null || isMySettings) && (
                                    <Button
                                      variant="primary"
                                      className="cs-btn-primary sm-btn default-regular-h5"
                                      // onClick={() => setShowEditProfile(true)}
                                      onClick={() => handleEditProfile(true)}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </div>
                                {rejMsg && (
                                  <div className="cs-msg default-regular-body-text-s cs-danger cs-flex">
                                    <span className="icon">
                                      <CapsyncIcon title="info-filled" />
                                    </span>
                                    {message}
                                  </div>
                                )}
                              </Form>
                            </>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </>
            </div>
          </Card>
        </Container>
      </section>

      <EditProfile
        currentEmail={email}
        loading={loading}
        record={userData}
        showEditProfile={showEditProfile}
        selectedAddress={selectedAddress}
        setShowEditProfile={setShowEditProfile}
        autocompletePredictions={autocompletePredictions}
        isChanged={isChanged}
        OnEmailUpdate={handleConfirmEmail}
        address={address}
        saveDisable={saveDisable}
        showConfirmEmailUpdate={showConfirmEmailUpdate}
        updateDetails={updateDetails}
        setUpdateDetails={setUpdateDetails}
        handleReset={handleReset}
        setShowConfirmEmailUpdate={setShowConfirmEmailUpdate}
        onContinueEmailUpdate={handleContinueEmailUpdate}
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleSubmit={handleSubmit}
        phoneHandleChange={phoneHandleChange}
        confirmPhoneHandleChange={confirmPhoneHandleChange}
        msg={msg}
        setMsg={setMsg}
        setFieldValue={setFieldValue}
        setAddressObj={setAddressObj}
        addressObj={addressObj}
        status={status}
        setStatus={setStatus}
        setIsAddressChange={setIsAddressChange}
        setErrors={setErrors}
        setFieldTouched={setFieldTouched}
      />

      <ConfirmEditProfie
        showConfirmEmailUpdate={showConfirmEmailUpdate}
        setShowConfirmEmailUpdate={setShowConfirmEmailUpdate}
        onContinueEmailUpdate={handleContinueEmailUpdate}
        values={values}
      />

      <AuthMFASetUpModal
        closeModal={closeModal}
        CapsyncMessage={CapsyncMessage}
        mfaType={mfaType}
        mfaModal={mfaModal}
        onChangeMFAOption={handleMFAOptionChange}
        mfaOptionModal={mfaOptionModal}
        submitMfaOption={submitMfaOption}
        record={record}
        modalTitle={"Profile Update"}
      />
      {/* <commonMfaSetup /> */}

      <AuthMFAOptionModal
        record={userData}
        modalShow={mfaOptionModal}
        setModalShow={setMfaOptionModal}
        mfaType={mfaType}
        // handleAccountProfileUpdate={handleAccountProfileUpdate}
        save={save}
      />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        // dialogClassName={"modal-dialog-border"}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="success-change-password default-light-body-text-m cs-neutral-90">
            <Image
              src={SuccessValidation}
              alt="Success Validation"
              width={84}
              height={84}
            />
            <p>
              Reset password link has been sent to your registration email
              address .
            </p>
          </div>
        </Modal.Body>
        <ModalFooter>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={() => setModalShow(false)}
              // onClick={backToSignIn}
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={backToSignIn}
            >
              Continue
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        show={mfaModal.show}
        onHide={mfaModal.hide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            Phone number update
          </Modal.Title>
          <CloseButton onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <MfaPhoneContext.Provider
            value={{ closeMfaModal: () => closeModal() }}
          >
            {mfaType === 1 ? <PhoneVerificationIndi /> : ""}
          </MfaPhoneContext.Provider>
        </Modal.Body>
        <ModalFooter>
          <div></div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AccountSettings;
