/* eslint-disable */
import React, { useCallback, useState } from "react";
import { Modal, Form, Row, Col, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { useLocation } from "react-router-dom";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import { useEffect } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { checkPhoneValid, phoneHandleChange } from "../../../../util/common";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";

import { usAreaCodes } from "../../../../config/countryAreaCodes";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";
const LifeInsurance = (props) => {
  const { onFormClose, onFormSubmit, record, showModal } = props;
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const insuranceType = ["Individual", "Company"];

  const [loading, setLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  const [loanAmountError, setLoadnAmountError] = useState("");
  const [type, setType] = useState(insuranceType[0]);
  // const [type, setType] = useState("");
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    company: "",
    email: "",
    type: "",
    phone_number: "",
    beneficiary: "",
    cash_value: "",
    loan_amount: "",
    date_updated: moment().format(),
    category: props.category,
  });
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [storageError, setStorageError] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [msg, setMsg] = useState("");
  const checkType = (event) => {
    setType(event);
  };

  useEffect(() => {
    if (!!record) {
      setInitialValues({
        user_id: record.user_id,
        company: record.company,
        email: record.email,
        phone_number: record.phone_number,
        beneficiary: record.beneficiary,
        cash_value:
          record.cash_value && record.cash_value.toLocaleString("en-US"),
        type: record.type,
        loan_amount:
          record.loan_amount && record.loan_amount.toLocaleString("en-US"),
        date_updated: new Date(
          moment(record.date_updated).format("MM/DD/YYYY")
        ),
        notes: record.notes == null ? "" : record.notes,
        category: props.category,
      });
      // setType(record && record.type ? record.type : type)
      record.phone_number && setPhoneNo(record.phone_number);
    }
  }, [record]);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: validationObj.asset_lifeinsurance_validationSchema,
      onSubmit: async (values) => {
        if (
          Number(values.cash_value.replace(/,/g, "")) <=
          Number(values.loan_amount.toString().replace(/,/g, ""))
        ) {
          setLoadnAmountError(
            "The loan amount should not be greater or equal to cash value."
          );
          return;
        } else if (
          Number(values.loan_amount.toString().replace(/,/g, "")) === 0 &&
          props.checkType === "life_insurance"
        ) {
          setLoadnAmountError("Enter Loan Balance");
          return;
        } else {
          setLoadnAmountError("");
        }
        if (phoneNo && (!isValidPhoneNumber(phoneNo) || msg)) {
          setMsg("Enter a valid phone number");
          return true;
        }
        values.phone_number = phoneNo;
        setLoading(true);
        record && (values.id = record.id);
        values.user_id = id;
        values.pfs_access_id = id ? id : 0;
        // values.type = type;
        values.loan_amount = values.loan_amount ? values.loan_amount : 0;
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;
        values.used_space = used_space;
        const formdata = new FormData();
        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.company);
        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }

        let data = {
          category: "lifeinsurance",
          main_category: props.category ? props.category : "assets",
          used_space: used_space,
        };

        if (!!record) {
          data = { ...data, newData: values };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("lifeinsurance");
                toast.success(response.message);
                props.dashboardAPi();
              }
              if (response.code === 500) {
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("lifeinsurance");
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500) {
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      },
    });
  const handleChange = (e) => {
    setLoading(false);
    setFieldValue(e.target.name, e.target.value);
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
    onDropAccepted,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  return (
    <Modal
      show={showModal}
      backdrop="static"
      id="connect-your-institutions"
      aria-labelledby="contained-modal-title-vcenter"
      className="cap-dash-main-modal"
      centered
      keyboard={false}
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {props.checkType === "lifeInsurance"
            ? commonNames.LifeInsurance
            : "Loan(s) Against Life Insurance"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col lg={12}>
              <Form.Group
                className={
                  record && record.api_provider === "plaid"
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Institution</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  name="company"
                  value={values.company}
                  className={values.company && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  disabled={record && record.api_provider === "plaid"}
                />
                {errors.company && touched.company ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.company}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="email"
                  placeholder="Optional"
                  name="email"
                  onKeyDown={(event) => {
                    if (event.code === "Space") event.preventDefault();
                  }}
                  value={values.email}
                  className={values.email && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.email}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Phone Number (Optional)</Form.Label>
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  addInternationalOption={false}
                  placeholder="Optional"
                  autoComplete="off"
                  defaultCountry="US"
                  name="phone_number"
                  withCountryCallingCode={false}
                  value={values.phone_number}
                  // className={values.phoneNo && "cs-input-field-active"}
                  className="form-control"
                  countries={["US"]}
                  // onChange={(e) => checkPhoneValid(e, setPhoneNo, setMsg, setLoading)}
                  onChange={(e) => {
                    phoneHandleChange(e, setPhoneNo, setMsg, setLoading);
                  }}
                />
                {msg !== "" ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {msg}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Beneficiary</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="beneficiary"
                  onKeyDown={(e) => {
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault();
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.beneficiary}
                  className={values.beneficiary && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.beneficiary && touched.beneficiary ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.beneficiary}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group
                className={
                  record && record.api_provider === "plaid"
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Cash Value</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  autoComplete="off"
                  maxLength={12}
                  placeholder=""
                  name="cash_value"
                  value={values.cash_value}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={
                    values.cash_value
                      ? "dollar-symbol form-control cs-input-field-active"
                      : "form-control"
                  }
                  allowNegative={false}
                  decimalScale={2}
                  disabled={record && record.api_provider === "plaid"}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.cash_value && touched.cash_value ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.cash_value}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group ">
                <Form.Label>Type</Form.Label>
                {/* <div className="cs-common-add-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            {type === ""
                                                ? "Individual Retirement Arrangements"
                                                : type.length > 20
                                                    ? type.slice(0, 30) + "..."
                                                    : type}
                                            <span className="cs-neutral-90">
                                                <CapsyncIcon title="chevron-down-outlined" size="18" />
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Toggle>
                                            {type === "" ? "Choose Type" : type.length > 20 ? type.slice(0, 30) + "..." : type}
                                            <span className="cs-neutral-90">
                                                <CapsyncIcon title="chevron-down-outlined" size="18" />
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {insuranceType.map((insuranceItr) => (
                                                <Dropdown.Item
                                                    className={insuranceItr == type ? "cs-selected-dropdown" : ""}
                                                    key={insuranceItr}
                                                    onClick={() => {
                                                        setLoading(false)
                                                        checkType(insuranceItr)
                                                    }}
                                                >
                                                    {insuranceItr}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="type"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Optional"
                  value={values.type}
                  className={values.type && "cs-input-field-active"}
                  onChange={handleChange}
                  onKeyPress={(e) =>
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault()
                  }
                  // onBlur={handleBlur}
                  // onKeyDown={(e) =>
                  //     !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault()
                  // }
                />
                {errors.type && touched.type ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.type}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan Balance</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder={props.category === "assets" ? "Optional" : ""}
                  maxLength={12}
                  name="loan_amount"
                  value={values.loan_amount}
                  onChange={(e) => {
                    handleChange(e);
                    if (
                      Number(values.cash_value.replace(/,/g, "")) <=
                        Number(e.target.value.replace(/,/g, "")) &&
                      e.target.value.length > 0
                    ) {
                      setLoadnAmountError(
                        "The loan amount should not be greater or equal to cash value."
                      );
                    } else {
                      setLoadnAmountError("");
                    }
                  }}
                  // onBlur={handleBlur}
                  className={
                    values.loan_amount
                      ? "dollar-symbol form-control cs-input-field-active"
                      : "form-control"
                  }
                  allowNegative={false}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.loan_amount && touched.loan_amount ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_amount}
                  </span>
                ) : null}
                {loanAmountError !== "" && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {loanAmountError}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Date Updated</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_updated"
                  value={values.date_updated}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_updated", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                />
                {errors.date_updated && touched.date_updated ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_updated}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Notes</Form.Label>
                <textarea
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="notes"
                  value={values.notes}
                  className={
                    values.notes
                      ? "cs-input-field-active cs-textarea"
                      : "cs-textarea"
                  }
                  onChange={handleChange}
                  // onBlur={handleBlur}
                ></textarea>
                {record && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {storageError || duplicateFileError}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          {!record && (
            <>
              <Row>
                <Col>
                  <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                    Upload Files
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="cs-upload-box">
                    <div
                      {...getRootProps({})}
                      className={`cs-upload-files cursor-pointer ${
                        loading && "cs-neutral-60 disabled"
                      }`}
                    >
                      <input
                        {...getInputProps()}
                        disabled={loading}
                        name="attachments"
                      />
                      <span className="cs-primary">
                        <CapsyncIcon title="upload-files-filled" size="28" />
                      </span>
                      <span className="default-regular-sub-heading-s cs-neutral-90">
                        <span className="cs-primary">Browse</span>&nbsp;or drag
                        file here
                      </span>
                      <p className="default-regular-sub-heading-xxs">
                        Supports PDF, PNG, JPG and JPEG format
                      </p>
                    </div>
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {storageError || duplicateFileError}
                    </span>
                    <aside className="category-attachments-files">
                      <ul className="attachment-files cs-files">{files}</ul>
                    </aside>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={onFormClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              disabled={loading}
            >
              Save
              {loading && (
                <span className="cs-common-spinner cs-primary-main">
                  <CapsyncIcon title="loading-outlined" size="16" />
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LifeInsurance;
