import axios from "axios";
import { backAppURl } from "../applicationMode";

let token = window.localStorage.getItem("user")
  ? JSON.parse(window.localStorage.getItem("user")).token
  : "";

var backEndUrl = "";


if (window.localStorage.getItem("user") !== null) {
  backEndUrl = axios.create({
    baseURL: backAppURl + "/api",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  backEndUrl.interceptors.response.use((response) => {

    // if(response && response.data && response.data.code === 400 && response.data.message === "You don't have permission to access this user's account") {
    //   // alert(response.data.message)
    //   // window.localStorage.removeItem('last_pfs_access_id')
    //   window.localStorage.removeItem('pfs_access_obj')
    //   window.localStorage.removeItem('pfs_access_id')
    //   setTimeout(() => {
    //     window.location.reload(true);
    //   }, 200);
    // }
    return response;
  }, (error) => {
    console.log('error :>> ', error);
    if (error && error.response && error.response.data.code == 401) {
      localStorage.clear()
      window.location.href = "/"
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  });
} else {
  backEndUrl = axios.create({
    baseURL: backAppURl + "/api",
  });
}
export default backEndUrl;
