/* eslint-disable */
import React, { useState, useEffect, createContext } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  CloseButton,
  Container,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { userFindRecord, mfaEmailUpdateRecord } from "../../../../slices/user";
import {
  loginUserFindRecord,
  sendVerificationEmail,
} from "../../../../slices/auth";
import { signout } from "../../../../slices/auth";
import EmailVerification from "../EmailVerification";
import MfaPhonePinVerification from "../MfaPhonePinVerification";
import GoogleAppSetup from "../GoogleAppSetup";
import {
  mfaGoogleDeleteRecord,
  mfaPhoneDeleteRecord,
} from "../../../../slices/user";
import { mfaPhoneAuth } from "../../../../slices/auth";
import CommonSettingHeading from "../CommonHeader/CommonSettingHeading";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import "../../settings/Mfa_setup/mfa_setup.css";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import AuthMFASetUpModal from "../../../../commonComponent/modalPopup/AuthMFASetUpModal";
import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import MfaPhoneVerification from "../MfaPhoneVerification";
import SkeletonMfa from "./SkeletonMfa";
import Skeleton from "react-loading-skeleton";
import { authUserUpdated } from "../../../../slices/auth";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";

export const MfaContext = createContext(null);

const mfaSetup = (props) => {
  const CapsyncMessage = props.CapsyncMessage;
  const navigate = useNavigate();
  const [record, setRecord] = useState({});
  const [loader, setLoader] = useState(false);
  const [mfaType, setMfatype] = useState(0);
  const [actionDisabled, setActionDisabled] = useState(false);
  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";
  // const [mfaModal, setMfaModal] = useState(false);
  const [mfaModal, setMfaModal] = useState({
    show: false,
    hide: true,
  });

  const { user, authUser } = useSelector((state) => state.auth);
  const email = isMySettings ? authUser?.user?.email : user.user.email;
  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (id) {
  //     dispatch(userFindRecord({ id }))
  //       .unwrap()
  //       .then((response) => {
  //         setRecord(response);
  //       })
  //       .catch(() => { });
  //   }
  // }, [id]);

  // const validationSchema = yup.object().shape({
  //   email: yup.string().email().required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
  //   return value.trim() !== "";
  // }),
  // });

  const resetMFAGoogle = () => {
    setLoader(true);
    setActionDisabled(true);
    dispatch(
      mfaGoogleDeleteRecord({
        user_id: isMySettings ? authUser?.user?.id : id,
        email: email,
      })
    )
      .unwrap()
      .then(() => {
        setLoader(false);
        if (isMySettings) {
          dispatch(loginUserFindRecord({ id: authUser?.user?.id }));
        } else {
          dispatch(authUserUpdated({ MFAGoogle: false }));
        }
        toast.success(
          "Your multi-factor authentication method has been updated successfully."
        );
      })
      .catch(() => {})
      .finally(() => {
        setActionDisabled(false);
      });
  };

  const resetMFAPhone = () => {
    setLoader(true);
    dispatch(
      mfaPhoneDeleteRecord({ id: isMySettings ? authUser?.user?.id : id })
    )
      .unwrap()
      .then(() => {
        setLoader(false);
        if (isMySettings) {
          dispatch(loginUserFindRecord({ id: authUser?.user?.id }));
        } else {
          dispatch(authUserUpdated({ MFAPhone: false }));
        }
        toast.success(
          "Your multi-factor authentication method has been updated successfully."
        );
      })
      .catch(() => {}, []);
  };

  const resetMfaType = (type) => {
    if (type === 2) {
      dispatch(
        mfaPhoneAuth({
          user_id: isMySettings ? authUser?.user?.id : id,
          email: email,
        })
      )
        .then((res) => {})
        .catch((err) => {});
    }
    setMfatype(type);
    setMfaModal({
      show: true,
      hide: false,
    });
  };

  const closeModal = () => {
    setMfaModal({
      show: false,
      hide: true,
    });
  };

  const MFAPhone = isMySettings ? authUser?.user?.MFAPhone : user.user.MFAPhone;
  const MFAGoogle = isMySettings
    ? authUser.user.MFAGoogle === true &&
      authUser.user.MFAGoogleSecret !== false
    : user.user.MFAGoogle === true && user.user.MFAGoogleSecret !== false;

  return (
    <>
      <section className="settings-main-section">
        <Container fluid>
          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl="/individual"
                pagetitle={isMySettings ? "My Settings" : "Settings"}
                subTitle={isMySettings ? "My Settings" : "Settings"}
              />
            </div>
          )}
          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown editAccessUser={props.editAccessUser} />
          )}
          <Card>
            <div className="settings-body">
              {props.is_loader.assets === true ||
              props.is_loader.liabilities === true ? (
                <div className="for-des">
                  {" "}
                  <div className="skeleton-account-tabs">
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={80} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={150} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                  </div>
                </div>
              ) : (
                <CommonSettingHeading editAccessUser={props.editAccessUser} />
              )}
              <div className="mfa-setup-page">
                <Form
                //  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col lg={8}>
                      <div className="mfa-cards">
                        {props.is_loader.assets === true ||
                        props.is_loader.liabilities === true ? (
                          <>
                            {" "}
                            <SkeletonMfa /> <SkeletonMfa />{" "}
                          </>
                        ) : (
                          <>
                            <Card className="settings-mfa-card">
                              <div className="mfa-icon-text">
                                <span className="cs-neutral-80">
                                  <CapsyncIcon
                                    title="mobile-outlined"
                                    size="38"
                                  />
                                </span>
                                <div className="mfa-content-text">
                                  <h4 className="default-regular-h4 cs-neutral-100">
                                    Mobile verification
                                  </h4>
                                  <h6 className="default-regular-h6 cs-neutral-60">
                                    Send a code sent to your phone via SMS
                                  </h6>
                                </div>
                              </div>
                              <>
                                {MFAPhone ? (
                                  <Button
                                    className="cs-btn-secondary lg-btn default-regular-h5"
                                    onClick={resetMFAPhone}
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    className="cs-btn-primary lg-btn default-regular-h5"
                                    onClick={() => resetMfaType(2)}
                                  >
                                    Setup
                                  </Button>
                                )}
                              </>
                            </Card>
                            <Card className="settings-mfa-card">
                              <div className="mfa-icon-text">
                                <span className="cs-neutral-80">
                                  <CapsyncIcon
                                    title="qr-code-outlined"
                                    size="38"
                                  />
                                </span>
                                <div className="mfa-content-text">
                                  <h4 className="default-regular-h4 cs-neutral-100">
                                    Authenticator app
                                  </h4>
                                  <h6 className="default-regular-h6 cs-neutral-60">
                                    Connect your authenticator app
                                  </h6>
                                </div>
                              </div>
                              <>
                                {MFAGoogle ? (
                                  <Button
                                    className="cs-btn-secondary lg-btn default-regular-h5"
                                    onClick={resetMFAGoogle}
                                    disabled={actionDisabled}
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    className="cs-btn-primary lg-btn default-regular-h5"
                                    onClick={() => resetMfaType(3)}
                                  >
                                    Setup
                                  </Button>
                                )}
                              </>
                            </Card>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Card>
        </Container>
      </section>

      {loader && <CapsyncLoader />}

      <Modal
        show={mfaModal.show}
        onHide={() => closeModal()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            {mfaType === 2
              ? "Mobile verification"
              : "Authenticator app verification"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MfaContext.Provider value={{ closeMfaModal: () => closeModal() }}>
            {mfaType === 2 ? (
              <MfaPhoneVerification
                CapsyncMessage={CapsyncMessage}
                closeModal={closeModal}
              />
            ) : (
              ""
            )}
            {mfaType === 3 ? (
              <GoogleAppSetup
                CapsyncMessage={CapsyncMessage}
                closeModal={closeModal}
              />
            ) : (
              ""
            )}
          </MfaContext.Provider>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default mfaSetup;
