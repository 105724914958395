/* eslint-disable */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FileDownload from "js-file-download";
import axios from "axios";
import {
  Nav,
  Navbar,
  Container,
  Col,
  Image,
  Dropdown,
  Form,
  Row,
  Button,
} from "react-bootstrap";
import "../../individual/utilities/utilities.css";

import premiumIcon from "../../../../assets/icons/svg/filled/premium-filled.svg";
import { userFindRecord } from "../../../slices/user";
import {
  SignaturerequestFetchAll,
  add_advisors,
  change_edit_permission,
  decline_by_main_user,
  get_access_from_indi,
} from "../../../slices/requests";
import UseFetchPermission from "../../../customHooks/UseFetchPermission";
import { backAppURl } from "../../../applicationMode";
import { socket } from "../../../config/Socket";
import CommonFileForModals from "../sharePfsModals/CommmonFileForModals";
import ExpirePopup from "../../../commonComponent/component/ExpirePopup";
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import Notifications from "../../../commonComponent/Notifications";
import NavSettings from "../../../commonComponent/NavSettings";
import TrialExpireComponent from "../../../commonComponent/TrialExpireComponent";
import CapsyncLogo from "../../../../assets/images/other/face.png";
import SharePFS from "./../sharePFS";
import { trialExpiryDays } from "../../../util/common";
import { capitalizeName, nameInitials } from "../../../config/finance";
import Avatar from "../../../commonComponent/Avatar";
import { avatarTitles } from "../../../config/avatar";
import { add_recently } from "../../../slices/pfs_access";
import { roleName } from "../../../config/commonRoleName";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import AccessPfsImage from "./SidebarMenu/AccessPfsImage";
import MultipleUserDropDown from "./SidebarMenu/MultipleUserDropDown";

const NavbarSection = (props) => {
  const {
    dataLiability,
    paymentInstallAuto,
    dataAsset,
    getCategoryTotalLib,
    getCategoryTotal,
    toastMessage,
    handleResponseFromIndividualDecision,
    openAddAccountModal,
    isMobile,
    standardUsers,
    sharedPfs,
    switchAccount,
    standardAccessingUsers,
    isMobileAccessUser,
  } = props;

  const [showModelFirst, setShowModelFirst] = useState(false);
  const [address, setAddress] = useState();
  const [profileImg, setProfileImg] = useState();
  const [displayName, setDisplayName] = useState("");
  const [userRecord, setUserRecord] = useState(false);
  const [mainInitials, setMainInitials] = useState("");
  const [avatarData, setAvatarData] = useState("");
  const [profilePicture, setProfilePicture] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";

  const { fetchUserPermission } = UseFetchPermission();

  const [fetchPendingNotifications, setFetchPendingNotifications] = useState(
    []
  );

  const [expireModal, setExpireModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const role = user && user.user_role.role;
  const { authUser } = useSelector((state) => state.auth);

  const logUser = authUser && authUser.user ? authUser.user : authUser;
  const logUserRole =
    authUser && authUser.user_role ? authUser.user_role : authUser;

  const custom_user_role = user && user.user_role.role;
  const current_user_email = user && user.user.email;
  const current_user_id = user && user.user.id;
  const current_user_token = user && user.token;
  const current_user_access_permission =
    user && user.user.user_access_permission;

  let id;

  //New-----------------------------------------
  const [showSharePFS, setShowSharePFS] = useState(false);
  const [openSharePFS, setOpenSharePFS] = useState(false);
  const [actionDisabled, setActionDisabled] = useState(false);

  function changeExpireState(params) {
    setExpireModal(params);
  }
  const changeCapsyncUser = async (key) => {
    window.localStorage.removeItem("pfs_access_email");
    socket.emit("broadcast_message", {
      user_id: Number(
        JSON.parse(window.localStorage.getItem("pfs_access_obj")).pfs_access_id
      ),
    });

    if (key === "Upgrade") {
      localStorage.setItem(
        "isMySetting",
        authUser && authUser.user && authUser.user.id
      );
    } else {
      const payload = {
        advisor_user_id: Number(window.localStorage.getItem("id")),
        user_id: Number(window.localStorage.getItem("id")),
        advisor_user_role: JSON.parse(
          window.localStorage.getItem("loginStatus")
        ).user_role,
        flag: true,
      };
      await dispatch(add_advisors(payload)).unwrap();
    }
    dispatch(
      change_edit_permission({
        advisor_user_id: Number(window.localStorage.getItem("id")),
        user_id: Number(
          JSON.parse(window.localStorage.getItem("pfs_access_obj"))
            .pfs_access_id
        ),
        flag:
          JSON.parse(window.localStorage.getItem("loginStatus")).user_role === 1
            ? roleName.individual
            : JSON.parse(window.localStorage.getItem("loginStatus"))
                .user_role === 2
            ? roleName.financial_advisor
            : roleName.tax_professional,
      })
    )
      .unwrap()
      .then((response) => {
        // let lastID = window.localStorage.getItem('pfs_access_id') != null ? window.localStorage.getItem('pfs_access_id') : "";
        [
          "pfs_access_obj",
          "pfs_access_id",
          "pfs_access_comp",
          "pfs_comp_id",
          "pfs_comp_name",
          "pfs_access_comp_first",
        ].forEach((itr) => {
          window.localStorage.removeItem(itr);
        });
        if (
          JSON.parse(window.localStorage.getItem("loginStatus")).user_role === 1
        ) {
          // ((authUser && authUser.user && authUser.user.is_subscribe === false) || (authUser && authUser.is_subscribe === false)) && window.localStorage.setItem('last_pfs_access_id', lastID)
          window.location.href = "/individual";
        } else if (
          JSON.parse(window.localStorage.getItem("loginStatus")).user_role === 2
        ) {
          window.location.href = "/financial-advisor";
        } else {
          window.location.href = "/professional";
        }
      })
      .catch((err) => {
        console.log("err:- ", err);
      });
  };

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    dispatch(userFindRecord({ id }))
      .unwrap()
      .then((response) => {
        setUserRecord(response);
        setAddress(response.address ? response.address : "");
        setProfileImg(response.profileImageUrl ? response.profileImageUrl : "");
        setDisplayName(response.displayName);
      })
      .catch(() => {});
  }, [props.profileState]);

  useEffect(() => {
    fetchSignDetails();
  }, []);

  const fetchSignDetails = () => {
    dispatch(
      SignaturerequestFetchAll({
        id: id,
        user_email: current_user_email,
      })
    )
      .unwrap()
      .then((response) => {
        const store = response;
        let response1 = response.map((itr) => itr.email);
        if (response1.includes(current_user_email)) {
          const count = store.filter(
            (x) => x.is_deleted === false && x.check_status === 3
          );
          setFetchPendingNotifications(count);
        } else {
          const count = store.filter(
            (x) => x.is_deleted === false && x.check_status === 2
          );
          setFetchPendingNotifications(count);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadPdf = (params) => {
    if (actionDisabled) return;
    setLoader(true);
    setActionDisabled(true);
    axios({
      url: `${backAppURl}/api/pdf/export`,
      method: "post",
      responseType: "blob",
      data: {
        user_id: params,
        sec: "export",
      },
      headers: {
        Authorization: `Bearer ${current_user_token}`,
      },
    })
      .then((response) => {
        if (response && response.status === 200) {
          setLoader(false);
          FileDownload(response.data, "attachment.pdf");
          props.toastMessage(
            `Your Personal Financial Statement has been downloaded successfully.`,
            "success"
          );
        } else {
          setLoader(false);
          props.toastMessage("Error: PDF not downloaded", "error");
        }
      })
      .catch((err) => {
        setLoader(false);
        props.toastMessage("PDF download :" + err.message, "error");
      })
      .finally(() => {
        setActionDisabled(false);
      });
  };

  //--------------- **** -----------------//

  // function pfsAccessMsg(params) {
  //   if (params !== "") {
  //     let newContent = "";
  //     newContent += params;
  //     let storeContent =
  //       newContent.substring(0, 1).toUpperCase() +
  //       newContent.substring(1, newContent.length);
  //     return storeContent;
  //   }
  // }

  function pfsAccessMsg(params) {
    if (params !== "") {
      let newContent = "";
      if (params.length > 20) {
        newContent = params.substring(0, 20) + "...";
      } else {
        newContent = params;
      }
      let storeContent =
        newContent.substring(0, 1).toUpperCase() +
        newContent.substring(1, newContent.length);
      return storeContent;
    }
  }

  useEffect(() => {
    const handleNewNotification = (msg) => {
      props.fetchAllNotifications();
      fetchSignDetails();
    };
    socket.on("newNotification", handleNewNotification);
    return () => {
      socket.off("newNotification", handleNewNotification);
    };
  }, [props.fetchAllNotifications]);

  const changeFirstModel = (params) => {
    setShowModelFirst(params);
    // setShowSharePFS(true);
  };
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // let notification = props.requestNotifications.length + fetchPendingNotifications.length;
  let notification = props.storeAllNotifications.length;

  const headerClassName = `${
    props.isExpanded ? "expanded-header" : "cs-header"
  }`;
  const handleHamburgerClick = () => {
    !isMobile && props.setIsExpanded(!props.isExpanded); // Toggle the expanded state
    props.toggleSidebar(!props.openSide);
  };

  const [isBulletHovered, setIsBulletHovered] = useState(false);

  const handleMouseEnterOnBullet = () => {
    setIsBulletHovered(true);
  };

  const handleMouseLeaveOnBullet = () => {
    setIsBulletHovered(false);
  };

  const handleSharePFSSubmit = (formValues) => {
    setShowSharePFS(false);
  };

  const handleClickTest = () => {
    setOpenSharePFS(true);
  };

  const daysLeft = trialExpiryDays(userRecord.subscription_expire_date);

  const getInitials = (name) => {
    const nameArray = name.split(" ");
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    const initials = firstName.charAt(0) + lastName.charAt(0);
    setMainInitials(initials.toUpperCase());
  };

  useEffect(() => {
    const {
      editAccessUser: { role_id, id, profileImageUrl, displayName },
      editAccessUser,
    } = props;
    if (editAccessUser && profileImageUrl) {
      if (avatarTitles.includes(profileImageUrl)) {
        if (profileImageUrl === "avtar-6") {
          getInitials(displayName);
        } else {
          getInitials("");
        }
        setProfilePicture("");
        setAvatarData(profileImageUrl);
      } else {
        let profileUrl = `${backAppURl}/api/${
          role_id === 1 ? "user" : "wealthmanager"
        }/profile/${id}`;
        setProfilePicture(profileUrl);
        getInitials("");
        setAvatarData("");
      }
    }
    if (editAccessUser && profileImageUrl === null) {
      getInitials(displayName);
      setAvatarData(profileImageUrl);
    }
  }, [props]);

  const handleSharedPfsItemClick = async (sharedWith) => {
    switchAccount(sharedWith.id, sharedWith);
  };

  const srcUrl = useMemo(
    () =>
      standardAccessingUsers.map(
        (itr, i) =>
          `${backAppURl}/api/${itr.roleName}/profile/${itr.id}?img=${itr.profileImageUrl}`
      ),
    [standardAccessingUsers]
  );
  const multipleDisplayAvatar = useCallback(() => {
    const itr = standardAccessingUsers && standardAccessingUsers[0];
    return (
      <>
        <div className="cs-common-add-dropdown multiple-user-dropdown cs-list-options-dropdown">
          <Dropdown>
            <Dropdown.Toggle className="default-semi-bold-h5">
              <div className="active-user-profile">
                <div className="profile-image">
                  {itr.profileImageUrl == "avtar-6" ||
                  itr.profileImageUrl == null ? (
                    <span className="user-text default-regular-h6 cs-neutral-80">
                      {nameInitials(itr.firstName, itr.lastName)}
                    </span>
                  ) : itr.profileImageUrl == "avtar-1" ? (
                    <Avatar title="avtar-1" size="38" />
                  ) : itr.profileImageUrl == "avtar-2" ? (
                    <Avatar title="avtar-2" size="38" />
                  ) : itr.profileImageUrl == "avtar-3" ? (
                    <Avatar title="avtar-3" size="38" />
                  ) : itr.profileImageUrl == "avtar-4" ? (
                    <Avatar title="avtar-4" size="38" />
                  ) : itr.profileImageUrl == "avtar-5" ? (
                    <Avatar title="avtar-5" size="38" />
                  ) : (
                    <Image
                      src={srcUrl[i]}
                      alt="Profile"
                      className="avtar-6"
                      width={40}
                      height={40}
                    />
                  )}
                </div>
                <span className="active-dot cs-success"></span>
              </div>
              <span className="cs-neutral-80 cs-icon">
                <CapsyncIcon title="chevron-down-outlined" size="14" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="cs-scroller">
              <>
                {props.editAccessUser &&
                  standardAccessingUsers.map((itr, i) => {
                    return (
                      <React.Fragment key={Date.now() + i}>
                        <MultipleUserDropDown
                          itr={itr}
                          srcUrl={srcUrl}
                          i={i}
                          handleResponseFromIndividualDecision={
                            handleResponseFromIndividualDecision
                          }
                        />
                      </React.Fragment>
                    );
                  })}
              </>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }, [srcUrl]);

  const displayAvatars = useCallback(() => {
    return (
      props.editAccessUser &&
      standardAccessingUsers.map((itr, i) => {
        return (
          <React.Fragment key={Date.now() + i}>
            <div className="active-user-profile">
              <div className="cs-common-add-dropdown d-flex">
                <AccessPfsImage
                  itr={itr}
                  srcUrl={srcUrl}
                  i={i}
                  handleResponseFromIndividualDecision={
                    handleResponseFromIndividualDecision
                  }
                />
              </div>
            </div>
          </React.Fragment>
        );
      })
    );
  }, [srcUrl]);

  useEffect(() => {}, [standardAccessingUsers]);
  return (
    <>
      {loader && (
        <div className="fixed-capsync-loader fixed-download-loader">
          <CapsyncLoader />
        </div>
      )}
      <CommonFileForModals
        showModelFirst={showModelFirst}
        changeFirstModel={changeFirstModel}
        toastMessage={props.toastMessage}
        individualUserData={props.individualUserData}
        CapsyncMessage={props.CapsyncMessage}
      />

      <SharePFS
        openSharePFS={openSharePFS}
        pfsSection="navBarSection"
        dataLiability={dataLiability}
        paymentInstallAuto={paymentInstallAuto}
        dataAsset={dataAsset}
        getCategoryTotalLib={getCategoryTotalLib}
        getCategoryTotal={getCategoryTotal}
        toastMessage={toastMessage}
      />

      <ExpirePopup
        expireModal={expireModal}
        changeExpireState={changeExpireState}
        role={roleName.individual}
      />

      <div className={headerClassName}>
        <Navbar collapseOnSelect expand="lg">
          <Container fluid>
            <Row>
              <Col>
                <div className="cs-nav-header">
                  <span
                    onClick={handleHamburgerClick}
                    className="cursor-pointer"
                    id="nav-hamburger"
                  >
                    <CapsyncIcon title="hamburger-outlined" size="22" />
                  </span>

                  <Dropdown className="cs-navbar-dropdown-btn">
                    <Dropdown.Toggle
                      className="default-semi-bold-h5"
                      id={"my-pfs-dropdown"}
                    >
                      {window.localStorage.getItem("pfs_access_id") !== null
                        ? pfsAccessMsg(user.user.displayName)
                        : "My PFS"}
                      <CapsyncIcon title="chevron-down-outlined" size="14" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="cs-pfs-menu cs-scroller">
                      {window.localStorage.getItem("pfs_access_id") !== null ? (
                        <Dropdown.Item
                          onClick={() =>
                            logUser &&
                            logUser.account_id === null &&
                            logUserRole.role === 1
                              ? navigate(
                                  "/individual/my-settings/account-details"
                                )
                              : changeCapsyncUser()
                          }
                          className={
                            window.localStorage.getItem("pfs_access_id") ==
                              null || isMySettings
                              ? "cs-active-accounts pfs-item "
                              : "pfs-item "
                          }
                          id="my-dashboard"
                        >
                          <span className="default-light-body-text-s cs-neutral-100">
                            {logUser &&
                            logUser.account_id === null &&
                            logUserRole.role === 1
                              ? "My Settings"
                              : "My Dashboard"}
                          </span>
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          className={
                            window.localStorage.getItem("pfs_access_id") ===
                            null
                              ? "cs-active-accounts pfs-item "
                              : "pfs-item "
                          }
                          id="my-pfs"
                        >
                          <span className="default-light-body-text-s cs-neutral-100">
                            My PFS
                          </span>
                        </Dropdown.Item>
                      )}
                      <span className="default-regular-sub-heading-xs cs-neutral-60">
                        Shared accounts
                      </span>

                      <div className="cs-search-bar">
                        <Form.Group className="cs-form-group">
                          <Form.Control
                            type="text"
                            placeholder="Search account"
                            value={searchQuery}
                            className={searchQuery && "cs-input-field-active"}
                            onChange={handleSearchChange}
                          />
                          <span className="input-field-icon">
                            <CapsyncIcon title="search-outlined" size="18" />
                          </span>
                        </Form.Group>
                      </div>
                      {sharedPfs.length >= 1 ? (
                        <>
                          {sharedPfs
                            .filter(
                              (sharedWith) =>
                                sharedWith.to_status === 1 &&
                                sharedWith.displayName
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                            )
                            .map((sharedWith) => {
                              const { to_access_permission } = sharedWith;
                              return (
                                <Dropdown.Item
                                  className={
                                    window.localStorage.getItem(
                                      "pfs_access_id"
                                    ) == sharedWith.id && !isMySettings
                                      ? "cs-active-accounts pfs-item"
                                      : "pfs-item-name"
                                  }
                                  key={sharedWith.id}
                                  id="pfs-accounts"
                                  onClick={() =>
                                    handleSharedPfsItemClick(sharedWith)
                                  }
                                >
                                  <div className="default-regular-h5">
                                    <div
                                      className="linked-account"
                                      key={sharedWith.id}
                                    >
                                      <span className="linked-account-name">
                                        <h6 className="default-light-body-text-s cs-neutral-90">
                                          {/* {sharedWith.displayName} */}
                                          {sharedWith.displayName.length >
                                          20 ? (
                                            <CapsyncToolTip
                                              Child={
                                                sharedWith.displayName.slice(
                                                  0,
                                                  20
                                                ) + "..."
                                              }
                                              placement={"top"}
                                              message={sharedWith.displayName}
                                              type="text"
                                            />
                                          ) : (
                                            <>{sharedWith.displayName}</>
                                          )}
                                        </h6>
                                        {/* <CapsyncIcon title="verified-filled" size="18" /> */}
                                      </span>

                                      <span className="cs-neutral-70 default-regular-body-text-xxs">
                                        {to_access_permission == 1
                                          ? "View Only"
                                          : "Standard"}
                                      </span>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              );
                            })}
                          {sharedPfs.length > 0 &&
                            sharedPfs.filter((sharedWith) =>
                              sharedWith.displayName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ).length === 0 && (
                              <span className="default-regular-sub-heading-xs cs-neutral-60">
                                No shared accounts available
                              </span>
                            )}
                        </>
                      ) : (
                        <span className="default-regular-sub-heading-xs cs-neutral-60">
                          No shared accounts available
                        </span>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Nav className="ms-auto navbar-wrapper">
                    {authUser &&
                      authUser.user &&
                      authUser.user.is_beta_user === false &&
                      authUser.user.is_subscribe === false && (
                        <>
                          {logUser &&
                            logUser.account_id === null &&
                            logUserRole &&
                            logUserRole.role === 1 &&
                            window.localStorage.getItem("pfs_access_id") !=
                              null && (
                              <>
                                <div className="upgrade-navbar">
                                  <span className="default-regular-sub-heading-xs">
                                    Create Your PFS
                                  </span>
                                  <Button
                                    className="cs-btn-primary default-regular-h6"
                                    onClick={() => {
                                      changeCapsyncUser("Upgrade");
                                    }}
                                  >
                                    Upgrade
                                  </Button>
                                  <div className="navbar-vertical-line "></div>
                                </div>
                              </>
                            )}

                          {(window.localStorage.getItem("pfs_access_id") ===
                            null ||
                            fetchUserPermission.to_access_permission === 2) && (
                            <>
                              <TrialExpireComponent
                                data={userRecord}
                                daysLeft={daysLeft}
                                changeExpireState={changeExpireState}
                              />
                            </>
                          )}
                        </>
                      )}
                    <div className="multiple-user-flow">
                      {window.localStorage.getItem("pfs_access_id") === null && // ONLY LOGIN USER CAN SEE ACCESS ICON
                        (standardAccessingUsers &&
                        (isMobileAccessUser
                          ? standardAccessingUsers.length < 2
                          : standardAccessingUsers.length < 4)
                          ? displayAvatars()
                          : multipleDisplayAvatar())}
                      {window.localStorage.getItem("pfs_access_obj") === null &&
                        standardUsers.length > 0 && (
                          <div className="cs-common-add-dropdown multiple-user-dropdown cs-list-options-dropdown">
                            <Dropdown>
                              <Dropdown.Toggle className="default-semi-bold-h5">
                                {/* <div className="profile-image">
                                  <span className="user-text default-regular-h6 cs-neutral-80">EW</span>
                              </div> */}
                                <span className="cs-neutral-80 cs-icon">
                                  <CapsyncIcon
                                    title="chevron-down-outlined"
                                    size="14"
                                  />
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="cs-scroller">
                                {standardUsers.map((itr) => {
                                  return (
                                    <>
                                      <div className="multiple-user-list">
                                        <div className="multiple-user-item">
                                          <div className="profile-image">
                                            {/* <span className="user-text default-regular-h6 cs-neutral-80">
                                              {nameInitials(itr.request_send_by_displayName.split(' ')[0], itr.request_send_by_displayName.split(' ')[1])}
                                            </span> */}
                                            <div className="multiple-user-avatar">
                                              {avatarData !== "" && (
                                                <>
                                                  <Avatar
                                                    title={
                                                      avatarData || "avtar-6"
                                                    }
                                                    size="38"
                                                  />
                                                  <div className="initials-overlay default-regular-h6 cs-neutral-80">
                                                    {mainInitials}{" "}
                                                  </div>
                                                </>
                                              )}
                                              {profilePicture !== "" && (
                                                <Image
                                                  src={profilePicture}
                                                  alt="Profile"
                                                  width={40}
                                                  height={40}
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div className="profile-info">
                                            <span className="default-regular-sub-heading-s cs-neutral-90">
                                              {capitalizeName(
                                                itr.request_send_by_displayName.split(
                                                  " "
                                                )[0]
                                              ) +
                                                " " +
                                                capitalizeName(
                                                  itr.request_send_by_displayName.split(
                                                    " "
                                                  )[1]
                                                )}
                                            </span>
                                            <span className="default-regular-body-text-xs cs-neutral-60">
                                              {itr.request_send_by_role ===
                                              roleName.financial_advisor
                                                ? "Financial Advisor"
                                                : "Firm"}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="cs-list-options">
                                          <div className="cs-common-add-dropdown">
                                            {Object.keys(props.editAccessUser)
                                              .length === 0 && (
                                              <Dropdown>
                                                {/* <Dropdown.Toggle>
                                                  <span className="icon cs-neutral-80">
                                                    <CapsyncIcon
                                                      title="option-horizontal-filled"
                                                      size="18"
                                                    />
                                                  </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  <Dropdown.Item onClick={() => giveAccountAccess(itr.request_send_by, itr.request_send_by_role)}>
                                                    <span className="default-regular-sub-heading-s">
                                                      Give account access
                                                    </span>
                                                  </Dropdown.Item>
                                                </Dropdown.Menu> */}
                                              </Dropdown>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                    </div>
                    {props.editAccessUser &&
                    standardAccessingUsers &&
                    standardAccessingUsers.length ? (
                      <div className="navbar-vertical-line nav-icons"></div>
                    ) : (
                      ""
                    )}

                    <div className="notification-container">
                      {user.user.is_beta_user === false && (
                        <span
                          className="upgrade-header-mobile"
                          onClick={() => changeExpireState(true)}
                        >
                          <Image src={premiumIcon} alt="premiumIcon" />
                        </span>
                      )}

                      {/* ----------------------------------------------------------------------------------------- */}
                      {/* <Notifications /> */}

                      {window.localStorage.getItem("pfs_access_id") ===
                        null && (
                        <>
                          <Notifications
                            forIndividual={true}
                            props={props}
                            notification={notification}
                            address={address}
                            profileImg={profileImg}
                          />
                          <div className="navbar-vertical-line nav-icons"></div>
                        </>
                      )}
                      {/* <div className="navbar-vertical-line nav-icons"></div> */}
                      {/* ................................................................................................ */}
                      {/* {fetchUserPermission.to_access_permission === 1 &&
                        window.localStorage.getItem("pfs_access_id") !== null ? (
                        <>
                          <div className="nav-icon-styling nav-icons">
                            <span
                              onClick={() => downloadPdf(id)}
                              className="cursor-pointer" id="download-icon">
                              <CapsyncIcon
                                title="download-outlined"
                                size="22"
                                onClick={() => changeFirstModel(true)}
                              />
                            </span>
                          </div>
                        </>
                      ) : (
                        <> */}
                      {props.editAccessUser && (
                        <div className="nav-icon-styling nav-icons">
                          <span
                            onClick={() => changeFirstModel(true)}
                            className="cursor-pointer"
                            id="share-icon"
                          >
                            <CapsyncIcon title="share-outlined" size="22" />
                          </span>
                        </div>
                      )}
                      <div className="nav-icon-styling nav-icons">
                        <span
                          onClick={() => downloadPdf(id)}
                          className="cursor-pointer"
                          id="download-icon"
                        >
                          <CapsyncIcon
                            title="download-outlined"
                            size="22"
                            // onClick={() => changeFirstModel(true)}
                          />
                        </span>
                      </div>
                      {/* </>
                      )} */}

                      {/* -------------------------------------------------------------------------------------------------------------------*/}
                      <div className="navbar-vertical-line nav-icons"></div>
                    </div>
                    <div>
                      <NavSettings props={props} profileImg={profileImg} />
                    </div>
                  </Nav>
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>

        <Button
          onClick={() => openAddAccountModal(true)}
          className="cs-neutral-10 mobile-add-icon"
        >
          <CapsyncIcon title="add-filled" size="22" />
        </Button>
      </div>
    </>
  );
};
export default NavbarSection;
