/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import moment from "moment";
import { userRequestList } from "../../../slices/requests";
import { fetch_user_from_email } from "../../../slices/commonUsers";

import commonNames from "../../../config/commonNames";
// import vector from "./../../../assets/img/icons-png/vector-filter.png";
import { backAppURl } from "../../../applicationMode";
import { socket } from "../../../config/Socket";
import CommmonFileForModals from "../sharePfsModals/CommmonFileForModals";

import UseFetchPermission from "../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../PermissionPrevent";
import { sendCancelemail, sendpfsemail } from "../../../slices/user";
import { requestFetchAll } from "../../../slices/requests";
import handleDownload from "../../../customHooks/SignaturePdfdownload";
import RequestTable from "./RequestTable";
import SignatureFrom from "./SignatureFrom";
// import img from "../../../../assets/icons/svg/outlined/search-outlined.svg";
// import DarkDownloadIcon from "../../../../assets/icons/svg/outlined/download-outlined.svg";
import OffCanvas from "./OffCanvas";
import SignatureTo from "./SignatureTo";

import DeclineSignatureModal from "./DeclineSignatureModal";
import CancelRequestConfirmation from "./CancelRequestConfirmation";
import SkeletonRequest from "./SkeletonRequest";
import UseFetchDetails from "../../../customHooks/UseFetchDetails";
import { roleName } from "../../../config/commonRoleName";
import { emailSchema } from "../../../util/common";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import Pagination from "../../../commonComponent/Pagination";
import {
  fetchSignatureFrom,
  getSignatureTo,
  requestStatusFilter,
} from "../../../util/request";
import { Buffer } from "buffer";
const RequestDashboard = ({
  fetchAllIndividuals,
  fetchAllNotifications,
  individualUserData,
  toastMessage,
  CapsyncMessage,
  ...props
}) => {
  // console.log(props.fetchAllIndividuals)
  const { user, authUser } = useSelector((state) => state.auth);

  const user_id = user && user.user.id;
  const user_email = user && user.user.email;
  const current_user_token = user && user.token;
  let id = user && user.user.id;
  let user_name = user && user.user.displayName;
  const pfsEmail = window.localStorage.getItem("pfs_access_email")
    ? window.localStorage.getItem("pfs_access_email")
    : user.user.email;
  let pfsId;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    pfsId = window.localStorage.getItem("pfs_access_id");
  } else {
    pfsId = user && user.user.id;
  }

  const tabs = [
    { name: "Pending", value: "1" },
    { name: "Completed", value: "2" },
  ];

  const [URL, setUrl] = useState(null);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");

  const [search, setSearch] = useState([]);
  const [sharePFS, setSharePFS] = useState({
    show: false,
    hide: true,
  });
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [signature_id, setSignature_id] = useState("");
  const [validationMsg, setValidationmsg] = useState("");
  const [validationMsg1, setValidationmsg1] = useState("");
  const [validationMsgLastName, setValidationmsgLastName] = useState("");

  const [showPFSPreview, setShowPFSPreview] = useState(false);
  const [linkk, setLinkk] = useState("");
  const [showCanvas, setShowCanvas] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [status, setStatus] = useState("Pending");
  // const [permissionVal, setPermissionVal] = useState(1);
  const [userId, setUserId] = useState("");
  const [storeBoth, setStoreBoth] = useState([]);
  const [filteredIndividuals, setFiltered] = useState(storeBoth);
  const [SignatureId, setSignatureId] = useState("");
  const [SignatureReqid, setSignatureReqid] = useState("");
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showString, setShowString] = useState(false);
  const [sigId, setSigId] = useState("");
  const [flag, setFlag] = useState("");
  const [sendUser, setSendUser] = useState("");
  const [wm_user_id, setWm_user_id] = useState("");
  const [signatureRequestId, setSignatureRequestId] = useState("");
  const [sentData, setSentData] = useState({});
  const [AllIndividuals, setAllIndividuals] = useState([]);
  const [AllIndividualsRequest, setAllIndividualsRequest] = useState([]);
  // const [fetchAllIndividuals, setFetchAllIndividuals] = useState([]);
  // const [signatureFrom, setSignatureFrom] = useState([]);
  // const [signatureTo, setSignatureTo] = useState([]);
  const [OffcanvasState, setOffcanvasState] = useState({});
  // const [accountAccessList, setAccountAccessList] = useState([]);
  const [sentByEmail, setSentByEmail] = useState("");

  const [new_AllIndividuals, setNewAllIndividuals] = useState({
    request_send_by_email: "",
  });

  const [ToUserEmail, setTouserEmail] = useState({
    ToUserEmail: "",
  });

  const [IsChecked, setIsChecked] = useState({
    IsChecked: "",
  });

  const [urlLink, setUrlLink] = useState(" ");
  const [SpouseValue, setSpouseValue] = useState({ secondOne: false });
  const [pfsLnk, setPfsLnk] = useState({ PfsLnk: "" });

  const [PfSLnk, setPfSLnk] = useState({ PfsLnk: "" });
  const [secondIndividuals, setSecondIndividuals] = useState([]);
  const [individualSignature, setIndividualSignature] = useState({
    individualSignature: "",
  });
  const [isCheckedWealth, setIsCheckedWealth] = useState({
    isCheckedWealth: "",
  });

  const [recieverEmailWealth, setWealthEmail] = useState({
    recieverEmailWealth: "",
  });

  const [spouseEmail, setSpouseEmail] = useState({
    spouseEmail: "",
  });

  const [individualSign, setIndividualSign] = useState({
    individualSign: false,
  });

  const [notesText, setNotesText] = useState({
    notesText: "",
  });

  const [toUserEmail, setToUserEmail] = useState({
    toUserEmail: "",
  });

  const [jointUserEmail, setJointUserEmail] = useState({
    jointUserEmail: "",
  });

  const [jointUserName, setJointUserName] = useState({
    jointUserName: "",
  });

  const [secondUserRequests, setSecondUserRequests] = useState({
    secondUserRequests: false,
  });

  const [secondUserEmail, setSecondUserEmail] = useState({
    secondUserEmail: "",
  });
  const [showSignatureRequestDecline, setShowSignatureRequestDecline] =
    useState(false);
  const [showSignatureRequestCancel, setShowSignatureRequestCancel] =
    useState(false);

  const accountAccess = useSelector(
    (state) => state.notification.accountAccess
  );
  const signatureRequest = useSelector(
    (state) => state.notification.signatureRequest
  );
  const [rowStartIdxSignatureFrom, setRowStartIdxSignatureFrom] = useState(0);
  const [rowStartIdxSignatureTo, setRowStartIdxSignatureTo] = useState(0);
  const [rowStartIdxAccount, setRowStartIdxAccount] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPageSignatureFrom, setRowsPerPageSignatureFrom] = useState(
    paginationDropdownItems[0]
  );
  const [rowsPerPageSignatureTo, setRowsPerPageSignatureTo] = useState(
    paginationDropdownItems[0]
  );
  const [rowsPerPageAccount, setRowsPerPageAccount] = useState(
    paginationDropdownItems[0]
  );

  const fetchSignatureFromDetails = () => {
    dispatch(
      userRequestList({
        id: pfsId,
        user_email: pfsEmail,
        flag: roleName.individual,
      })
    )
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        console.log("fetchSignatureFromDetails exception:- ", err);
      });
  };

  useEffect(() => {
    fetchAccountAccessList();
  }, []);

  useEffect(() => {
    if (user && user.user && user.user.email && user.user.id)
      fetchSignatureFromDetails();
  }, [user]);

  useEffect(() => {
    const handleNewNotification = (msg) => {
      fetchAllNotifications();
    };
    socket.on("newNotification", handleNewNotification);
    return () => {
      socket.off("newNotification", handleNewNotification);
    };
  }, [fetchAllNotifications]);

  useEffect(() => {
    const mergedArray = [
      ...fetchAllIndividuals,
      ...AllIndividuals,
      ...AllIndividualsRequest,
      ...secondIndividuals,
    ];

    setStoreBoth(mergedArray);
  }, [
    fetchAllIndividuals,
    AllIndividuals,
    AllIndividualsRequest,
    secondIndividuals,
  ]);

  useEffect(() => {
    setFiltered(storeBoth);
  }, [storeBoth]);

  // const fetchAccountAccessList = useCallback(() => {
  //   dispatch(
  //     requestFetchAll({
  //       id: id,
  //       user_email: current_user_email,
  //     })
  //   )
  //     .unwrap()
  //     .then((response) => {
  //       const count = response.individual.filter((x) => x.check_status === 3);
  //       setFetchAllIndividuals(response.individual);
  //       setNotifications(count);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [id, fetchAllIndividuals, notifications]);

  const fetchAccountAccessList = () => {
    dispatch(
      requestFetchAll({
        id: id,
        user_email: user_email,
      })
    )
      .unwrap()
      .then((response) => {
        // const count = response.individual.filter((x) => x.check_status === 3);
        // setAccountAccessList(response.individual);
        // setNotifications(count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const handleUpdatedList = (msg) => {
      fetchAccountAccessList();
    };
    socket.on("get_updated_list", handleUpdatedList);
    return () => {
      socket.off("get_updated_list", handleUpdatedList);
    };
  }, []);

  const dispatch = useDispatch();
  const { fetchUserPermission } = UseFetchPermission();

  const searchIndividual = (params) => {
    setSearch(params);
    let findIndividual = storeBoth.filter((x) => {
      return (
        x.request_send_by_displayName
          .toLowerCase()
          .match(params.toLowerCase()) ||
        x.request_send_by_role.toLowerCase().match(params.toLowerCase()) ||
        x.request_type.toLowerCase().match(params.toLowerCase()) ||
        moment(x.date_updated).format("MM/DD/YYYY").match(params)
      );
    });
    setFiltered(findIndividual);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setValidationmsg1("");
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setValidationmsgLastName("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValidationmsg("");
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  // let regex = /^\S*$/;
  // let regex = /^[A-Za-z\s]+$/;
  let regex = /[0-9]/;

  function inValidateName(name) {
    return regex.test(name);
  }
  function inValidateLastName(name) {
    return regex.test(name);
  }
  const handleSubmitSec = async (e) => {
    e.preventDefault();
    const datas = { name, lastName, email, notes };
    let nameError = !datas.name
      ? "Enter the First Name"
      : inValidateName(datas.name)
      ? "Enter a valid first name"
      : "";
    let lastNameError = !datas.lastName
      ? "Enter the Last Name"
      : inValidateLastName(datas.lastName)
      ? "Enter a valid last name"
      : "";
    let emailError = "";
    try {
      await emailSchema.validate(datas.email);
    } catch (error) {
      emailError = error.message || uniValidation.email.validEmail;
    }
    setValidationmsg1(nameError);
    setValidationmsg(emailError);
    setValidationmsgLastName(lastNameError);

    if (nameError === "" && emailError === "" && lastNameError === "") {
      setLinkk(sharePFS.pfslink);
      setSharePFS(false);
      openSecondModal(sharePFS.pfslink, datas);
    }
  };

  const openSecondModal = (values, data1) => {
    setSentData(data1);
    fetchUrl(values);
    setShowPFSPreview(true, data1);
  };

  const fetchUrl = async (values) => {
    if (values) {
      const username = process.env.REACT_APP_HELLO_SIGN_USER_NAME;
      const password = process.env.REACT_APP_HELLO_SIGN_PASSWORD;

      const token = Buffer.from(`${username}:${password}`, "utf8").toString(
        "base64"
      );

      const headers = { Authorization: `Basic ${token}` };

      const response = await axios.get(values, {
        headers,
        responseType: "arraybuffer",
      });

      const fileData = response.data;
      const pdfDoc = await PDFDocument.create();

      const downloadedPDF = await PDFDocument.load(fileData);

      const pageCount = downloadedPDF.getPageCount();

      for (let i = 0; i < pageCount; i++) {
        const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
        pdfDoc.addPage(copiedPage);
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setUrl(url);
    }
  };

  const handleClosePFSPreview = () => {
    setShowPFSPreview(false);
    setValidationmsg("");
    setValidationmsg1("");
    setValidationmsgLastName("");
    setUrl(null);
  };

  const sharePFSModal = (show, hide, pfslink, sign_id, sentByEmail) => {
    setSentByEmail(sentByEmail);
    setSignature_id(sign_id);
    setSharePFS({ show, hide, pfslink });
    setUrlLink(pfslink);
    setEmail("");
    setName("");
    setLastName("");
    setValidationmsg("");
    setValidationmsg1("");
    setValidationmsgLastName("");
    setNotes("");
  };

  const handleSignatureDetailsClick = (
    request_send_by_email,
    dataParam,
    signature_Id,
    signature_req_id,
    requestPageType
  ) => {
    setSignatureId(signature_Id);
    setSignatureReqid(signature_req_id);
    dispatch(fetch_user_from_email({ email: request_send_by_email }))
      .unwrap()
      .then((response) => {
        let offCanvasObject = {
          requestPageType: requestPageType,
          id: dataParam.id,
          row_id: dataParam.id,
          signature_date: dataParam.signature_date,
          signature_req_id: dataParam.request_id,
          request_type: dataParam.request_type,
        };
        // offCanvasObject['signature_date'] = dataParam.requestPageType ? dataParam.signature_date : "";
        // offCanvasObject['signature_req_id'] = dataParam.signature_hello_id;
        // offCanvasObject['request_type'] = dataParam.request_type;
        // offCanvasObject['signature_req_id'] = dataParam.id;

        if (response.code == "200") {
          let companyData = "";
          if (response && response.res_data.businessName) {
            companyData = response && response.res_data.businessName;
          }
          // if (response && response.res_data.companyLicenceId) {
          //   if (response && response.res_data.companyName) {
          //     companyData += " | ";
          //     companyData += response && response.res_data.companyLicenceId;
          //   } else {
          //     companyData += response && response.res_data.companyLicenceId;
          //   }
          // }
          offCanvasObject = {
            ...offCanvasObject,
            request_send_by: response.res_data.id,
            request_send_by_displayName:
              dataParam && dataParam.request_send_by_displayName
                ? dataParam.request_send_by_displayName
                : `${dataParam.request_sent_to_firstName} ${dataParam.request_sent_to_lastName}`,
            request_send_by_phoneNo: response.res_data.phoneNo,
            request_send_by_email: response.res_data.email,
            request_send_by_role: response.role,
            // request_send_by_role: dataParam.request_sent_by_role,
            request_send_by_photo: response.res_data.profileImageUrl,
            request_send_by_companyData: dataParam.companyName,
            request_send_to_role: dataParam.request_send_to_role,
            companyLicenceId: response.res_data.companyLicenceId,
            licenceId: response.res_data.licenceId,

            request_sent_to_email: dataParam.request_sent_to_email,
            check_status: dataParam.request_status || dataParam.check_status,
            createdAt: dataParam.createdAt,
            is_deleted: dataParam.is_deleted,
            is_signed: dataParam.isSigned,
            SpouseJointEmail: dataParam.joint_user_email,
            SpouseJointName: dataParam.jointUserName,
            updatedAt: dataParam.updatedAt,
            send_user: dataParam.send_user,
            FirstEmail: dataParam.email,
            FirstUserName: dataParam.firstName + " " + dataParam.lastName,
            access_permission: dataParam.access_permission,
            signStatus: dataParam.sign_status,
            signature_hello_id: dataParam.signature_hello_id,
            secondUserEmail: dataParam.request_sent_by_email,
            firstUserEmail: dataParam.request_sent_to_email,
            username:
              dataParam.request_sent_to_firstName +
              " " +
              dataParam.request_sent_to_lastName,
            wealthManagerName: dataParam.request_sent_by_displayName,
            request_sent_by_role: dataParam.request_sent_by_role,
            receiver_email: dataParam.receiver_email,
            receiver_name:
              dataParam.receiver_firstName + " " + dataParam.receiver_lastName,
            receiver_note: dataParam.receiver_notes,
            spouse_email: dataParam.spouse_email,
            spouse_name: dataParam.spouse_name,
            request_sent_to_role: dataParam.request_sent_to_role,
            signer_count: dataParam.signer_count,
          };
        } else {
          offCanvasObject = {
            ...offCanvasObject,
            request_send_by_email: request_send_by_email,
            check_status: dataParam.request_status,
            request_send_by_phoneNo: dataParam.request_sent_to_phoneNo,
            request_send_by_displayName: `${dataParam.request_sent_to_firstName} ${dataParam.request_sent_to_lastName}`,
            request_send_by_role: dataParam.request_sent_by_role,
            companyLicenceId: "-",
            request_send_by_companyData: dataParam.companyName,
            createdAt: dataParam.createdAt,
            // request_send_by_role: dataParam.request_sent_by_role,
            request_send_by_role: roleName.individual,
            signStatus: dataParam.sign_status,
            request_send_by: dataParam.request_sent_to,
            secondUserEmail: dataParam.request_sent_by_email,
            firstUserEmail: dataParam.request_sent_to_email,
            username:
              dataParam.request_sent_to_firstName +
              " " +
              dataParam.request_sent_to_lastName,
            wealthManagerName: dataParam.request_sent_by_displayName,
            request_sent_by_role: dataParam.request_sent_by_role,
            receiver_email: dataParam.receiver_email,
            receiver_name:
              dataParam.receiver_firstName + " " + dataParam.receiver_lastName,
            receiver_note: dataParam.receiver_notes,
            spouse_email: dataParam.spouse_email,
            spouse_name: dataParam.spouse_name,
            request_sent_to_role: dataParam.request_sent_to_role,
            signature_hello_id: dataParam.signature_hello_id,
            signer_count: dataParam.signer_count,
          };
        }

        setOffcanvasState(offCanvasObject);
        setShowCanvas(true);
      })
      .catch((err) => {
        //
      });
  };

  const openSecondModalRequest = (makeTrue, textString) => {
    setShowCanvas(false);
    setShowSecondModal(makeTrue);
    setShowString(textString);
  };

  const handleSubmit = (datas, linkk) => {
    dispatch(
      sendpfsemail({
        user_id: user_id,
        pfsLink: linkk,
        firstUserEmail: datas.email,
        secondUserEmail: "",
        secondUserName: datas.name + " " + datas.lastName,
        notes: datas.notes,
        user: "user",
        signature_id: signature_id,
        ind_flag: `${user_email !== sentByEmail ? "receiver" : ""}`,
      })
    );
    setShowPFSPreview(false);
    toastMessage(
      "Your Personal Financial Statement was sent successfully.",
      "success"
    );
    setUrl(null);
  };

  // async function emailCancellation(signatureRequestId) {
  //   let cancellationApi = `${backAppURl}/api/hellosign/cancel_signature`;
  //   await fetch(cancellationApi, {
  //     method: "post",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${current_user_token}`,
  //     },
  //     body: JSON.stringify({
  //       values: signatureRequestId,
  //     }),
  //   });
  // }

  const HandleOpen = (id, flag, sendUser, wm_user_id, signatureRequestId) => {
    setSigId(id);
    setFlag(flag);
    setSendUser(sendUser);
    setWm_user_id(wm_user_id);
    setSignatureRequestId(signatureRequestId);
    setShowSignatureRequestDecline(true);
  };

  const handleSignatureRequestDecline = () => {
    setShowCanvas(false);
    setShowSignatureRequestDecline(false);
    // emailCancellation(signatureRequestId);
    // setTimeout(() => window.location.reload(true), 2000)
    // fetchSignatureFromDetails();
    fetchSignatureFromDetails();
  };

  filteredIndividuals &&
    filteredIndividuals.filter(
      (x) => x.is_deleted === false && x.check_status === 3
    );

  filteredIndividuals &&
    filteredIndividuals.filter(
      (x) => x.is_deleted === true || x.check_status === 1
    );

  useEffect(() => {
    if (tabValue === "1") {
      setStatus("Pending");
    } else if (tabValue === "2") {
      setStatus("Completed");
    }
  }, [tabValue]);
  // ---------------------------------------------------------------------------------

  const handleCancelRequestConfirmationClick = (requestId) => {
    setShowSignatureRequestCancel(true);
  };

  const handleSignatureRequestCancelConfirm = () => {};
  useEffect(() => {
    socket.on("signature_request_status_update_individual", (msg) => {
      // fetchSignatureFromDetails()
      fetchSignatureFromDetails();
    });
  }, []);

  // -----------------------------------------------------------

  const handleTabChange = (tabs) => {
    setTabValue(tabs.value);
  };

  useEffect(() => {
    setRowStartIdxSignatureFrom(0);
    setRowStartIdxSignatureTo(0);
    setRowStartIdxAccount(0);
  }, [tabValue]);

  return (
    <>
      <CommmonFileForModals
        SignatureReqid={SignatureReqid}
        SignatureId={SignatureId}
        notesText={notesText}
        showSecondModal={showSecondModal}
        showString={showString}
        setShowSecondModal={setShowSecondModal}
        AllIndividuals={AllIndividuals}
        new_AllIndividuals={new_AllIndividuals}
        SpouseValue={SpouseValue}
        pfsLnk={pfsLnk}
        toUseremail={ToUserEmail}
        individualSignature={individualSignature}
        IsChecked={IsChecked}
        isCheckedWealth={isCheckedWealth}
        recieverEmailWealth={recieverEmailWealth}
        individualSign={individualSign}
        toUserEmail={toUserEmail}
        PfSLnk={PfSLnk}
        spouseEmail={spouseEmail}
        jointUserEmail={jointUserEmail}
        jointUserName={jointUserName}
        secondUserRequests={secondUserRequests}
        secondUserEmail={secondUserEmail}
        userId={userId}
        individualUserData={individualUserData}
        toastMessage={toastMessage}
        CapsyncMessage={CapsyncMessage}
      />

      <DeclineSignatureModal
        signatureRequestId={signatureRequestId}
        sendUser={sendUser}
        showSignatureRequestDecline={showSignatureRequestDecline}
        // HandleOpen={HandleOpen}
        onSignatureRequestDecline={handleSignatureRequestDecline}
        setShowSignatureRequestDecline={setShowSignatureRequestDecline}
        toastMessage={toastMessage}
        OffcanvasState={OffcanvasState}
        current_user_token={current_user_token}
      />

      <CancelRequestConfirmation
        showSignatureRequestCancel={showSignatureRequestCancel}
        setShowSignatureRequestCancel={setShowSignatureRequestCancel}
        onSignatureRequestCancelConfirm={handleSignatureRequestCancelConfirm}
      />

      {/* SHAREPFS - WIP - START */}
      <Modal
        dialogClassName="cs-md-modal"
        show={sharePFS.show}
        // show={props.showThirdModal}
        onHide={sharePFS.hide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Share PFS</Modal.Title>
          {/* <CloseButton onClick={() => sharePFSModal(false, true)} /> */}
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <Form.Group className="cs-form-group">
                  <Form.Label className="">First name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={name}
                    className={name && "cs-input-field-active"}
                    onChange={handleNameChange}
                    maxLength={40}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {validationMsg1}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastName"
                    autoComplete="off"
                    value={lastName}
                    className={lastName && "cs-input-field-active"}
                    onChange={handleLastNameChange}
                    maxLength={40}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {validationMsgLastName}
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    autoComplete="off"
                    value={email}
                    onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
                    className={email && "cs-input-field-active"}
                    onChange={handleEmailChange}
                  />

                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {validationMsg}
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="cs-form-group last-email-field">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    className={
                      notes && "cs-textarea"
                        ? "cs-textarea cs-input-field-active"
                        : "cs-textarea"
                    }
                    type="text"
                    autoComplete="off"
                    placeholder="Optional"
                    onChange={handleNotesChange}
                    // className="cs-textarea"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              onClick={() => sharePFSModal(false, true)}
              className="cs-btn-secondary md-btn default-regular-h5"
              // className="wm-req-sharepfs-cancelbtn "
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              type="button"
              onClick={handleSubmitSec}
              variant="primary"
              disabled={!name || !email || !lastName}
            >
              Review & Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPFSPreview}
        sentData={sentData}
        link={linkk}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="pfs-pdf-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h1 cs-neutral-100">
            PFS Preview
          </Modal.Title>
          {/* <CloseButton onClick={handleClosePFSPreview} /> */}
        </Modal.Header>

        <Modal.Body>
          {/* <iframe src={URL} width="450" height="800"></iframe> */}
          <div className="pfs-pdf-box wm-req-pdf-container">
            <iframe
              src={URL}
              width="100%"
              height="100%"
              title="PDF Viewer"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              onClick={handleClosePFSPreview}
              className="cs-btn-secondary md-btn default-regular-h5"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              type="button"
              onClick={() => handleSubmit(sentData, linkk)}
              variant="primary"
            >
              Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* SHAREPFS - WIP - END */}
      {props.is_loader.assets === true ||
      props.is_loader.liabilities === true ? (
        <SkeletonRequest />
      ) : (
        <section className="request-main-section">
          <Container fluid>
            <section className="cs-section-topbar">
              <Row>
                <Col>
                  <div className="cs-title">
                    <h1 className="default-semi-bold-h1 cs-neutral-100">
                      {commonNames.Requests}
                    </h1>
                  </div>
                  <div className="cs-tabs-flex">
                    <div className="cs-tabs">
                      {tabs.map((tabs, id) => (
                        <Button
                          className={`cs-stroke-default-tab cs-tabs-height default-regular-h5 ${
                            tabValue === tabs.value ? "cs-stroke-active" : ""
                          }`}
                          key={id}
                          onClick={() => handleTabChange(tabs)}
                        >
                          {tabs.name}
                        </Button>
                      ))}
                    </div>
                    {/* <div className="cs-search-bar">
                        <Form.Group className="cs-form-group">
                          <Form.Control
                            type="text"
                            placeholder="Search..."
                            icon={img}
                            value={search}
                            // onChange={(event) => setSearch(event.target.value)}
                            onChange={(event) =>
                              searchIndividual(event.target.value)
                            }
                          />
                          <span className="input-field-icon cs-neutral-70">
                            <CapsyncIcon title="search-outlined" size="18" />
                          </span>
                        </Form.Group>
                      </div> */}
                  </div>
                </Col>
              </Row>
            </section>
            <div className="signature-request-from-box">
              <div className="cs-requests-tables">
                <h4 className="default-medium-sub-heading-m cs-font-color-sub-title">
                  Signature request received
                </h4>
                <SignatureFrom
                  tabValue={tabValue}
                  user_email={user_email}
                  filteredIndividuals={filteredIndividuals}
                  onSignatureDetails={handleSignatureDetailsClick}
                  sharePFSModal={sharePFSModal}
                  handleDownload={handleDownload}
                  // shareicon={shareicon}
                  // DarkDownloadIcon={DarkDownloadIcon}
                  status={status}
                  signatureFrom={signatureRequest.from}
                  requestPageType="Signature From"
                  rowsPerPage={rowsPerPageSignatureFrom}
                  rowStartIdx={rowStartIdxSignatureFrom}
                />
                {signatureRequest.from &&
                  fetchSignatureFrom(signatureRequest.from, status, true)
                    .length > 0 && (
                    <Pagination
                      rowStartIdx={rowStartIdxSignatureFrom}
                      setRowStartIdx={setRowStartIdxSignatureFrom}
                      rowsPerPage={rowsPerPageSignatureFrom}
                      setRowsPerPage={setRowsPerPageSignatureFrom}
                      paginationDropdownItems={paginationDropdownItems}
                      signatureRequest={signatureRequest.from}
                      paginationList={
                        tabValue === "1"
                          ? signatureRequest.from.filter(
                              (data) => data.is_completed === false
                            )
                          : signatureRequest.from.filter(
                              (data) => data.is_completed === true
                            )
                      }
                    />
                  )}
              </div>
              <div className="cs-requests-tables">
                <h4 className="default-medium-sub-heading-m cs-font-color-sub-title">
                  Signature request sent
                </h4>
                <SignatureTo
                  tabValue={tabValue}
                  user_email={user_email}
                  filteredIndividuals={props.filteredIndividuals}
                  onSignatureDetails={handleSignatureDetailsClick}
                  sharePFSModal={sharePFSModal}
                  handleDownload={handleDownload}
                  // shareicon={shareicon}
                  // DarkDownloadIcon={DarkDownloadIcon}
                  status={status}
                  signatureTo={signatureRequest.to}
                  requestPageType="Signature To"
                  rowsPerPage={rowsPerPageSignatureTo}
                  rowStartIdx={rowStartIdxSignatureTo}
                />
                {signatureRequest.to &&
                  getSignatureTo(signatureRequest.to, status).length > 0 && (
                    <Pagination
                      rowStartIdx={rowStartIdxSignatureTo}
                      setRowStartIdx={setRowStartIdxSignatureTo}
                      rowsPerPage={rowsPerPageSignatureTo}
                      setRowsPerPage={setRowsPerPageSignatureTo}
                      paginationDropdownItems={paginationDropdownItems}
                      signatureRequest={signatureRequest.to}
                      paginationList={
                        tabValue === "1"
                          ? signatureRequest.to.filter(
                              (data) => data.is_completed === false
                            )
                          : signatureRequest.to.filter(
                              (data) => data.is_completed === true
                            )
                      }
                    />
                  )}
              </div>
              <div className="cs-requests-tables">
                <h4 className="default-medium-sub-heading-m cs-font-color-sub-title">
                  Account Access
                </h4>
                <RequestTable
                  tabValue={tabValue}
                  user_email={user_email}
                  filteredIndividuals={filteredIndividuals}
                  onSignatureDetails={handleSignatureDetailsClick}
                  sharePFSModal={sharePFSModal}
                  handleDownload={handleDownload}
                  // shareicon={shareicon}
                  // DarkDownloadIcon={DarkDownloadIcon}
                  status={status}
                  accountAccessList={accountAccess.individual}
                  rowsPerPage={rowsPerPageAccount}
                  rowStartIdx={rowStartIdxAccount}
                />
                {accountAccess.individual &&
                  requestStatusFilter(accountAccess.individual, status)
                    .length !== 0 && (
                    <Pagination
                      rowStartIdx={rowStartIdxAccount}
                      setRowStartIdx={setRowStartIdxAccount}
                      rowsPerPage={rowsPerPageAccount}
                      setRowsPerPage={setRowsPerPageAccount}
                      paginationDropdownItems={paginationDropdownItems}
                      signatureRequest={accountAccess.individual}
                      paginationList={
                        tabValue === "1"
                          ? accountAccess.individual.filter(
                              (data) => data.check_status === 3
                            )
                          : accountAccess.individual.filter(
                              (data) =>
                                data.check_status === 1 ||
                                data.check_status === 2
                            )
                      }
                    />
                  )}
              </div>
            </div>
          </Container>
        </section>
      )}

      <OffCanvas
        userEmail={user_email}
        OffcanvasState={OffcanvasState}
        setShowCanvas={setShowCanvas}
        showCanvas={showCanvas}
        jointUserEmail={jointUserEmail}
        HandleOpen={HandleOpen}
        users={user}
        socket={socket}
        toastMessage={toastMessage}
        status={status}
        onCancelRequestConfirmationClick={handleCancelRequestConfirmationClick}
        fetchAllNotifications={fetchAllNotifications}
        signatureFrom={signatureRequest.from}
        signatureTo={signatureRequest.to}
      />
    </>
  );
};
export default RequestDashboard;
