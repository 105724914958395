/* eslint-disable */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Table, Row, Col, Container, Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../../../individual/category.css";
import CategoryLineChart from "../../CategoryLineChart";
import commonNames from "../../../../config/commonNames";
import CapsyncToolTip from "../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import ManageFilesUpload from "../../../../commonComponent/ManageFilesUpload";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import { isValidFileName, setAddressString } from "../../../../util/common";
import { totalBalanceColumn } from "../../../../util/finance";
import SkeletonCategoryDashboard from "../category/SkeletonCategoryDashboard";
import { loadStripe } from "@stripe/stripe-js";
import {
  categoryLineChartCalculation,
  totalBalance,
} from "../../../../config/financeCalculation";
import {
  categoryDeleteRecord,
  categoryFilesRemove,
  categoryFilesUpdate,
  categoryFindRecord,
  categoryGetAll,
  categoryTotal,
  categoryFileListing,
  categoryUnlinkRecord,
  getAllTransactionsLoaderData,
  categoryTotalNew,
} from "../../../../slices/category.slice";
import {
  columnFindRecord,
  selectColumn,
} from "../../../../slices/columnFilter";
import {
  quickBooksReportCategory,
  tableHeader,
} from "../../../../config/categoryTable";
// import UpgradeStorageModal from "./fileManagement/UpgradeStorageModal";
import { Elements } from "@stripe/react-stripe-js";
import CategoryTableData from "../../../../commonComponent/CategoryTableData";
import {
  assetsCategory,
  categoryId,
  institutionColumn,
} from "../../../../config/finance";
import "../asset_dashboard/AssetDashboard.css";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import InitialSetup3 from "../../dashboard/InitialSetup/InitialSetup3";
import LinkModal from "../../../../commonComponent/modalPopup/LinkModal";
import Unlink from "../../../../commonComponent/modalPopup/UnlinkPopup";
import {
  amortizationCategory,
  portfolioCategory,
  transactionCategory,
  manageColumns,
} from "../../../../config/categoryTable";
import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import { socket } from "../../../../config/Socket";
import QuickBooksModal from "../../../../commonComponent/quickBooks/QuickBooksModal";
import UpgradeStorageModal from "../../fileManagement/UpgradeStorageModal";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";
import DownloadConfirmationModal from "../../fileManagement/DownloadConfirmationModal";

const CategoryDashboard = ({
  categoryData,
  CapsyncDeleteMessage,
  individualUserData,
  toastMessage,
  editAccessUser,
  errorAccounts,
  dataAsset,
  dataLiability,
  is_loader,
  plaidErrorAccounts,
}) => {
  const { category } = useParams();
  const { user } = useSelector((state) => state.auth);
  const categoryListDataTest = useSelector(
    (state) => state.category.categoryDashboardListing
  );
  const categoryTableData =
    (categoryListDataTest[category] && categoryListDataTest[category].data) ||
    [];
  const cashGetData =
    (categoryListDataTest[category] &&
      categoryListDataTest[category].chart_data) ||
    {};

  const categoryItemsID = useRef(null);

  const arrlength = categoryTableData && categoryTableData.length;
  const [modalShow, setModalShow] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [quickBooksModalShow, setQuickBooksModalShow] = useState(false);
  const [sequence, setSequence] = useState([]);
  const [showEditModal, setShowEditModal] = useState("");
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [isUpdateLinkedListData, setIsUpdateLinkedListData] = useState(true);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [storeFiles, listFiles] = useState([]);
  const [storeAllFiles, setAllFiles] = useState("");
  const [fileModal, setFileModal] = useState(false);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [downloadConfirmationModalOpen, setDownloadConfirmationModalOpen] =
    useState(false);
  const [isBigFile, setIsBigFile] = useState(false);
  const [downloadItem, setDownloadItem] = useState(null);
  const [categoryItem, setCategoryItem] = useState(null);
  const actionColumnData = {
    title: "Actions",
    class: "default-medium-sub-heading-m",
    width: "300",
    tooltip: false,
    filter: false,
    fieldName: "action",
    draggable: false,
    fieldType: "string",
  };
  const [isLoader, setIsloader] = useState({
    data: true,
    chart: true,
  });
  const [is_deleted, setIs_deleted] = useState(false);
  const [record, setRecord] = useState({});
  const [assetsColumnsList, setcategoryColumnsFilters] = useState([]); //column data from config with filter
  const [categoryItemId, setCategoryItemId] = useState(null);

  const [categoryColumnsFilters, setCategoryColumnsFilters] = useState(
    tableHeader.assets[category]
  );
  const [storedColumns, setStoredColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [fetchTypeCategory, setFetchTypeCategory] = useState({
    type: 0,
    category: "Assets",
  });
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isTransactionLoader, setIsTransactionLoader] = useState(false);
  const [displayFile, setDisplayFile] = useState([]);
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const [deletedFileDisable, setDeleteFileDisable] = useState([]);
  const [linkId, setLinkId] = useState({
    address: "",
    id: "",
  });
  let id,
    pfs_access_id = 0;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
    pfs_access_id = id;
  } else {
    id = user && user.user.id;
  }

  //----------------***************-----------------//
  const getUserStorageFunction = async () => {
    dispatch(get_user_storage({ id })).unwrap();
  };

  useEffect(() => {
    categoryItemsID.current = categoryItemId;
  }, [categoryItemId]);

  useEffect(() => {
    getUserStorageFunction();
    const columnArr = tableHeader.assets[category];
    setcategoryColumnsFilters(columnArr);
    fetchColumns();

    socket.on("investmentTransactionsLoaderUpdated", (msg) => {
      getTableList();
    });

    socket.on("transaction_loader_updated", (msg) => {
      getTableList();
      // clearTimeout(timeout)
      // timeout = setTimeout(() => {
      //   getUpdatedTransactionsLoaderData()
      // }, 2000)
    });

    return () => {
      socket.off("investmentTransactionsLoaderUpdated");
      socket.off("transaction_loader_updated");
    };
  }, []);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const getUpdatedTransactionsLoaderData = () => {
    const ins_ids = [];
    for (let i = 0; i < categoryTableData.length; i++) {
      if (categoryTableData[i].institution_id) {
        ins_ids.push(categoryTableData[i].institution_id);
      }
    }
    dispatch(
      getAllTransactionsLoaderData({ user_id: id, ins_ids, category })
    ).unwrap();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let selectedColumnsArray = sequence
      .filter((item) => item.isChecked)
      .map((item) => item.name);
    setSelectedColumns([...selectedColumnsArray]);
    const sortedCategoryColumnFilters = sortDataBySequence(
      categoryColumnsFilters,
      sequence
    );
    setCategoryColumnsFilters(sortedCategoryColumnFilters);
  }, [sequence]);

  useEffect(() => {
    if (
      ["retirementaccount", "nonretirementbrokerage"].includes(category) &&
      categoryTableData.length
    ) {
      setIsTransactionLoader(
        !!categoryTableData.find((data) => data.transaction_loader === true)
      );
    }
  }, [categoryTableData]);

  const getTotalCount = (category, index, selectedColumns) => {
    switch (true) {
      case index &&
        ["savingaccount", "cashonhand", "nonretirementbrokerage"].includes(
          category
        ):
        return totalBalance(categoryTableData, "share_value");

      case selectedColumns === "market_value" &&
        ["personalproperty", "otherassets", "automobile"].includes(category):
        return totalBalance(categoryTableData, "market_value");

      case selectedColumns === "total" &&
        ["retirementaccount"].includes(category):
        return totalBalance(categoryTableData, "total");

      case selectedColumns === "balance_due" &&
        ["accountnotesreceviable"].includes(category):
        return totalBalance(categoryTableData, "balance_due");

      case selectedColumns === "share_market_value" &&
        ["realestate", "business"].includes(category):
        return totalBalance(categoryTableData, "share_market_value");

      case selectedColumns === "cash_value" &&
        ["lifeinsurance"].includes(category):
        return totalBalance(categoryTableData, "cash_value");

      default:
        return null;
    }
  };

  useEffect(() => {
    getTableList();
  }, [categoryData]);

  useEffect(() => {
    if (fileModal) {
      setDuplicateFileError("");
    }
  }, [fileModal]);

  const dispatch = useDispatch();

  //------for add and upadate account button---------//
  const modelData = (title, id) => {
    setRecord({});
    dispatch(
      categoryFindRecord({
        id: id,
        category: category,
        main_category: "assets",
      })
    )
      .unwrap()
      .then((response) => {
        setRecord(response);
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
    // setModalTitle(title);
    setShowEditModal(true);

    setFetchTypeCategory({
      type: fetchCatData.id,
      category: "Assets",
    });
    // setModalShow(true);
  };

  //----------------For data listing----------------//
  const getTableList = () => {
    dispatch(
      categoryGetAll({ id: id, category: category, main_category: "assets" })
    )
      .unwrap()
      .then((response) => {
        setIsloader((prev) => ({ ...prev, chart: false, data: false }));
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  //----------------For delete table record-----------------//
  const dashboardAPi = () => {
    dispatch(categoryTotalNew({ id: id }))
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteRecord = (id) => {
    CapsyncDeleteMessage(true, "Delete", "", () => deleteFunc(id));
    dashboardAPi();
  };

  function deleteFunc(id) {
    let payload = {
      id: id,
      pfs_access_id: pfs_access_id,
      category: category,
      main_category: "assets",
    };

    dispatch(categoryDeleteRecord(payload))
      .unwrap()
      .then(() => {
        getTableList();
        setTimeout(() => {
          errorAccounts();
          toastMessage(
            "Your record has been deleted successfully. ",
            "success"
          );
        }, 1000);
      })
      .catch((err) => {
        setModalShow(false);
        toastMessage(err.message, "error");
      });
  }

  const OnLinkButtonClick = (id) => {
    const addressObj = categoryTableData.find((addr) => addr.id === id);
    setLinkId({
      address: addressObj.address || null,
      id,
    });
    setShowLinkModal(true);
  };

  const linkFunc = () => {
    getTableList();
    toastMessage("You have linked this asset successfully.", "success");
    setShowLinkModal(false);
    setLinkId({
      address: "",
      id: "",
    });
  };

  const OnUnlinkButtonClick = (id) => {
    setLinkId({
      address: "",
      id,
    });
    setShowUnlinkModal(true);
  };

  function UnlinkFunc() {
    setShowUnlinkModal(false);
    let payload = {
      id: linkId.id,
      category: category,
      main_category: "assets",
    };

    dispatch(categoryUnlinkRecord(payload))
      .unwrap()
      .then(() => {
        getTableList();
        toastMessage("You have unlinked this asset successfully. ", "success");
        setLinkId({
          address: "",
          id: "",
        });
      })
      .catch((err) => {
        setModalShow(false);
        toastMessage(err.message, "error");
      });
  }

  const manageFilesList = (showModal, itemId) => {
    setFileModal(showModal);
    if (!showModal) {
      listFiles([]);
      return;
    }
    dispatch(
      categoryFileListing({
        formdata: {
          category_id: categoryId.asset[category],
          row_id: itemId,
          user_id: id,
        },
        category: category,
        main_category: "assets",
      })
    )
      .unwrap()
      .then((response) => {
        categoryItemsID.current = itemId;
        setOriginalFiles(response.map((x) => x.original_file_name));
        let storeDetails = response.map((x) => ({
          url: x.attachments,
          name: x.original_file_name,
          uploadDate: x.createdAt,
          id: x.id,
          is_scan_file: x.is_scan_file,
          file_size: x.file_size,
        }));
        const allFiles = response.map((x) => x.original_file_name).join(",");
        setAllFiles(allFiles);
        listFiles(storeDetails);
      })
      .catch((err) => {});
    setDeleteFileDisable([]);
  };

  function fileAttachment(params) {
    let storeDetails = params.map((x) => ({
      url: x.attachments,
      name: x.original_file_name,
      uploadDate: x.createdAt,
      id: x.id,
      is_scan_file: x.is_scan_file,
      file_size: x.file_size,
    }));
    const allFiles = params.map((x) => x.original_file_name).join(",");
    setAllFiles(allFiles);
    listFiles(storeDetails);
  }

  // const storePlaidMask = PlaidMask();
  //---------------------- Manage Files ----------------------//

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setDisplayFile(acceptedFiles);
      if (!validateDuplicateFileName(acceptedFiles, originalFiles)) {
        acceptedFiles.length = 0;
        return;
      }

      setIs_deleted(true);
      const emptyfileArr = [];
      acceptedFiles.forEach((file) => {
        emptyfileArr.push(file);
      });
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;
      const used_space = storageData.usedSpace + inProgressData;
      const formdata = new FormData();
      dispatch(
        categoryFindRecord({
          id: categoryItemsID.current,
          category: category,
          main_category: "assets",
        })
      )
        .unwrap()
        .then((res) => {
          formdata.append(
            "institution",
            res[institutionColumn.asset[category]]
          );
          formdata.append("user_id", res.user_id);
          formdata.append("used_space", used_space);
          formdata.append("row_id", categoryItemsID.current);
          for (let i = 0; i < emptyfileArr.length; i++) {
            formdata.append("attachments", emptyfileArr[i]);
          }
          let newData = {
            formdata: formdata,
            category: category,
            main_category: "assets",
          };
          dispatch(categoryFilesUpdate(newData))
            .unwrap()
            .then(async (response) => {
              getUserStorageFunction();
              // await dispatch(get_user_storage({ id })).unwrap();
              if (response.code == 500) {
                setDisplayFile([]);
                setDuplicateFileError(`${response.message}`);
                setShowUpgradeModal(true);
                setFileModal(false);
              }
              fileAttachment(response.data);
              setOriginalFiles(response.data.map((x) => x.original_file_name));
              getTableList();
              setIs_deleted(false);
              acceptedFiles.length = 0;
              toastMessage(
                "Your file has been uploaded successfully.",
                "success"
              );
            })
            .catch((err) => {
              setIs_deleted(false);
              // toastMessage(err.message, "error");
            });
        })
        .catch((err) => {});
    },
    [originalFiles, storageData]
  );

  const validateDuplicateFileName = (newFiles, originalFiles) => {
    const maxFileSize =
      process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT; // 10MB in bytes
    const supportedExtensions = [".pdf", ".png", ".jpg", ".jpeg"];
    let totalNewFileSize = 0;

    for (const newFile of newFiles) {
      // if (!isValidFileName(newFile.name)) {
      //   setDuplicateFileError(
      //     'Special characters ([, <. , {, ^, }, %, `, ], ", >, ~, #, |, Non-printable ASCII characters) are not allowed in file name.'
      //   );
      //   return false;
      // }
      if (originalFiles.includes(newFile.name)) {
        setDuplicateFileError("A file with this name already exists");
        return false;
      }
      // Check for maximum file size
      if (newFile.size && newFile.size > maxFileSize) {
        setDuplicateFileError(
          `This file exceeds maximum file size limit (${
            maxFileSize / 1048576
          } MB)`
        );
        return false;
      }
      //check file extension
      const fileExtension = newFile.name.slice(
        ((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      const notAllowedExtensionHandling =
        fileExtension === "" ||
        fileExtension === null ||
        fileExtension === undefined;
      if (
        notAllowedExtensionHandling ||
        !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setDuplicateFileError("Format not supported");
        return false;
      }
      totalNewFileSize += newFile.size;
    }
    if (totalNewFileSize > maxFileSize) {
      setDuplicateFileError(
        `This file exceeds maximum file size limit (${
          maxFileSize / 1048576
        } MB)`
      );
      return false;
    }
    const uploadedData = storageData && storageData.usedSpace;
    const maxLimitData = storageData && storageData.maxSpace;
    if (uploadedData + totalNewFileSize > maxLimitData) {
      setShowUpgradeModal(true);
      setFileModal(false);
      setDisplayFile([]);
      setIs_deleted(false);
      return false;
    }
    setDuplicateFileError("");
    return true;
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: is_deleted,
    onDropAccepted,
  });

  const removeFile = (params, row_id) => {
    setIs_deleted(true);

    setDeleteFileDisable((pre) => [...pre, row_id]);

    dispatch(
      categoryFilesRemove({
        catRowId: categoryItemId,
        row_id: row_id,
        user_id: id,
        fileName: params,
        category: category,
        main_category: "assets",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          getUserStorageFunction();
          setIs_deleted(false);
          fileAttachment(response.data);
          setOriginalFiles(response.data.map((x) => x.original_file_name));
          getTableList();
          toastMessage("Your file has been deleted successfully.", "success");
          setDuplicateFileError("");
        }
      })
      .catch((err) => {
        setIs_deleted(false);
        toastMessage(err.message, "error");
      });
  };

  function checkFieldNameExist(category, sequence) {
    const categoryFieldName = category.map((item) => item.fieldName);
    const sequenceFieldName = sequence.map((item) => item.name);
    return categoryFieldName.every((title) =>
      sequenceFieldName.includes(title)
    );
  }

  const fetchColumns = () => {
    dispatch(
      columnFindRecord({
        id: id,
        categoryNames: category,
        category_type: "assets",
      })
    )
      .unwrap()
      .then((response) => {
        if (
          response &&
          response.columns_name &&
          checkFieldNameExist(categoryColumnsFilters, response.columns_name)
        ) {
          setSequence(response.columns_name);
        } else {
          changeManageColumnsFilter({
            firstTimeUserSequence: categoryColumnsFilters,
          });
        }
      })
      .catch((err) => {});
  };

  const onHandleClose = (key = "link", isUpdate = false) => {
    if (key === "link") {
      setShowLinkModal(false);
      setLinkId({
        address: "",
        id: "",
      });
    } else if (key === "unlink") {
      setShowUnlinkModal(false);
      setLinkId({
        address: "",
        id: "",
      });
    } else if (key === "addCategory") {
      setShowAddCategoryModal(false);
      isUpdate === true && setIsUpdateLinkedListData(!isUpdateLinkedListData);
    }
  };

  const onHandleAddCategory = () => {
    setShowAddCategoryModal(true);
  };

  const lineData = categoryLineChartCalculation(cashGetData, "Assets");

  let fetchCatData = assetsCategory.find((x) => x.url === category);

  const changeManageColumnsFilter = ({
    items,
    columnName,
    firstTimeUserSequence,
  }) => {
    const sequenceMap = {};
    sequence.forEach((item, index) => {
      sequenceMap[item.name] = item.isChecked;
    });
    let newSequence = [];
    if (items) {
      items.map((categoryColumn, index) => {
        newSequence.push({
          name: categoryColumn.fieldName,
          isChecked: sequenceMap[categoryColumn.fieldName],
        });
      });
    } else if (firstTimeUserSequence) {
      firstTimeUserSequence.map((categoryColumn, index) => {
        newSequence.push({ name: categoryColumn.fieldName, isChecked: true });
      });
    } else {
      newSequence = [...sequence];
    }
    if (columnName) {
      newSequence.map((categoryColumn, index) => {
        if (categoryColumn.name === columnName) {
          newSequence[index].isChecked = !newSequence[index].isChecked;
        }
      });
    }

    setSequence(newSequence);
    dispatch(
      selectColumn({
        user_id: id,
        category_name: category,
        columns_name: newSequence, //modifyColumns.length === 0 ? '' : modifyColumns.join(','),
        category_type: "assets",
      })
    )
      .unwrap()
      .then((response) => {})
      .catch((err) => {});
  };

  let columnCount = selectedColumns.length;
  const isAmortization =
    amortizationCategory.find((item, index) => item.type === category) !==
    undefined
      ? true
      : false;
  const isTransaction =
    transactionCategory.find((item, index) => item.type === category) !==
    undefined
      ? true
      : false;
  const isPortfolio =
    portfolioCategory.find((item, index) => item.type === category) !==
    undefined
      ? true
      : false;
  const isManageColumns =
    manageColumns.find((item, index) => item.type === category) !== undefined
      ? true
      : false;
  const isQuickBook =
    quickBooksReportCategory.find((item, index) => item.type === category) !==
    undefined
      ? true
      : false;

  columnCount = columnCount + 1;
  const tableFooterRow = totalBalanceColumn(columnCount);

  const isChartLoader =
    ["retirementaccount", "nonretirementbrokerage"].includes(category) &&
    isLoader.chart &&
    isLoader.data;

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(categoryColumnsFilters);
    if (
      result.destination.index === items.length ||
      !items[result.destination.index].draggable
    )
      return;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    changeManageColumnsFilter({ items: items });
    setCategoryColumnsFilters(items);
  }

  function sortDataBySequence(data, sequence) {
    const sequenceMap = {};
    sequence.forEach((item, index) => {
      sequenceMap[item.name] = index;
    });

    data.sort((a, b) => {
      const indexA = sequenceMap[a.fieldName];
      const indexB = sequenceMap[b.fieldName];
      return indexA - indexB;
    });

    return data;
  }

  function changeQuickBookModal(params, business_id) {
    setQuickBooksModalShow(params);
    if (business_id === 0) {
      window.localStorage.removeItem("business_id");
    } else {
      window.localStorage.setItem("business_id", business_id);
    }
  }

  return (
    <>
      <ManageFilesUpload
        changeStatus={manageFilesList}
        fileModal={fileModal}
        storeFiles={storeFiles}
        storeAllFiles={storeAllFiles}
        getTableList={getTableList}
        toastMessage={toastMessage}
        fileAttachment={fileAttachment}
        user_id={id}
        acceptedFiles={displayFile}
        getRootProps={getRootProps}
        deletedFileDisable={deletedFileDisable}
        getInputProps={getInputProps}
        removeFile={removeFile}
        is_deleted={is_deleted}
        category={category}
        main_category={"assets"}
        categoryItemId={categoryItemId}
        duplicateFileError={duplicateFileError}
        setDownloadConfirmationModalOpen={setDownloadConfirmationModalOpen}
        setIsBigFile={setIsBigFile}
        setDownloadItem={setDownloadItem}
        setCategoryItem={setCategoryItem}
        setCategoryItemId={setCategoryItemId}
      />

      {downloadConfirmationModalOpen && (
        <DownloadConfirmationModal
          downloadConfirmationModalOpen={downloadConfirmationModalOpen}
          downloadItem={downloadItem}
          categoryItem={categoryItem}
          isBigFile={isBigFile}
          closeModal={() => {
            setDownloadConfirmationModalOpen(false);
            setCategoryItem(null);
            setDownloadItem(null);
            setIsBigFile(false);
          }}
        />
      )}

      {is_loader.assets === true ||
      is_loader.liabilities === true ||
      (dataAsset &&
        dataAsset.grandTotal == "0.00" &&
        dataLiability &&
        dataLiability.grandTotal == "0.00") ? (
        <SkeletonCategoryDashboard />
      ) : (
        <>
          <section className="cs-category-main-section">
            <Container fluid>
              <div className="cs-section-topbar">
                <CapsyncSectionHeader
                  mainTitle="Assets"
                  subTitle={fetchCatData.title}
                  mainUrl="/individual/assets"
                  pagetitle={fetchCatData.title}
                  individualUserData={individualUserData}
                  getTableList={getTableList}
                />
              </div>
              {isChartLoader ? (
                <CapsyncLoader />
              ) : (
                <React.Fragment>
                  <div className="category-line-chart-container">
                    <Row>
                      <Col>
                        <CategoryLineChart
                          lineData={lineData}
                          categoryName="Assets"
                          datas={categoryTableData}
                          isLoading={isLoader.chart}
                          isTransactionLoading={isTransactionLoader}
                        />
                      </Col>
                    </Row>
                  </div>
                  {isManageColumns && editAccessUser && (
                    <Row>
                      <Col>
                        <div className="category-manage-row">
                          <div className="cs-common-add-dropdown">
                            <div className="custom-dropdown" ref={dropdownRef}>
                              <button
                                className="default-regular-h5 xl-btn cs-btn-icon-primary"
                                onClick={() => setIsOpen(!isOpen)}
                              >
                                <span className="cs-neutral-10">
                                  <CapsyncIcon
                                    title="filter-filled"
                                    size="18"
                                  />
                                </span>
                                Manage Columns
                              </button>
                              {isOpen && (
                                <div className="custom-dropdown-menu">
                                  <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters">
                                      {(provided) => (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          className="manage-columns-items"
                                        >
                                          {[
                                            ...categoryColumnsFilters,
                                            actionColumnData,
                                          ].map((filterColumn, index) => (
                                            <Draggable
                                              key={filterColumn.fieldName}
                                              draggableId={
                                                filterColumn.fieldName
                                              }
                                              index={index}
                                              isDragDisabled={
                                                !filterColumn.draggable
                                              }
                                            >
                                              {(provided) => (
                                                <li
                                                  {...provided.draggableProps}
                                                  ref={provided.innerRef}
                                                  key={index}
                                                  className="manage-column-item"
                                                >
                                                  <div className="cs-form-check-box">
                                                    <span
                                                      className={`${
                                                        !filterColumn.draggable
                                                          ? "cs-disabled"
                                                          : "cs-neutral-100"
                                                      }`}
                                                      {...provided.dragHandleProps}
                                                    >
                                                      <CapsyncIcon
                                                        title="order-selector-filled"
                                                        size="18"
                                                      />
                                                    </span>
                                                    <Form.Check
                                                      inline
                                                      className={`default-light-body-text-m cs-neutral-100 ${
                                                        !filterColumn.filter &&
                                                        "cs-disabled"
                                                      }`}
                                                      label={filterColumn.title}
                                                      type="checkbox"
                                                      id={`inline-checkbox-${index}`}
                                                      disabled={
                                                        !filterColumn.filter
                                                      }
                                                      onChange={() =>
                                                        changeManageColumnsFilter(
                                                          {
                                                            columnName:
                                                              filterColumn.fieldName,
                                                          }
                                                        )
                                                      }
                                                      checked={
                                                        selectedColumns.includes(
                                                          filterColumn.fieldName
                                                        ) ||
                                                        filterColumn.fieldName ===
                                                          "action"
                                                      }
                                                    />
                                                  </div>
                                                </li>
                                              )}
                                            </Draggable>
                                          ))}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <div className="category-cs-table-container">
                    <Row>
                      <Col>
                        {selectedColumns &&
                        selectedColumns.length !== 0 &&
                        categoryTableData &&
                        categoryTableData.length > 0 ? (
                          <Table className={`cs-table ${category}-table`}>
                            <thead className="cs-thead">
                              <tr>
                                {categoryColumnsFilters.map((itr, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {selectedColumns.includes(
                                        itr.fieldName
                                      ) && (
                                        <th
                                          className={itr.class}
                                          width={itr.width}
                                        >
                                          {itr.title}
                                          {itr.tooltip && (
                                            <CapsyncToolTip
                                              Child={"info-outlined"}
                                              placement={"top"}
                                              message={"Adjust % as required."}
                                              type="icon"
                                              size={22}
                                            />
                                          )}
                                        </th>
                                      )}
                                    </Fragment>
                                  );
                                })}
                                <th
                                  className="default-medium-sub-heading-m"
                                  width={284}
                                >
                                  {editAccessUser && commonNames.Actions}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="cs-tbody default-regular-sub-heading-m">
                              {categoryTableData.map(
                                (assetsDisplay, indexDisplay) => {
                                  return (
                                    <tr key={indexDisplay}>
                                      {categoryColumnsFilters.map(
                                        (assetRowField, index) => {
                                          const { fieldType, fieldName } =
                                            assetRowField;

                                          let linkedTooltip = "";
                                          let displayValue =
                                            fieldName === "date_updated"
                                              ? moment(
                                                  assetsDisplay[fieldName]
                                                ).format("MM/DD/YYYY")
                                              : assetsDisplay[fieldName];
                                          if (fieldName === "ownership") {
                                            displayValue = displayValue + "  %";
                                          }
                                          if (fieldName === "address") {
                                            displayValue =
                                              setAddressString(displayValue);
                                          }
                                          if (
                                            assetRowField &&
                                            assetRowField.isLinkedTooltip
                                          ) {
                                            if (
                                              category === "realestate" &&
                                              assetsDisplay &&
                                              assetsDisplay.liability_real_estate_mortgage &&
                                              assetsDisplay
                                                .liability_real_estate_mortgage
                                                .creditor_name
                                            ) {
                                              linkedTooltip =
                                                assetsDisplay
                                                  .liability_real_estate_mortgage
                                                  .creditor_name;
                                            } else if (
                                              category === "automobile" &&
                                              assetsDisplay &&
                                              assetsDisplay.liability_automobile &&
                                              assetsDisplay.liability_automobile
                                                .description
                                            ) {
                                              linkedTooltip =
                                                assetsDisplay
                                                  .liability_automobile
                                                  .description;
                                            }
                                          }

                                          return (
                                            <React.Fragment key={index}>
                                              {selectedColumns.includes(
                                                assetRowField.fieldName
                                              ) && (
                                                <td
                                                  className={
                                                    fieldName === "ownership"
                                                      ? "text-end"
                                                      : ""
                                                  }
                                                  width={assetRowField.width}
                                                >
                                                  <CategoryTableData
                                                    component={displayValue}
                                                    type={fieldType}
                                                    classValue={`${
                                                      fieldName === "ownership"
                                                        ? "text-end"
                                                        : ""
                                                    }`}
                                                    item={assetsDisplay}
                                                    fieldName={fieldName}
                                                    linkedTooltip={
                                                      linkedTooltip
                                                    }
                                                    category={category}
                                                    mainCategory={"assets"}
                                                    is_display_tooltip={
                                                      category ===
                                                        "realestate" &&
                                                      assetsDisplay.is_manual
                                                        ? true
                                                        : assetsDisplay.is_display_tooltip
                                                    }
                                                    rowFieldIndex={index}
                                                    plaidErrorAccounts={
                                                      plaidErrorAccounts
                                                    }
                                                  />
                                                </td>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                      <td>
                                        <div className="other-action-td">
                                          {editAccessUser && (
                                            <CategoryTableData
                                              type="action"
                                              // changeStatus={changeStatus}
                                              changeStatus={(e, id) => {
                                                manageFilesList(e, id);
                                                setCategoryItemId(
                                                  assetsDisplay.id
                                                );
                                              }}
                                              modelData={modelData}
                                              deleteRecord={deleteRecord}
                                              item={assetsDisplay}
                                              isLinkToggleVIsible={[
                                                "realestate",
                                                "automobile",
                                              ].includes(category)}
                                              linkType={
                                                category === "realestate"
                                                  ? "liability_real_estate_mortgage"
                                                  : "liability_automobile"
                                              }
                                              // item={assetsDisplay}
                                              OnLinkButtonClick={
                                                OnLinkButtonClick
                                              }
                                              OnUnlinkButtonClick={
                                                OnUnlinkButtonClick
                                              }
                                              plaidErrorAccounts={
                                                plaidErrorAccounts
                                              }
                                            />
                                          )}
                                          {![
                                            "realestate",
                                            "automobile",
                                          ].includes(category) && (
                                            <CategoryTableData
                                              type={"other_actions"}
                                              item={assetsDisplay}
                                              main_category="assets"
                                              category={category}
                                              isAmortization={isAmortization}
                                              isPortfolio={isPortfolio}
                                              isQuickBook={isQuickBook}
                                              is_manual={
                                                assetsDisplay.is_manual
                                              }
                                              isTransaction={isTransaction}
                                              isTransactionEnable={true}
                                              transactionLoader={
                                                assetsDisplay.transaction_loader
                                                  ? assetsDisplay.transaction_loader
                                                  : assetsDisplay.is_transaction_loader
                                                  ? assetsDisplay.is_transaction_loader
                                                  : false
                                              }
                                              arrlength={arrlength}
                                              changeQuickBookModal={
                                                changeQuickBookModal
                                              }
                                              isRetirementTransactionLoader={
                                                assetsDisplay.transaction_loader
                                                  ? assetsDisplay.transaction_loader
                                                  : false
                                              }
                                            />
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr className="cs-tfoot-assets default-medium-sub-heading-m">
                                {tableFooterRow.map((column, index) => {
                                  const setFromLast = 3;
                                  const totalColumn = tableFooterRow;
                                  const lastColumnIndx =
                                    totalColumn.length - setFromLast === index;
                                  // const columnIndexBrokerage = index === 1;
                                  // const isDisplayBalance = columnIndexBrokerage && ["nonretirementbrokerage"].includes(category);

                                  return (
                                    <td
                                      className={
                                        category
                                          ? category === "automobile"
                                            ? "automobile-market"
                                            : "share-value-th"
                                          : ""
                                      }
                                      key={index}
                                    >
                                      {index === 0 && commonNames.Total}
                                      {getTotalCount(
                                        category,
                                        lastColumnIndx,
                                        selectedColumns[column - 1]
                                      ) && (
                                        <CategoryTableData
                                          type="number"
                                          component={getTotalCount(
                                            category,
                                            lastColumnIndx,
                                            selectedColumns[column - 1]
                                          )}
                                        />
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </Table>
                        ) : (
                          <span className="cs-common-no-record">
                            There are no records to display
                          </span>
                        )}

                        {/* {categoryTableData && categoryTableData.length === 0 && (<span className="cs-common-no-record">There are no records to display</span>
                      )} */}
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              )}
            </Container>
          </section>
        </>
      )}

      <QuickBooksModal
        show={quickBooksModalShow}
        modalState={changeQuickBookModal}
        connect={true}
      />
      {showEditModal && record && (
        <InitialSetup3
          fetchTypeCategory={fetchTypeCategory}
          record={record}
          setShowEditModal={setShowEditModal}
          showModal={showEditModal}
        />
      )}
      {showAddCategoryModal && (
        <InitialSetup3
          fetchTypeCategory={
            category === "realestate"
              ? {
                  type: 5,
                  category: "liabilities",
                }
              : {
                  type: 19,
                  category: "liabilities",
                }
          }
          setShowEditModal={setShowAddCategoryModal}
          showModal={showAddCategoryModal}
          onHnadleClose={(type) => onHandleClose("addCategory", type)}
          linkAddressObj={linkId.address}
        />
      )}
      <Elements stripe={stripePromise}>
        <UpgradeStorageModal
          setShowUpgradeModal={setShowUpgradeModal}
          setFileModal={setFileModal}
          showUpgradeModal={showUpgradeModal}
          showLimitModal={true}
          // onSuccess={fetchMainList}
          storage={storageData}
        />
      </Elements>

      {showLinkModal && (
        <LinkModal
          modalShow={showLinkModal}
          usrId={id}
          category={category}
          updateCategory={
            category === "realestate"
              ? "mortgagesonrealestate"
              : "installmentsauto"
          }
          endPointAPI={
            category === "realestate"
              ? "liability_real_estate_mortgages_external_manual"
              : "liability_automobiles_external_manual"
          }
          mainCategory={"assets"}
          updateMainCategory={"liabilities"}
          displayCategory={"Assets"}
          toastMessag={toastMessage}
          descType={"creditor_name"}
          descDisplayType={"Creditor Name"}
          numType={"balance_due"}
          // numDisplayType={category === "realestate" ? 'Total Balance' : 'Balance Due'}
          numDisplayType={"Balance Due"}
          id={linkId.id}
          idType={
            category === "realestate" ? "real_estate_id" : "automobile_id"
          }
          linkIdType={
            category === "realestate"
              ? "real_estate_mortgage_id"
              : "liability_automobile_id"
          }
          closeModal={() => onHandleClose("link")}
          addLinkSuccess={linkFunc}
          onHandleAddCategory={onHandleAddCategory}
          isUpdateData={isUpdateLinkedListData}
        />
      )}
      {showUnlinkModal && (
        <Unlink
          modalShow={showUnlinkModal}
          modalFunc={() => onHandleClose("unlink")}
          removeLink={UnlinkFunc}
          tableHeader={
            categoryColumnsFilters && categoryColumnsFilters[0].title
          }
        />
      )}
    </>
  );
};

export default CategoryDashboard;
