import React, { useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import CapsyncToolTip from "./../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { customStyles, formatPhoneNumber } from "../../../util/common";
import Pagination from "../../../commonComponent/Pagination";
const ManageUserTable = ({ data, onClick, deleteRecord, mainTabName }) => {
  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        const fullName = row.to_firstName + " " + row.to_lastName;
        const isLong = fullName.length > 20;
        return isLong ? (
          <span className="cs-ellipsis">
            <CapsyncToolTip
              Child={fullName}
              placement={"top"}
              size="18"
              message={fullName}
              type="text"
            />
          </span>
        ) : (
          fullName
        );
      },
    },
    {
      name: "Phone",
      cell: (row) =>
        row.to_phoneNo == 0 ? "-" : formatPhoneNumber(row.to_phoneNo),
    },
    {
      name: "Email",
      selector: (row) => {
        const isLong = row.to_email && row.to_email.length > 35;
        return isLong ? (
          <span className="cs-ellipsis">
            <CapsyncToolTip
              Child={row.to_email}
              placement={"top"}
              size="18"
              message={row.to_email}
              type="text"
            />
          </span>
        ) : (
          row.to_email
        );
      },
    },
    ...(mainTabName === "2"
      ? [
          {
            name: "Company Name",
            selector: (row) => {
              const isLong = row.companyName && row.companyName.length > 35;
              return isLong ? (
                <span className="cs-ellipsis">
                  <CapsyncToolTip
                    Child={row.companyName}
                    placement={"top"}
                    size="18"
                    message={row.companyName}
                    type="text"
                  />
                </span>
              ) : (
                row.companyName
              );
            },
          },
        ]
      : []),
    {
      name: "Date",
      cell: (row) =>
        row.to_status == 1 ? (
          <span
            className="cs-badge cs-warning default-regular-body-text-xs"
            onClick={() => onClick(row)}>
            Pending
          </span>
        ) : row.to_status == 3 ? (
          <span
            className="cs-badge cs-danger default-regular-body-text-xs"
            onClick={() => onClick(row)}>
            Declined
          </span>
        ) : (
          <div onClick={() => onClick(row)}>
            {moment(row.updatedAt).format("MM/DD/YYYY")}
          </div>
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-box">
          <div className="table-icons-container cs-file-icon">
            <span
              className="cs-icon-badge-danger cs-danger "
              onClick={() => deleteRecord(row.id)}>
              <CapsyncToolTip
                Child={"delete-outlined"}
                placement={"top"}
                size="18"
                message={"Delete"}
                type="icon"
              />
            </span>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="cs-data-table cursor-pointer-table-body">
      <DataTable
        columns={columns}
        data={data ? data.slice(rowStartIdx, rowStartIdx + rowsPerPage) : []}
        onRowClicked={(row) => onClick(row)}
        customStyles={customStyles}
      />
      {data &&
        data.length !== 0 &&
        data.some((item) => item.to_user_role !== "1") && (
          <Pagination
            rowStartIdx={rowStartIdx}
            setRowStartIdx={setRowStartIdx}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            paginationDropdownItems={paginationDropdownItems}
            paginationList={data ? data : []}
          />
        )}
    </div>
  );
};

export default ManageUserTable;
