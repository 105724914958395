/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { clearMessage } from "../../../slices/message";
import {
  mfaPhoneAuthPin,
  mfaPhoneAuth,
  wealth_managers_findone,
} from "../../../slices/auth";
import { Themeprovider } from "./File_Encryption/FileEncryption";
import { userFindRecord } from "../../../slices/user";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import "../settings/File_Encryption/file_encryption.css";
import { toast } from "react-toastify";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const commonMfPhonePin = (props) => {
  const fetchProp = useContext(Themeprovider);
  const [loading, setLoading] = useState(false);
  const [resendMsg, setResendMsg] = useState();
  const [record, setRecord] = useState({});

  const [otp, setOtp] = useState();
  const [msg, setMsg] = useState("");

  const { user, authUser } = useSelector((state) => state.auth);
  const user_role = user && user.user_role.role;
  const { message } = useSelector((state) => state.message);
  const loggedUser = authUser && authUser.user ? authUser.user : authUser;
  const dispatch = useDispatch();

  const handleChange = (otp) => {
    setMsg("");
    setResendMsg(false);
    setOtp(otp);
    dispatch(clearMessage());
  };

  let phoneNumber = loggedUser.phoneNo;
  if (JSON.parse(localStorage.getItem("phone"))) {
    phoneNumber = JSON.parse(localStorage.getItem("phone"));
  }

  useEffect(() => {
    dispatch(clearMessage());
    setResendMsg(false);

    return () => {
      dispatch(clearMessage());
    };
  }, [dispatch]);

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = loggedUser.id;
  }

  const email = loggedUser && loggedUser.email;

  console.log(user);
  console.log(email);

  const handleSubmit = () => {
    setResendMsg(false);
    setLoading(true);
    if (otp) {
      dispatch(mfaPhoneAuthPin({ email: email, user_id: id, MFAPhoneOtp: otp }))
        .unwrap()
        .then((res) => {
          console.log("res:- ", res);
          setLoading(false);

          if (res.code === 200) {
            props.setModalShow && props.setModalShow(false);
            props.saveData && props.saveData();
            props.save && props.save();
            props.setshowpass && props.setshowpass(true);
            // if (props.forFileEncryption && props.forFileEncryption === true) {
            //   toast.success('File encryption password can be viewed');
            // }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };

  const resendMFAPhoneOTP = () => {
    setOtp("");
    dispatch(clearMessage());
    dispatch(mfaPhoneAuth({ user_id:id, email }))
      .then((res) => {
        setResendMsg(res.payload.message);
      })
      .catch((err) => {});
  };

  // const findUserRecord = () => {
  //   if (id) {
  //     dispatch(userFindRecord({ id }))
  //       .unwrap()
  //       .then((response) => {
  //         setRecord(response);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // useEffect(() => {
  //   findUserRecord();
  // }, []);

  return (
    <>
      <div className="email-otp-verfication">
        {!props.notitle && (
          <h3 className="default-semi-bold-h3">Mobile verification</h3>
        )}
        <div className="text-boxes-content">
          <div className="otp-content">
            <h5 className="default-regular-h5 cs-neutral-80">
              We have sent a code to
              <span className="default-regular-h5 cs-primary">
                &nbsp;{phoneNumber}
              </span>
            </h5>
            <Form className="otp-form-boxes">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle="otp-txt"
                containerStyle="otp-layout"
                // isInputNum={true}
                inputType="number"
                shouldAutoFocus={true}
              />
              {msg ? (
                <span className="default-light-body-text-s cs-danger">
                  {msg}
                </span>
              ) : null}
            </Form>
          </div>
          <p className="default-regular-sub-heading-s cs-neutral-90">
            Did not receive the code?
            <span
              onClick={resendMFAPhoneOTP}
              className="cs-primary cursor-pointer"
            >
              &nbsp;Resend
            </span>
          </p>
        </div>

        {resendMsg && (
          <div className="cap-success text-center default-regular-body-text-s cap-messageArea cs-success">
            <span className="icon cs-success">
              <CapsyncIcon title="verification-outlined" size="18" />
            </span>
            {resendMsg}
          </div>
        )}

        {message && (
          <div className="cs-msg default-regular-body-text-s cs-danger">
            <span className="icon">
              <CapsyncIcon title="info-filled" size="18" />
            </span>
            <span className="txt"> {message.message || message}</span>
          </div>
        )}

        <div className="email-verification-buttons">
          <Button
            type="submit"
            className="cs-btn-secondary md-btn default-regular-h5"
            onClick={() => props.setModalShow(false)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="cs-btn-primary md-btn default-regular-h5"
            disabled={loading}
            onClick={handleSubmit}
          >
            {/* <span onClick={fetchProp.closeModal}>Verify account</span> */}
            Verify account
          </Button>
        </div>
      </div>
    </>
  );
};

export default commonMfPhonePin;
