import React from "react";
import { Button, Form, Modal, ModalFooter } from "react-bootstrap";

const AttachOptionsModal = ({
  isShow,
  onModalClose,
  isCreditManagerCheckboxEnabled,
  setShareCreditReport,
  shareCreditReport,
  onSubmit,
}) => {
  return (
    <Modal
      show={isShow}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          Additional Reports
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={
            "auth-mfa-item " +
            (shareCreditReport
              ? "active-method"
              : !isCreditManagerCheckboxEnabled
              ? "cs-disabled"
              : "")
          }
          onClick={() =>
            isCreditManagerCheckboxEnabled &&
            setShareCreditReport(!shareCreditReport)
          }
        >
          <div className="auth-mfa-inner">
            <div className="auth-mfa-options cs-radio-btn">
              <Form.Check
                type="checkbox"
                checked={shareCreditReport}
                className="cs-form-check-box"
                id="radio-credit-report"
                disabled={!isCreditManagerCheckboxEnabled}
              />
            </div>
            <div className="auth-mfa-options">
              <h4 className="cs-neutral-100 default-regular-h4 ">
                Credit Report
              </h4>
            </div>
          </div>
        </div>
      </Modal.Body>
      <ModalFooter>
        <div className="cs-modal-btn">
          <Button
            onClick={() => {
              onModalClose();
            }}
            className="cs-btn-secondary md-btn default-regular-h5"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={onSubmit}
            className="cs-btn-primary md-btn default-regular-h5"
          >
            Review & Send
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AttachOptionsModal;
