import React from "react";
import { useSelector } from "react-redux";
import { Button, Image, Modal } from "react-bootstrap";
import ErrorValidation from "../../../../assets/images/other/error-validation.svg";

const StoragePaymentByModal = (props) => {
  const { showPaymentBy, setShowPaymentBy, paymentByUserName } = props;

  const continueFun = () => {
    setShowPaymentBy(false);
  };

  return (
    <>
      <Modal
        show={showPaymentBy}
        onHide={!showPaymentBy}
        centered
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal payment-success"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Upgrade Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="success-head">
            <span>
              <Image
                src={ErrorValidation}
                alt="Error Validation"
                width={100}
                height={100}
              />
            </span>
            <h4 className="default-regular-sub-heading-m cs-neutral-100">
              To upgrade the plan, contact a{" "}
              {window.localStorage.getItem("pfs_access_id") === null
                ? "Firm"
                : "Client"}
            </h4>
            <p className="default-light-body-text-m cs-neutral-90">
              {(window.localStorage.getItem("pfs_access_id") === null
                ? "Your"
                : "This") +
                " account billing & subscription is managed by " +
                paymentByUserName +
                ". Please reach out to them with any billing related requests."}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cs-btn-primary lg-btn default-regular-h5"
            onClick={() => continueFun()}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StoragePaymentByModal;
