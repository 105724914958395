import React, { useEffect } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { socket } from "../../config/Socket";
import { localStorageClear } from "../../util/common";
import ErrorValidation from "../../../assets/images/other/error-validation.svg";

import { roleName } from "../../config/commonRoleName";
import { add_audit_log } from "../../slices/auditLogs";
import { setRemoveAuthUser, signout } from "../../slices/auth";

import "../../../app/commonComponent/component/Component.css";

const InactivityLogoutModal = ({ remainingSeconds, onStay }) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);
  const auth_user_role = (authUser && authUser.role) || "";

  useEffect(() => {
    if (remainingSeconds === 0) {
      if (window.localStorage.pfs_access_obj) {
        socket.emit("remove_edit_permission", {
          user_id: JSON.parse(window.localStorage.getItem("pfs_access_id")),
          advisor_user_id: window.localStorage.getItem("id"),
          flag:
            auth_user_role === 1
              ? roleName.individual
              : auth_user_role === 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        });
      }
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingSeconds]);

  const handleLogout = async () => {
    await dispatch(add_audit_log({ signout: true })).unwrap();

    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    const loginUser = userFromLocalStorage && userFromLocalStorage.user;
    const loginUserRole =
      userFromLocalStorage && userFromLocalStorage.user_role;

    const payload = {
      user_id: loginUser.id,
      user_role: loginUserRole ? loginUserRole.role : 1,
    };

    const response = await dispatch(signout(payload)).unwrap();

    if (response && response.status === "Success") {
      localStorageClear();
      dispatch(setRemoveAuthUser());
      onStay();
      socket.disconnect();
      socket.emit("user_logging_out", true);
      window.location.href = "/";
    }
  };

  return (
    <Modal
      show
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
      className="cs-common-modal-overlay"
      onHide={handleLogout}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          No activity detected
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="log-out-popup">
          <span>
            <Image
              src={ErrorValidation}
              alt="Error Validation"
              width={100}
              height={100}
            />
          </span>
          <p className="default-light-body-text-m cs-neutral-90 text-center">
            To ensure the security of your account and data, you will be
            automatically logged out in&nbsp;
            <span className="cs-neutral-100">
              {Math.floor(remainingSeconds / 60)}:
              {remainingSeconds % 60 < 10 ? "0" : ""}
            </span>
            {remainingSeconds % 60} due to inactivity.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn cs-center-btn">
          <Button
            className="cs-btn-secondary md-btn default-regular-h5"
            onClick={() => handleLogout()}
            variant="secondary"
          >
            Log out
          </Button>
          <Button
            className="cs-btn-primary md-btn default-regular-h5"
            onClick={onStay}
            variant="primary"
          >
            Stay on CapSync
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InactivityLogoutModal;
