/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button, Row, Form, Col } from "react-bootstrap";
import moment from "moment";

import { backAppURl } from "../applicationMode";
import CapsyncToolTip from "./capsyncTooltip/CapsyncToolTip";
import UseDownload from "../customHooks/UseDownload";
import UseZipDownload from "../customHooks/UseZipDownload";
import CapsyncIcon from "./CapsyncIcon";
import { toast } from "react-toastify";

const ManageFilesUpload = ({
  changeStatus,
  fileModal,
  deletedFileDisable,
  storeFiles = [],
  storeAllFiles,
  user_id,
  acceptedFiles,
  getRootProps,
  getInputProps,
  removeFile,
  is_deleted,
  toastMessage,
  category,
  main_category,
  categoryItemId,
  duplicateFileError,
  setDownloadConfirmationModalOpen,
  setDownloadItem,
  setCategoryItem,
  setIsBigFile,
  setCategoryItemId,
}) => {
  let storeFileArr = [];

  let url = backAppURl;

  const [downloadStatus, setDownloadStatus] = useState(false);
  const [validationError, setValidationError] = useState("");

  //------------Download all attchments---------------//
  const downloadZip = (url) => {
    setDownloadStatus(true);

    const hasUnScannedFiles = storeFiles.some((val) => val.is_scan_file === 0);
    const hasBlockedFiles = storeFiles.some((val) => val.is_scan_file === 2);

    if (hasUnScannedFiles) {
      toast.info("Scanning process in progress, please try again later.");
      setDownloadStatus(false);
      return;
    }

    if (hasBlockedFiles) {
      const fileNames = storeFiles.map((obj) => obj.name).join(", ");
      toast.error(
        `${fileNames} This ${
          storeFiles.length > 1 ? "Files" : "File"
        } cannot be downloaded`
      );
    }

    const hasAllBlockedFiles = storeFiles.every(
      (val) => val.is_scan_file === 2
    );
    if (hasAllBlockedFiles) {
      setDownloadStatus(false);
      return;
    }

    UseZipDownload(
      `${url}/api/${main_category}/${category}/zip`,
      categoryItemId,
      user_id,
      () => setDownloadStatus(false),
      () =>
        toastMessage("Your files have been downloaded successfully.", "success")
    );
  };

  //------------------Download single attchments----------------//
  const downloadFile = (url, params, is_scan_file, item) => {
    if (is_scan_file === 1) {
      let row_id = window.localStorage.getItem("row_id");
      UseDownload(
        `${url}/api/${main_category}/${category}/file_download`,
        params,
        categoryItemId,
        () =>
          toastMessage(
            "Your file has been downloaded successfully.",
            "success"
          ),
        item?.name
      );
    } else {
      changeStatus(false, null);
      setIsBigFile(is_scan_file === 3);
      setDownloadConfirmationModalOpen(true);
      setDownloadItem([item]);
      setCategoryItem({
        url: url,
        main_category: main_category,
        category: category,
        params: params,
        categoryItemId: categoryItemId,
        name: item?.name,
      });
      setCategoryItemId(null);
    }
  };

  function showStarNumber(params) {
    let newContent = "";
    newContent += params;
    if (newContent.length > 20) {
      return newContent.substring(0, 20) + "...";
    } else {
      return newContent;
    }
  }

  return (
    <>
      <Modal
        show={fileModal}
        // onHide={!fileModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal manage-files"
        className="cs-common-modal-overlay"
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Manage files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                Upload supportive documents
              </Form.Label>
            </Col>
          </Row>
          <div className="manage-profile-upload">
            <Row>
              <Col>
                <div className="manage-file-container">
                  <div
                    {...getRootProps({})}
                    className={`cs-upload-files cursor-pointer ${
                      is_deleted && "cs-neutral-60 disabled"
                    }`}
                  >
                    <input
                      {...getInputProps()}
                      name="attachments"
                      disabled={is_deleted}
                    />
                    <span className="cs-primary">
                      <CapsyncIcon title="upload-files-filled" size="28" />
                    </span>
                    <p className="default-regular-sub-heading-s">
                      <span>Browse</span>&nbsp;or drag file here
                    </p>
                    <p className="default-regular-sub-heading-xxs">
                      Support PDF, PNG, JPG and JPEG files
                    </p>
                  </div>
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {duplicateFileError}
                  </span>
                </div>
              </Col>
            </Row>
            <div className="cs-files">
              <div className="cs-scroller">
                {acceptedFiles.map((file) => {
                  return (
                    <>
                      <div className="cs-selected-files">
                        <div className="cs-fileicon cs-neutral-60">
                          <CapsyncToolTip
                            Child={"file-icon-outlined"}
                            placement={"top"}
                            type="icon"
                            message={showStarNumber(file.name)}
                          />
                        </div>
                        <div className="cs-files-names">
                          <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                            {showStarNumber(file.name)}
                          </span>
                          <span className="download-filename default-light-body-text-s cs-last-date cs-neutral-80">
                            {file.size / 1000} Kb
                          </span>
                        </div>

                        {is_deleted === true && (
                          <span className="cs-common-spinner cs-primary">
                            <CapsyncIcon title="loading-outlined" size="22" />
                          </span>
                        )}
                      </div>
                    </>
                  );
                })}
                {storeFiles[0] !== "" &&
                  storeFiles.map((iter) => {
                    const disableButton =
                      deletedFileDisable &&
                      deletedFileDisable.length > 0 &&
                      deletedFileDisable.includes(iter.id);
                    return (
                      <div className="cs-selected-files">
                        <div className="cs-fileicon cs-neutral-60">
                          <CapsyncToolTip
                            Child={"file-icon-outlined"}
                            placement={"top"}
                            type="icon"
                            message={iter.name.replaceAll("_", " ")}
                          />
                        </div>
                        <div className="cs-files-names">
                          <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                            {showStarNumber(iter.name)}
                          </span>
                          <span className="download-filename default-light-body-text-s cs-last-date cs-neutral-80">
                            Last updated on{" "}
                            {moment(iter.uploadDate).format("MM/DD/YYYY")}
                          </span>
                        </div>

                        <div className="cs-file-icon icons">
                          <span
                            className="cs-icon-badge-neutral-20 cs-neutral-80"
                            onClick={() => {
                              if (iter.is_scan_file !== 0) {
                                downloadFile(
                                  url,
                                  iter.url,
                                  iter.is_scan_file,
                                  iter
                                );
                              } else {
                                toast.info(
                                  "Scanning process in progress, please try again later."
                                );
                              }
                            }}
                          >
                            <CapsyncIcon title="download-outlined" size="18" />
                          </span>
                          <span
                            className="cs-icon-badge-danger cs-danger"
                            onClick={() =>
                              !disableButton && removeFile(iter.name, iter.id)
                            }
                          >
                            <CapsyncIcon title="delete-outlined" size="18" />
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="cs-modal-btn">
          <Button
            variant="secondary"
            className="cs-btn-secondary md-btn default-regular-h5"
            disabled={is_deleted}
            onClick={() => {
              changeStatus(false, null);
              setCategoryItemId(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="cs-btn-primary md-btn default-regular-h5"
            onClick={() => downloadZip(url)}
            disabled={
              storeFiles[0] === "" ||
              storeFiles.length === 0 ||
              downloadStatus ||
              is_deleted
            }
          >
            Download all
            {downloadStatus && (
              <>
                &nbsp;
                <span className="spinner-border spinner-border-sm"></span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageFilesUpload;
