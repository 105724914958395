import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";

import {
  authUserUpdated,
  loginUserFindRecord,
  mfaGoogleAuthPin,
} from "../../../slices/auth";
import { MfaContext } from "./Mfa_setup/MfaSetup";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const GoogleAppPin = (props) => {
  const fetchData = useContext(MfaContext);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [msg, setMsg] = useState("");

  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";
  const { user, authUser } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const handleChange = (otp) => {
    setMsg("");
    setOtp(otp);
  };

  const id = isMySettings ? authUser?.user?.id : user.user.id;
  const email = isMySettings ? authUser?.user?.email : user.user.email;

  const handleSubmit = (e) => {
    e.preventDefault();
    setMsg("");
    setLoading(true);
    const MFAGoogleOtp = otp;
    if (MFAGoogleOtp) {
      dispatch(mfaGoogleAuthPin({ email, user_id: id, MFAGoogleOtp }))
        .unwrap()
        .then((res) => {
          if (res.code === 200) {
            setLoading(false);
            fetchData.closeMfaModal();
            if (isMySettings) {
              dispatch(loginUserFindRecord({ id: authUser?.user?.id }));
            } else {
              dispatch(authUserUpdated({ MFAGoogle: true }));
            }
            // toast.success("You have successfully authenticated.")
          } else {
            setMsg(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };

  return (
    <>
      <div className="email-otp-verfication">
        <div className="otp-content">
          <h5 className="default-regular-h5 cs-neutral-80">
            Enter the 6-digit code from your authenticator app.
          </h5>
          <Form onSubmit={handleSubmit} className="otp-form-boxes">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle="otp-txt"
              containerStyle="otp-layout"
              // isInputNum={true}
              inputType="number"
              shouldAutoFocus={true}
            />

            {msg ? (
              <span className="default-light-body-text-s cs-danger">{msg}</span>
            ) : null}
          </Form>
        </div>
        <div className="email-verification-buttons">
          <Button
            type="submit"
            className="cs-btn-secondary md-btn default-regular-h5"
            onClick={() => props.closeModal()}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="cs-btn-primary md-btn default-regular-h5"
            disabled={loading}
            onClick={handleSubmit}>
            {/* <span onClick={fetchProp.closeModal}>Verify account</span> */}
            Verify account
          </Button>
        </div>

        {message && message.message && (
          <div className="cs-msg default-regular-body-text-s cs-danger">
            <span className="icon">
              <CapsyncIcon title="info-filled" />
            </span>
            <span className="txt"> {message.message}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default GoogleAppPin;
