import React, { useState } from "react";
import { Accordion, Row, Col, Table, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import moment from "moment";
import { requestTableHeader } from "../../../config/request";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { getSignatureTo, getSignatureStatus } from "../../../util/request";
import { sendpfsemail, signature_reminder } from "../../../slices/user";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { roleName } from "../../../config/commonRoleName";
import HelloSign from "hellosign-embedded";
import { backAppURl } from "../../../applicationMode";
import axios from "axios";
const SignatureTo = ({
  tabValue,
  user_email,
  filteredIndividuals,
  onSignatureDetails,
  sharePFSModal,
  handleDownload,
  shareicon,
  DarkDownloadIcon,
  status,
  signatureTo,
  requestPageType,
  rowStartIdx,
  rowsPerPage,
}) => {
  const { user } = useSelector((state) => state.auth);
  const current_user_token = user && user.token;
  const userId = user && user.user.id;
  const imageTitles = [
    "signature-timline-outlined",
    "information-outlined",
    "information-outlined",
    "verification-outlined",
  ];
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [makeDisable, setMakeDisable] = useState(false);
  const [disabledUsers, setDisabledUsers] = useState([]);
  const [isLoader, setIsLoader] = useState(-1);
  const [notificationDisable, setNotificationDisable] = useState(-1);

  const handleEmailSend = async (user) => {
    if (disabledUsers.includes(user.request_sent_to_email)) {
      return;
    }
    if (notificationDisable > -1) {
      return;
    }
    setDisabledUsers([...disabledUsers, user.request_sent_to_email]);
    setNotificationDisable(user.id);
    const email = user.request_sent_to_email;
    const signatureHelloId = user.signature_hello_id;
    await handleSendEmail(email, signatureHelloId);
    setDisabledUsers(
      disabledUsers.filter((u) => u !== user.request_sent_to_email)
    );
    setNotificationDisable(-1);
  };

  async function handleSendEmail(email, signatureHelloId) {
    const response = await dispatch(
      signature_reminder({
        email: email,
        signature_request_id: signatureHelloId,
      })
    );
    if (response && response.payload.code === 500) {
      toast.error(response.payload.message);
    } else if (
      response &&
      response.payload.response &&
      response.payload.response.data.code === 500
    ) {
      toast.error(response.payload.response.data.message);
    } else {
      toast.success("Your reminder has been sent successfully.");
    }
  }
  const downloadPdf = async () => {
    await axios({
      url: `${backAppURl}/api/pdf/export`,
      method: "post",
      responseType: "blob",

      data: {
        user_id: id,
        flag: "true",
        sec: "sign",
        secondName: "",
      },
      headers: {
        Authorization: `Bearer ${current_user_token}`,
      },
    })
      .then((response) => {})
      .catch((err) => {
        console.log("err", err);
        return false;
      });
  };
  const handlePendingRequest = async (user) => {
    if (isLoader > -1) {
      return;
    }
    setIsLoader(user.id);
    await downloadPdf();
    let claimUrl = `${backAppURl}/api/hellosign/send_user_signature`;
    const response = await fetch(claimUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${current_user_token}`,
      },

      body: JSON.stringify({
        email: user.request_sent_to_email,
        userEmail: user.receiver_email,
        user_id: userId,
        firstName: user.request_sent_to_firstName,
        flag: "self_request",
        id: user.id,
      }),
    });
    const responseData = await response.text();
    const signer2 = JSON.parse(responseData);

    const email1 = signer2.email1;

    const signer2Url = signer2.signing_url;
    const email2 = signer2.email2;

    const signatureRequestId = signer2.signature_request_id;

    const accessToken = process.env.REACT_APP_HELLO_SIGN_API_KEY;
    const client = new HelloSign();
    setIsLoader(-1);
    if (process.env.REACT_APP_MODE === "PROD") {
      client.open(signer2Url, {
        clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
      });
    } else {
      client.open(signer2Url, {
        clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
        skipDomainVerification: true,
      });
    }

    // }
    // setIsLOading(false);

    client.on("sign", async (event) => {
      let downloadUrl = `${backAppURl}/api/hellosign/get_user_signature`;

      const response = await fetch(downloadUrl, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${current_user_token}`,
        },
        body: JSON.stringify({
          values: signatureRequestId,
          flag: "self_request",
        }),
      });
    });
  };

  return (
    <Row>
      <Col>
        <div className="table amortization-main-listing">
          {getSignatureTo(signatureTo, status).length === 0 ? (
            <span className="cs-common-no-record">
              There are no records to display
            </span>
          ) : (
            <Table className="cs-table">
              <thead id="table-head-details-wm" className="cs-thead">
                <tr>
                  {status == "Pending" &&
                    requestTableHeader.requestToPending.map((header) => (
                      <th
                        key={header.title}
                        className={header.class}
                        width={header.width}
                        scope="col"
                      >
                        {header.title}
                      </th>
                    ))}
                  {status == "Completed" &&
                    requestTableHeader.requestToCompleted.map((header) => (
                      <th
                        key={header.title}
                        className={header.class}
                        width={header.width}
                        scope="col"
                      >
                        {header.title}
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody className="request-dashboard cs-tbody default-regular-sub-heading-m">
                {getSignatureTo(signatureTo, status)
                  .slice(rowStartIdx, rowStartIdx + rowsPerPage)
                  .map((request, index) => {
                    const {
                      indvidual_users,
                      request_type,
                      request_sent_by_role,
                    } = request;
                    // const { request_sent_by_displayName } = indvidual_users && indvidual_users.length && indvidual_users[0];
                    let request_sent_by_displayName;
                    if (indvidual_users && indvidual_users.length > 0) {
                      request_sent_by_displayName =
                        indvidual_users[0].request_sent_by_displayName;
                    }

                    const statusListArr = getSignatureStatus(request);

                    return (
                      <React.Fragment key={index}>
                        {indvidual_users && indvidual_users.length > 1 && (
                          <tr id={`sign_sent_${request?.request_id}`}>
                            <td
                              className="accordion-table-collapse accordion-after"
                              colSpan={6}
                            >
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item>
                                  <Accordion.Header>
                                    <Table className="cs-table">
                                      <tbody className="cs-tbody default-regular-sub-heading-m">
                                        <tr>
                                          <td width="280">{request_type}</td>
                                          <td width="240">
                                            {/* {request_sent_by_displayName} */}
                                            {request_sent_by_displayName.length >
                                            20 ? (
                                              <CapsyncToolTip
                                                Child={
                                                  request_sent_by_displayName.slice(
                                                    0,
                                                    20
                                                  ) + "..."
                                                }
                                                placement={"top"}
                                                message={
                                                  request_sent_by_displayName
                                                }
                                                type="text"
                                              />
                                            ) : (
                                              request_sent_by_displayName
                                            )}
                                          </td>
                                          <td width="240">
                                            {request_sent_by_role ===
                                            roleName.financial_advisor
                                              ? "Financial Advisor"
                                              : request_sent_by_role ===
                                                roleName.individual
                                              ? "Individual"
                                              : "Firm"}
                                          </td>
                                          <td width="240">
                                            {moment(
                                              indvidual_users[0].createdAt
                                            ).format("MM/DD/YYYY")}
                                          </td>
                                          <td width="320">
                                            <div className="cs-progress-indicator">
                                              {statusListArr.map(
                                                (status, index) => {
                                                  let statusclass = "";
                                                  let currentImageTitle =
                                                    imageTitles[index];
                                                  let tooltipMessage = "";

                                                  if (status === "Completed") {
                                                    statusclass = "cs-success";
                                                    tooltipMessage =
                                                      "Signature request has been sent";
                                                    if (index === 1) {
                                                      tooltipMessage =
                                                        "1st Signature added";
                                                    } else if (index === 2) {
                                                      tooltipMessage =
                                                        "2nd Signature added";
                                                    } else if (index === 3) {
                                                      tooltipMessage =
                                                        "Verification completed";
                                                    }
                                                  } else if (
                                                    index === 3 &&
                                                    status === "Rejected"
                                                  ) {
                                                    currentImageTitle =
                                                      "declined-outlined";
                                                    statusclass = "cs-danger";
                                                    tooltipMessage =
                                                      "Verification Rejected";
                                                  } else if (
                                                    status === "Rejected"
                                                  ) {
                                                    statusclass = "cs-danger";
                                                    if (index === 1) {
                                                      tooltipMessage =
                                                        "1st User rejected";
                                                    } else {
                                                      tooltipMessage =
                                                        "2nd User rejected";
                                                    }
                                                  } else {
                                                    if (
                                                      index === 1 &&
                                                      status === "Pending"
                                                    ) {
                                                      tooltipMessage =
                                                        "Waiting for 1st signature";
                                                    } else {
                                                      tooltipMessage =
                                                        "Waiting for 2nd signature";
                                                      if (
                                                        index === 3 &&
                                                        status === "Pending"
                                                      ) {
                                                        tooltipMessage =
                                                          "Waiting for verification";
                                                      }
                                                    }
                                                  }

                                                  return (
                                                    <div
                                                      className={`cs-indicator ${statusclass} ${
                                                        status === "Pending"
                                                          ? "cs-neutral-50"
                                                          : ""
                                                      }`}
                                                      key={index}
                                                    >
                                                      <CapsyncToolTip
                                                        Child={
                                                          currentImageTitle
                                                        }
                                                        placement={"top"}
                                                        message={tooltipMessage}
                                                        type="icon"
                                                        size="22"
                                                      >
                                                        <span className="cs-indicator-icon cs-neutral-50">
                                                          <CapsyncIcon
                                                            title={
                                                              currentImageTitle
                                                            }
                                                            size="18"
                                                          />
                                                        </span>
                                                      </CapsyncToolTip>

                                                      {index <
                                                        statusListArr.length -
                                                          1 && (
                                                        <span className="cs-indicator-line"></span>
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </td>
                                          {/* {status === "Completed" && <td width="320"></td>} */}

                                          {status === "Completed" && (
                                            <td width="264">
                                              <div className="table-icons-container cs-file-icon finance-action-acc-column">
                                                <span
                                                  className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                    request.is_completed ===
                                                      true &&
                                                    status === "Completed"
                                                      ? "cs-disabled-icon"
                                                      : ""
                                                  } 
                                                ${
                                                  status === "Completed"
                                                    ? "display-signature-icon"
                                                    : ""
                                                } ${
                                                    makeDisable
                                                      ? "cs-disabled-icon"
                                                      : ""
                                                  }`}
                                                  // onClick={()=> toast.success("Your reminder has been sent successfully. ")}
                                                  onClick={() => {
                                                    // toast.success("Your reminder has been sent successfully. ");
                                                    if (
                                                      request.is_completed ===
                                                      false
                                                    ) {
                                                      handleEmailSend(
                                                        indvidual_users[0]
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <CapsyncToolTip
                                                    Child={"reminder-outlined"}
                                                    placement={"top"}
                                                    size="18"
                                                    message={"Reminder"}
                                                    type="icon"
                                                  />
                                                </span>

                                                <span
                                                  className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                    request.is_completed ===
                                                      false ||
                                                    indvidual_users[0]
                                                      .request_status === 4
                                                      ? "cs-disabled-icon"
                                                      : ""
                                                  } ${
                                                    status === "Pending"
                                                      ? "display-signature-icon"
                                                      : ""
                                                  }`}
                                                  onClick={(e) => {
                                                    if (
                                                      request.is_completed ===
                                                        true &&
                                                      indvidual_users[0]
                                                        .request_status !== 4
                                                    ) {
                                                      sharePFSModal(
                                                        true,
                                                        false,
                                                        indvidual_users[0]
                                                          .sign_pfs_link,
                                                        indvidual_users[0]
                                                          .request_id
                                                      );
                                                    }
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <CapsyncToolTip
                                                    Child={"share-outlined"}
                                                    placement={"top"}
                                                    size="18"
                                                    message={"Share"}
                                                    type="icon"
                                                  />
                                                </span>

                                                <span
                                                  className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                    request.is_completed ===
                                                      false ||
                                                    indvidual_users[0]
                                                      .request_status === 4
                                                      ? "cs-disabled-icon"
                                                      : ""
                                                  } ${
                                                    status === "Pending"
                                                      ? "display-signature-icon"
                                                      : ""
                                                  }`}
                                                  onClick={(e) => {
                                                    if (
                                                      request.is_completed ===
                                                        true &&
                                                      indvidual_users[0]
                                                        .request_status !== 4
                                                    ) {
                                                      handleDownload(
                                                        indvidual_users[0]
                                                          .sign_pfs_link
                                                      );
                                                      toast.success(
                                                        "Your Personal Financial Statement has been downloaded successfully."
                                                      );
                                                    }
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <CapsyncToolTip
                                                    Child={"download-outlined"}
                                                    placement={"top"}
                                                    size="18"
                                                    message={"Download"}
                                                    type="icon"
                                                  />
                                                </span>
                                              </div>
                                            </td>
                                          )}
                                          {status === "Pending" && (
                                            <td width="264"></td>
                                          )}
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Table className="cs-table accordion-table">
                                      <thead className="cs-thead">
                                        <tr>
                                          <th
                                            width="250"
                                            className="cs-first-radius"
                                          >
                                            First Name
                                          </th>
                                          <th width="240">Last Name</th>
                                          <th width="260">Email</th>
                                          <th width="240">Status</th>
                                          <th width="240">Date</th>
                                          {/* <th width="186">Action</th> */}
                                          {status === "Pending" ? (
                                            <th
                                              width="306"
                                              className="cs-last-radius"
                                            >
                                              Action
                                            </th>
                                          ) : (
                                            <th
                                              width="306"
                                              className="cs-last-radius"
                                            ></th>
                                          )}
                                          {/* <th
                                          width="120"
                                          className="cs-last-radius"
                                        ></th> */}
                                        </tr>
                                      </thead>
                                      <tbody className="cs-tbody default-regular-body-text-m">
                                        {indvidual_users.map((user, index) => {
                                          const isDisabled =
                                            disabledUsers.includes(
                                              user.request_sent_to_email
                                            );
                                          const {
                                            request_sent_to_firstName,
                                            request_sent_to_lastName,
                                            request_sent_to_email,
                                            request_status,
                                          } = user;
                                          return (
                                            <tr
                                              className="default-regular-sub-heading-m"
                                              key={index}
                                            >
                                              <td width="250">
                                                {/* {request_sent_to_firstName} */}
                                                {request_sent_to_firstName.length >
                                                20 ? (
                                                  <CapsyncToolTip
                                                    Child={
                                                      request_sent_to_firstName.slice(
                                                        0,
                                                        20
                                                      ) + "..."
                                                    }
                                                    placement={"top"}
                                                    message={
                                                      request_sent_to_firstName
                                                    }
                                                    type="text"
                                                  />
                                                ) : (
                                                  request_sent_to_firstName
                                                )}
                                              </td>
                                              <td width="240">
                                                {/* {request_sent_to_lastName ? request_sent_to_lastName : "-"} */}
                                                {request_sent_to_lastName ? (
                                                  request_sent_to_lastName.length >
                                                  20 ? (
                                                    <CapsyncToolTip
                                                      Child={
                                                        request_sent_to_lastName.slice(
                                                          0,
                                                          20
                                                        ) + "..."
                                                      }
                                                      placement={"top"}
                                                      message={
                                                        request_sent_to_lastName
                                                      }
                                                      type="text"
                                                    />
                                                  ) : (
                                                    request_sent_to_lastName
                                                  )
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              <td width="260">
                                                {/* {request_sent_to_email} */}
                                                {request_sent_to_email ? (
                                                  request_sent_to_email.length >
                                                  25 ? (
                                                    <CapsyncToolTip
                                                      Child={
                                                        request_sent_to_email.slice(
                                                          0,
                                                          25
                                                        ) + "..."
                                                      }
                                                      placement={"top"}
                                                      message={
                                                        request_sent_to_email
                                                      }
                                                      type="text"
                                                    />
                                                  ) : (
                                                    request_sent_to_email
                                                  )
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              <td width="240">
                                                <span
                                                  className={`cs-badge default-regular-body-text-xs ${
                                                    user.request_status === 1 ||
                                                    user.request_status === 2
                                                      ? "cs-success"
                                                      : user.request_status ===
                                                        3
                                                      ? "cs-warning"
                                                      : user.request_status ===
                                                          4 && "cs-danger"
                                                  }`}
                                                >
                                                  {user.request_status === 1 ||
                                                  user.request_status === 2
                                                    ? "Completed"
                                                    : user.request_status === 3
                                                    ? "Pending"
                                                    : "Rejected"}
                                                </span>
                                              </td>
                                              <td width="240">
                                                {moment(user.createdAt).format(
                                                  "MM/DD/YYYY"
                                                )}
                                              </td>
                                              <td width="306">
                                                <div
                                                  className={
                                                    status === "Pending"
                                                      ? "finance-action-column"
                                                      : "justify-content-end"
                                                  }
                                                >
                                                  {status === "Pending" && (
                                                    <div className="table-icons-container cs-file-icon finance-action-acc-column">
                                                      <span
                                                        className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                                          user.request_status ===
                                                            1 ||
                                                          user.request_status ===
                                                            2 ||
                                                          user.request_status ===
                                                            4
                                                            ? "cs-disabled-icon"
                                                            : ""
                                                        } ${
                                                          isDisabled
                                                            ? "cs-disabled-icon"
                                                            : ""
                                                        }`}
                                                        // onClick={()=> toast.success("Your reminder has been sent successfully. ")}
                                                        onClick={() => {
                                                          if (
                                                            !(
                                                              user.request_status ===
                                                                1 ||
                                                              user.request_status ===
                                                                2 ||
                                                              user.request_status ===
                                                                4
                                                            )
                                                          ) {
                                                            // toast.success("Your reminder has been sent successfully. ");
                                                            handleEmailSend(
                                                              user
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <CapsyncToolTip
                                                          Child={
                                                            "reminder-outlined"
                                                          }
                                                          placement={"top"}
                                                          size="18"
                                                          message={"Reminder"}
                                                          type="icon"
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                  <div
                                                    className="cs-detail-btn"
                                                    onClick={() =>
                                                      onSignatureDetails(
                                                        user.request_sent_to_email,
                                                        user,
                                                        user.signature_hello_id,
                                                        user.request_id,
                                                        requestPageType
                                                      )
                                                    }
                                                  >
                                                    <span className="default-light-sub-heading-s cs-neutral-90">
                                                      Details
                                                    </span>
                                                    <span className="icon cs-neutral-80">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="18"
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </td>
                          </tr>
                        )}
                        {indvidual_users && indvidual_users.length === 1 && (
                          <>
                            {indvidual_users.map((request, index) => {
                              const isDisabled = disabledUsers.includes(
                                request.request_sent_to_email
                              );
                              const statusArr = getSignatureStatus(request);

                              return (
                                <tr
                                  key={index}
                                  id={`sign_sent_${request?.request_id}`}
                                >
                                  <td width="280">{request.request_type}</td>
                                  <td width="240">
                                    {/* {request.request_sent_by_displayName} */}
                                    {request.request_sent_by_displayName
                                      .length > 20 ? (
                                      <CapsyncToolTip
                                        Child={
                                          request.request_sent_by_displayName.slice(
                                            0,
                                            20
                                          ) + "..."
                                        }
                                        placement={"top"}
                                        message={
                                          request.request_sent_by_displayName
                                        }
                                        type="text"
                                      />
                                    ) : (
                                      request.request_sent_by_displayName
                                    )}
                                  </td>
                                  <td width="240">
                                    {request_sent_by_role ===
                                    roleName.financial_advisor
                                      ? "Financial Advisor"
                                      : request_sent_by_role ===
                                        roleName.individual
                                      ? "Individual"
                                      : "Firm"}
                                  </td>
                                  <td width="240">
                                    {moment(request.createdAt).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td width="320">
                                    <div className="cs-progress-indicator">
                                      <div
                                        className={`cs-indicator ${
                                          statusArr[0] === "Completed"
                                            ? "cs-success"
                                            : ""
                                        }`}
                                      >
                                        <span>
                                          <CapsyncToolTip
                                            Child={"signature-timline-outlined"}
                                            placement={"top"}
                                            message={
                                              statusArr[0] === "Completed"
                                                ? "Signature request has been sent"
                                                : "Signature request has been completed"
                                            }
                                            type="icon"
                                            size="22"
                                          />
                                        </span>
                                        <span className="cs-indicator-line"></span>
                                      </div>

                                      <div
                                        className={`cs-indicator ${
                                          statusArr[1] === "Completed" &&
                                          request.request_status !== 4
                                            ? "cs-success"
                                            : request.request_status === 4
                                            ? "cs-danger"
                                            : "cs-neutral-50"
                                        }`}
                                      >
                                        <span>
                                          <CapsyncToolTip
                                            Child={"information-outlined"}
                                            placement={"top"}
                                            message={
                                              (request.request_status === 4 &&
                                                "Signature Rejected") ||
                                              (request.request_status === 1 &&
                                                "Signature added") ||
                                              (request.request_status === 3 &&
                                                "Waiting for signature")
                                            }
                                            type="icon"
                                            size="22"
                                          />
                                        </span>
                                        <span className="cs-indicator-line"></span>
                                      </div>

                                      {request.request_status !== 4 && (
                                        <div
                                          className={` cs-indicator-icon ${
                                            statusArr[2] === "Completed"
                                              ? "cs-success"
                                              : request.request_status === 4
                                              ? "cs-danger"
                                              : "cs-neutral-50"
                                          }`}
                                        >
                                          <span>
                                            <CapsyncToolTip
                                              Child={"verification-outlined"}
                                              width="225px"
                                              placement={"top"}
                                              size="22"
                                              message={
                                                (request.request_status === 4 &&
                                                  "verification rejected") ||
                                                (request.request_status === 1 &&
                                                  "Completion of signature") ||
                                                (request.request_status === 3 &&
                                                  "Waiting for completion")
                                              }
                                              type="icon"
                                            />
                                          </span>
                                          <span className="cs-indicator-line"></span>
                                        </div>
                                      )}
                                      {request.request_status === 4 && (
                                        <div
                                          className={` cs-indicator-icon ${
                                            request.request_status === 4
                                              ? "cs-danger"
                                              : ""
                                          }`}
                                        >
                                          <span>
                                            <CapsyncToolTip
                                              Child={"declined-outlined"}
                                              placement={"top"}
                                              message={
                                                request.request_status === 4 &&
                                                "Verification Rejected"
                                              }
                                              type="icon"
                                              size="22"
                                            />
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td width="264">
                                    <div className="table-icons-container cs-file-icon finance-action-column">
                                      <div className="finance-action-acc-column">
                                        <span
                                          className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                            request.request_status === 1 ||
                                            request.request_status === 2 ||
                                            request.request_status === 4
                                              ? "cs-disabled-icon"
                                              : ""
                                          } ${
                                            status === "Completed" ||
                                            status === "Pending"
                                              ? "display-signature-icon"
                                              : ""
                                          } ${
                                            notificationDisable === request.id
                                              ? "cs-disabled-icon"
                                              : ""
                                          }`}
                                          // onClick={()=> toast.success("Your reminder has been sent successfully. ")}
                                          onClick={() => {
                                            if (
                                              !(
                                                request.request_status === 1 ||
                                                request.request_status === 2 ||
                                                request.request_status === 4
                                              )
                                            ) {
                                              // toast.success("Your reminder has been sent successfully. ");
                                              handleEmailSend(request);
                                            }
                                          }}
                                        >
                                          <CapsyncToolTip
                                            Child={"reminder-outlined"}
                                            placement={"top"}
                                            size="18"
                                            message={"Reminder"}
                                            type="icon"
                                          />
                                        </span>

                                        {/* -------------------------------------------------------------- */}

                                        <span
                                          className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                            request.request_status === 1 ||
                                            request.request_status === 2 ||
                                            request.request_status === 4
                                              ? "cs-disabled-icon"
                                              : ""
                                          } ${
                                            status === "Completed" ||
                                            window.localStorage.getItem(
                                              "pfs_access_id"
                                            ) !== null
                                              ? "display-signature-icon"
                                              : ""
                                          }  ${
                                            isLoader === request.id
                                              ? "cs-disabled-icon"
                                              : ""
                                          }`}
                                          // onClick={()=> toast.success("Your reminder has been sent successfully. ")}
                                          onClick={() => {
                                            if (
                                              !(
                                                request.request_status === 1 ||
                                                request.request_status === 2 ||
                                                request.request_status === 4
                                              )
                                            ) {
                                              handlePendingRequest(request);
                                            }
                                          }}
                                        >
                                          <CapsyncToolTip
                                            Child={"signature-outlined"}
                                            placement={"top"}
                                            size="18"
                                            message={"Add Sign"}
                                            type="icon"
                                          />
                                        </span>
                                        {request.request_status === 3 &&
                                          isLoader === request.id && (
                                            <span className="cs-common-spinner cs-primary-main">
                                              <CapsyncIcon
                                                title="loading-outlined"
                                                size="60"
                                              />
                                            </span>
                                          )}

                                        <span
                                          className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                            request.request_status === 3 ||
                                            request.request_status === 4 ||
                                            request.is_deleted === true
                                              ? "cs-disabled-icon"
                                              : ""
                                          } ${
                                            status === "Pending"
                                              ? "display-signature-icon"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              (request.request_status === 1 ||
                                                request.request_status === 2) &&
                                              !(request.request_status === 4) &&
                                              request.is_deleted === false
                                            ) {
                                              sharePFSModal(
                                                true,
                                                false,
                                                request.sign_pfs_link,
                                                request.request_id
                                              );
                                            }
                                          }}
                                        >
                                          <CapsyncToolTip
                                            Child={"share-outlined"}
                                            placement={"top"}
                                            size="18"
                                            message={"Share"}
                                            type="icon"
                                          />
                                        </span>

                                        <span
                                          className={`cs-icon-badge-neutral-20 cs-neutral-80 ${
                                            request.request_status === 3 ||
                                            request.request_status === 4 ||
                                            request.is_deleted === true
                                              ? "cs-disabled-icon"
                                              : ""
                                          } ${
                                            status === "Pending"
                                              ? "display-signature-icon"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              (request.request_status === 1 ||
                                                request.request_status === 2 ||
                                                request.request_status === 4) &&
                                              request.is_deleted === false
                                            ) {
                                              handleDownload(
                                                request.sign_pfs_link
                                              );
                                              toast.success(
                                                "Your Personal Financial Statement has been downloaded successfully."
                                              );
                                            }
                                          }}
                                        >
                                          <CapsyncToolTip
                                            Child={"download-outlined"}
                                            placement={"top"}
                                            size="18"
                                            message={"Download"}
                                            type="icon"
                                          />
                                        </span>
                                      </div>
                                      <div
                                        className="cs-detail-btn"
                                        onClick={() =>
                                          onSignatureDetails(
                                            request.request_sent_to_email,
                                            request,
                                            request.signature_hello_id,
                                            request.request_id,
                                            requestPageType
                                          )
                                        }
                                      >
                                        <span className="default-light-sub-heading-s cs-neutral-90">
                                          Details
                                        </span>
                                        <span className="icon cs-neutral-80">
                                          <CapsyncIcon
                                            title="chevron-right-outlined"
                                            size="18"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  {/* {request.request_status === 4 && <td className="request-status-width"></td>} */}
                                  {/* <td>

                                </td> */}
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
};
export default SignatureTo;
