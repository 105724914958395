/* eslint-disable */
import React, { createContext, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  Container,
  CloseButton,
  Card,
  ModalFooter,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authUserUpdated, mfaPhoneAuth } from "../../../../slices/auth";
import WmEmailVerification from "./WmEmailVerification";
import WmMfaPhonePinVerification from "./WmMfaPhonePinVerification";
import WmGoogleAppSetup from "./WmGoogleAppSetup";
import UseUserRecordWm from "../../../../customHooks/UseUserRecordWm";
import { wm_setreset_phone_mfa } from "../../../../slices/wealthManager.slice";
import { mfaGoogleDeleteRecord } from "../../../../slices/user";
import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import CommonSettingHeader from "../CommonHeader/CommonSettingHeader";
import "../../../individual/settings/Mfa_setup/mfa_setup.css";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import Skeleton from "react-loading-skeleton";
import SkeletonMfa from "../../../individual/settings/Mfa_setup/SkeletonMfa";
import WmMFAPhoneVerification from "./WmMFAPhoneVerification";
import { toast } from "react-toastify";

export const MfaContext = createContext(null);

const MFASetup = (props) => {
  const wmUserData = useSelector((state) => state.auth.user.user)
  const CapsyncMessage = props.CapsyncMessage;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
const role = user && user.user_role && user.user_role.role;
  const [loader, setLoader] = useState(false);
  const [mfaType, setMfatype] = useState(0);
  const [mfaModal, setMfaModal] = useState({
    show: false,
    hide: true,
    UseUserRecordWm,
  });
  const [actionDisabled, setActionDisabled] = useState(false);


  const resetMfaType = (type) => {
    if (type === 2) {
      dispatch(
        mfaPhoneAuth({ user_id: wmUserData.id, email: wmUserData.email })
      )
        .then((res) => {
          setMfatype(type);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setMfatype(type);
    setMfaModal({
      show: true,
      hide: false,
    });
  };
  const closeModal = () => {
    setMfaModal({
      show: false,
      hide: true,
    });
  };
  const resetMFAPhone = () => {
    setLoader(true);
    dispatch(wm_setreset_phone_mfa({ wm_id: wmUserData.id }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          toast.success("Your multi-factor authentication method has been updated successfully.")
          dispatch(authUserUpdated({ MFAPhone: false }))
          setLoader(false);

        }
      })
      .catch((err) => {
        CapsyncMessage(
          "Error",
          "wm_setreset_phone_mfa :: " + err.message,
          "error"
        );
      });
  };

  const resetMFAGoogle = () => {
    setLoader(true);
    setActionDisabled(true)
    dispatch(
      mfaGoogleDeleteRecord({ user_id: wmUserData.id, email: wmUserData.email })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          toast.success("Your multi-factor authentication method has been updated successfully.")
          setLoader(false);
          dispatch(authUserUpdated({ MFAGoogle: false }))
        }
      })
      .catch((err) => {
        CapsyncMessage(
          "Error",
          "mfaGoogleDeleteRecord :: " + err.message,
          "error"
        );
      })
      .finally(() => {
        setActionDisabled(false)
      });
  };

  return (
    <>
      <section className="settings-main-section">
        <Container fluid>
          {/* <div className="cs-section-topbar">
            <CapsyncSectionHeader
              mainTitle="Dashboard"
              mainUrl="/individual"
              pagetitle={"Settings"}
              subTitle="Settings"
            />
          </div> */}
          {props.isLoader === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl={` ${role == 2 ? "/financial-advisor" : "/professional"}`}
                pagetitle={"Settings"}
                subTitle="Settings"
              />
            </div>
          )}

          {props.isLoader === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown />
          )}

          <Card>
            <div className="settings-body">
              {props.isLoader === true ? (
                <div className="for-des">
                  {" "}
                  <div className="skeleton-account-tabs">
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={80} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={150} />
                    </p>
                  </div>
                </div>
              ) : (
                <CommonSettingHeader />
              )}
              <div className="mfa-setup-page">
                <Form>
                  <Row>
                    <Col lg={8}>
                      <div className="mfa-cards">
                        {props.isLoader === true ? (
                          <>
                            <SkeletonMfa /> <SkeletonMfa />{" "}
                          </>
                        ) : (
                          <>
                            <Card className="settings-mfa-card">
                              <div className="mfa-icon-text">
                                <span className="cs-neutral-80">
                                  <CapsyncIcon
                                    title="mobile-outlined"
                                    size="38"
                                  />
                                </span>
                                <div className="mfa-content-text">
                                  <h4 className="default-regular-h4 cs-neutral-100">
                                    Mobile verification
                                  </h4>
                                  <h6 className="default-regular-h6 cs-neutral-60">
                                    Send a code sent to your phone via SMS
                                  </h6>
                                </div>
                              </div>
                              <>
                                {wmUserData.MFAPhone === true ? (
                                  <Button
                                    className="cs-btn-secondary lg-btn default-regular-h5"
                                    onClick={resetMFAPhone}>
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    className="cs-btn-primary lg-btn default-regular-h5"
                                    onClick={() => resetMfaType(2)}>
                                    Setup
                                  </Button>
                                )}
                              </>
                            </Card>

                            <Card className="settings-mfa-card">
                              <div className="mfa-icon-text">
                                <span className="cs-neutral-80">
                                  <CapsyncIcon
                                    title="qr-code-outlined"
                                    size="38"
                                  />
                                </span>
                                <div className="mfa-content-text">
                                  <h4 className="default-regular-h4 cs-neutral-100">
                                    Authenticator app
                                  </h4>
                                  <h6 className="default-regular-h6 cs-neutral-60">
                                    Connect your authenticator app
                                  </h6>
                                </div>
                              </div>
                              <>
                                {wmUserData.MFAGoogle === true &&
                                wmUserData.MFAGoogleSecret !== false ? (
                                  <Button
                                    className="cs-btn-secondary lg-btn default-regular-h5"
                                    onClick={resetMFAGoogle}
                                    disabled={actionDisabled}>
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    className="cs-btn-primary lg-btn default-regular-h5"
                                    onClick={() => resetMfaType(3)}>
                                    Setup
                                  </Button>
                                )}
                              </>
                            </Card>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Card>
        </Container>
      </section>
      {loader && <CapsyncLoader />}

      <Modal
        show={mfaModal.show}
        onHide={() => closeModal()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        backdrop="static">
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            {mfaType === 2
              ? "Mobile verification"
              : "Authenticator app verification"}
          </Modal.Title>
          {/* <CloseButton onClick={closeModal} /> */}
        </Modal.Header>
        <Modal.Body>
          <MfaContext.Provider value={{ closeMfaModal: () => closeModal() }}>
            {mfaType === 1 ? (
              <WmEmailVerification
                CapsyncMessage={CapsyncMessage}
                closeModal={closeModal}
              />
            ) : (
              ""
            )}
            {mfaType === 2 ? (
              <WmMFAPhoneVerification
                CapsyncMessage={CapsyncMessage}
                closeModal={closeModal}
              />
            ) : (
              ""
            )}
            {mfaType === 3 ? (
              <WmGoogleAppSetup
                CapsyncMessage={CapsyncMessage}
                closeModal={closeModal}
              />
            ) : (
              ""
            )}
          </MfaContext.Provider>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MFASetup;
