import React, { useEffect, useState } from "react";
import { Modal, Tabs, Tab, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import * as XLSX from "xlsx";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";
import ReactPlayer from "react-player";
import { renderAsync } from "docx-preview";
import Papa from "papaparse";

const FileViewModal = ({ isOpen, onClose, file, fileName, fileType }) => {
  const [blobUrl, setBlobUrl] = useState(null);
  const [csvContent, setCsvContent] = useState(null);
  const [txtContent, setTxtContent] = useState(null);

  const [xlsxContent, setXlsxContent] = useState({});
  const [sheetNames, setSheetNames] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("");
  const [loading, setLoading] = useState(true);
  const [docxContent, setDocxContent] = useState(null);

  // const fileType = getFileType(fileName);

  useEffect(() => {
    const fetchFileContent = async () => {
      setLoading(true);
      try {
        if (["image", "video", "audio"].includes(fileType)) {
          const response = await axios.get(file, {
            responseType: "blob",
          });
          const blobUrl = URL.createObjectURL(response.data);
          setBlobUrl(blobUrl);
        } else if (fileType === "csv") {
          const response = await axios.get(file, {
            responseType: "blob",
          });
          const text = await response.data.text();
          setCsvContent(text);
        } else if (fileType === "xlsx") {
          const response = await axios.get(file, {
            responseType: "arraybuffer",
          });
          const data = new Uint8Array(response.data);
          const workbook = XLSX.read(data, { type: "array", cellDates: true });
          setSheetNames(workbook.SheetNames);
          setSelectedSheet(workbook.SheetNames[0]);

          const sheetsData = workbook.SheetNames.reduce((acc, sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet, {
              header: 1,
              defval: "",
              raw: true,
            });
            acc[sheetName] = json;
            return acc;
          }, {});

          setXlsxContent(sheetsData);
        } else if (fileType === "pdf") {
          const response = await axios.get(file, {
            responseType: "arraybuffer",
          });
          const fileData = response.data;
          const pdfDoc = await PDFDocument.create();
          const downloadedPDF = await PDFDocument.load(fileData);
          const pageCount = downloadedPDF.getPageCount();

          for (let i = 0; i < pageCount; i++) {
            const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
            pdfDoc.addPage(copiedPage);
          }

          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          setBlobUrl(blobUrl);
        } else if (fileType === "docx") {
          const response = await axios.get(file, {
            responseType: "arraybuffer",
          });
          const docxBlob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          setDocxContent(docxBlob);
        } else if (fileType === "txt") {
          const response = await axios.get(file, {
            responseType: "blob",
          });
          const text = await response.data.text();
          setTxtContent(text);
        }
      } catch (error) {
        if (
          fileType === "pdf" &&
          error &&
          error.message &&
          error.message.includes("encrypted")
        ) {
          onClose("protected-pdf-file");
        }
        console.error("Error fetching file content", error);
      } finally {
        setLoading(false);
      }
    };

    if (file) {
      fetchFileContent();
      // const fetch = fetchFileContent();
    }
  }, [file, fileType]);

  useEffect(() => {
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [blobUrl]);
  useEffect(() => {
    if (docxContent) {
      const container = document.getElementById("docx-preview-container");
      renderAsync(docxContent, container);
    }
  }, [docxContent]);
  const handleSheetChange = (e) => {
    setSelectedSheet(e.target.value);
  };

  const [pageIndex, setPageIndex] = useState(0);

  const renderCsvTable = (csvData, pageSize = 50) => {
    // Parse CSV data with Papa.parse
    const parsedData = Papa.parse(csvData, {
      header: false, // Treat all rows as data, not headers
      skipEmptyLines: true,
      transformHeader: (header) => header.trim(), // Optional: trim headers
    });

    // Extract rows
    const rows = parsedData.data;

    // Adjust rows for pagination
    const currentPageRows = rows.slice(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    );

    return (
      <div className="table-preview-container">
        <div className="table-preview-row">
          <table className="cs-table">
            <tbody className="cs-tbody">
              {currentPageRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, cellIndex) => (
                    <td key={cellIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="cs-modal-btn cs-center-btn">
          <button
            onClick={() => setPageIndex(pageIndex - 1)}
            disabled={pageIndex === 0}
            className="cs-btn-primary md-btn default-regular-h5"
          >
            Previous
          </button>
          <button
            onClick={() => setPageIndex(pageIndex + 1)}
            disabled={(pageIndex + 1) * pageSize >= rows.length}
            className="cs-btn-primary md-btn default-regular-h5"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const renderXlsxTable = (xlsxData, selectedSheet) => {
    if (!xlsxData[selectedSheet]) {
      return (
        <span className="default-regular-h4">
          No data in the selected sheet
        </span>
      );
    }

    const sheet = xlsxData[selectedSheet];
    if (sheet.length === 0) {
      return (
        <span className="default-regular-h4">
          No data in the selected sheet
        </span>
      );
    }

    // Extract the headers
    const headers = sheet[0];
    const rows = sheet.slice(1);

    const formatDate = (value) => {
      if (value instanceof Date) {
        const correctedDate = new Date(value.getTime() + 24 * 60 * 60 * 1000);
        return correctedDate.toLocaleDateString(); // Customize the date format as needed
      }
      return value;
    };

    return (
      <div className="cs-preview-xls">
        <div className="table-preview-row">
          <table className="cs-table">
            <thead className="cs-thead">
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody className="cs-tbody">
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td key={colIndex}>{formatDate(cell)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="file-preview-modal"
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {fileName}
          {!loading && (
            <span onClick={onClose} className="cs-neutral-70 cs-close-btn">
              <CapsyncIcon title="close-outlined" size="20" />
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="cs-preview-body">
          {loading ? (
            <div className="fixed-capsync-loader cs-preview-loader">
              <CapsyncLoader />
            </div>
          ) : blobUrl || xlsxContent || csvContent || txtContent ? (
            fileType === "image" ? (
              <img src={blobUrl} alt={fileName} style={{ width: "100%" }} />
            ) : fileType === "pdf" ? (
              <iframe
                src={blobUrl}
                width="100%"
                height="600px"
                title="file"
                type="application/pdf"
              />
            ) : fileType === "video" ? (
              <ReactPlayer
                className="cs-videoplayer"
                url={blobUrl}
                controls
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                width="auto"
                height="100%"
              />
            ) : fileType === "audio" ? (
              <audio controls src={blobUrl} style={{ width: "100%" }} />
            ) : fileType === "csv" ? (
              renderCsvTable(csvContent)
            ) : fileType === "xlsx" ? (
              <div className="table-preview-container">
                {renderXlsxTable(xlsxContent, selectedSheet)}
                <div className="cs-preview-tab">
                  {sheetNames.length > 1 && (
                    <Tabs
                      defaultActiveKey={sheetNames[0]}
                      id="sheet-tab"
                      className="default-regular-sub-heading-m"
                      onSelect={(e) => {
                        setSelectedSheet(e);
                      }}
                    >
                      {sheetNames.map((sheetName) => (
                        <Tab
                          className="d-none"
                          eventKey={sheetName}
                          title={sheetName}
                        >
                          {sheetName}
                        </Tab>
                      ))}
                    </Tabs>
                  )}
                </div>
              </div>
            ) : fileType === "document" ? (
              (console.log(encodeURIComponent, "encodeURIComponentsddd"),
              (
                <iframe
                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                    file
                  )}&embedded=true`}
                  style={{ width: "100%" }}
                  title={fileName}
                />
              ))
            ) : fileType === "docx" ? (
              <div
                id="docx-preview-container"
                style={{ height: "100%", overflow: "auto" }}
              />
            ) : fileType === "txt" ? (
              <div className="txt-preview">
                <pre>{txtContent}</pre>
              </div>
            ) : (
              <span className="default-regular-h4">Unsupported file type</span>
            )
          ) : (
            <span className="default-regular-h4">Unsupported file type</span>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FileViewModal;
